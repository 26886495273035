.page-header {
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid $color-alto-gray;
    z-index: 10;
    width: 100%;
    transition: transform #{$animation-speed * 2};

    .quick-nav {
        display: none;
        height: $pre-header-height;
        padding: 0.625rem 2rem;

        &__list {
            display: flex;
            align-items: center;
            column-gap: 1.5rem;
        }

        &__item {
            @include text-small;

            display: block;
            text-transform: uppercase;
            color: $white;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        height: $header-height-sm;
        background-color: $color-white;
    }

    &__logo {
        display: inline-flex;
        align-items: center;
        align-self: flex-start;
        height: 100%;
        padding: 10px 16px;

        img,
        svg {
            display: block;
            max-height: 42px;
            height: 100%;
            width: auto;
            max-width: none;
        }
    }

    &__actions,
    &__back {
        display: flex;
        align-items: flex-end;
        flex: 0 0 auto;
        margin-left: auto;
    }

    &__back {
        flex: 0 0 auto;
        margin-right: 1rem;
    }

    &__quick {
        display: flex;
        font-size: 12px;
        font-weight: $font-weight-medium;
        text-transform: uppercase;

        &-item {
            margin-right: 18px;
            position: relative;
        }

        &-link {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            align-items: center;

            &:hover {
                color: $color-ribbon-red;
            }
        }

        &-link span {
            font-size: 10px;
            line-height: 20px;
        }

        .icon {
            height: 20px;
            width: auto;
        }

        .badge {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -10px;
            left: 14px;
            background: $color-ribbon-red;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            color: $color-white;
            font-size: 10px;
            font-weight: $font-weight-medium;
        }

        [widget="CartCounter"] {
            position: absolute;
            top: -10px;
            left: 14px;
        }
    }

    &__toggle-primary-nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 17px;
        color: $color-ribbon-red;

        .icon {
            width: 21px;
            color: inherit;
        }

        > span {
            text-transform: uppercase;
            font-weight: $font-weight-medium;
            font-size: 10px;
            line-height: 20px;
            letter-spacing: 0.5px;
        }

        .is-open {
            display: none;
        }

        .primary-nav-active & {
            .is-open {
                display: block;
            }

            .is-closed {
                display: none;
            }
        }
    }

    &__dealer-logo {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1rem 0.875rem;
        border-left: 1px solid $color-gallery-gray;

        img {
            display: block;
            max-height: 100%;
            height: 100%;
            width: auto;
        }
    }

    @include media-breakpoint-up(sm) {
        [widget="CartCounter"] {
            left: 36px;
        }
    }

    @include media-breakpoint-up(md) {
        &__inner {
            height: $header-height-md;
        }

        &__logo {
            padding: 16px 24px;

            img,
            svg {
                max-height: 32px;
            }
        }

        .scrolled-down & {
            transform: translateY(-100%);
        }

        &__back {
            margin-right: 2rem;
        }

        &__quick {
            &-item {
                margin-right: 28px;
            }

            [widget="CartCounter"],
            .badge {
                left: 36px;
            }
        }

        &__dealer-logo {
            padding: 1rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .quick-nav {
            display: flex;
            justify-content: flex-end;
        }

        &__toggle-primary-nav {
            display: none;
        }

        &__quick {
            &-item {
                margin-right: 32px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__quick {
            &-link {
                row-gap: 0.375rem;

                > span {
                    display: inline;
                    margin-left: 7px;
                    font-size: 14px;
                }
            }

            [widget="CartCounter"],
            .badge {
                font-size: 12px;
                width: 22px;
                height: 22px;
                left: 48px;
            }
        }

        &__dealer-logo {
            padding: 1rem 1.5rem;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__inner {
            height: $header-height-xxl;
        }

        &__quick {
            font-size: 14px;
        }
    }
}
