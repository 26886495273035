.page-dealer-service {
    .js-service-tool {
        min-height: 520px;

        @include media-breakpoint-up(sm) {
            min-height: 410px;
        }

        @include media-breakpoint-up(md) {
            min-height: 380px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 385px;
        }
    }

    .iframe-block {
        min-height: 1932px;

        @include media-breakpoint-up(sm) {
            min-height: 1760px;
        }

        @include media-breakpoint-up(md) {
            min-height: 1720px;
        }
    }
}
