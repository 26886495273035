.drawer {
    position: fixed;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 10;
    transition: top 0.3s;

    &.open {
        top: 0;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: black;
        color: white;
        width: 48px;
        height: 48px;
        padding: 12px;

        &:hover {
            cursor: pointer;
        }
    }
}
