.occasion-dealer-map .dealers-map {
    margin-top: 0;
    margin-bottom: 0;
}

.occasion-options-block {
    &__section {
        border: 1px solid $color-alto-gray;
        text-align: center;
        border-radius: 2px;
        margin-bottom: 16px;
        padding: 24px;
    }

    &__icon {
        width: 58px;
        margin-bottom: 24px;
    }

    &__button {
        width: 100%;
        color: $color-mine-shaft-gray;
        margin-top: 16px;

        &[disabled],
        &.disabled {
            background: $color-alto-gray;
            color: $color-hit-gray;
        }
    }

    &__ctas {
        margin-top: 24px;
        text-align: center;

        .icon-tooltip use:first-child {
            fill: $color-mine-shaft-gray;
        }

        .button {
            width: 100%;
            margin-bottom: 8px;
        }
    }

    @include media-breakpoint-up(md) {
        &__section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            padding: 32px;
        }

        &__icon {
            flex: 0 0 58px;
            margin-right: 20px;
            margin-bottom: 0;
        }

        &__content {
            flex: 1 1 auto;
        }

        &__button {
            flex: 0 0 auto;
            width: auto;
            margin-top: 0;
        }
    }
}
