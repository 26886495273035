.shopping-cart-block {
    &--commerce {
        margin-block-end: 0;

        @include media-breakpoint-up(md) {
            margin-block-end: 5rem;
        }
    }

    .button--continue {
        --size: 1rem;

        gap: 1rem;
        margin-block-start: 2rem;

        &::before {
            content: "";
            width: var(--size);
            height: var(--size);
            mask-image: var(--button-arrow);
            mask-size: var(--size);
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: currentcolor;
            transform: rotate(180deg);
        }
    }
}
