.skeleton-color-step {
    &__image {
        aspect-ratio: 2/1;
        width: 100%;
        margin-block-end: 1.5rem;
    }

    &__toggle {
        height: 2rem;
        width: 6.25rem;
        border-radius: 1rem;
    }

    &__title {
        height: 1.3125rem;
        width: 8.75rem;
        margin-bottom: 1rem;
    }

    &__color-button {
        --color: #{$color-gallery-gray};

        background-color: $color-white;
        height: 4.75rem;
    }

    @include media-breakpoint-up(md) {
        &__title {
            height: 1.5rem;
            width: 11.375rem;
        }

        &__color-row {
            gap: 1rem;

            > div {
                margin: 0.25rem;
                gap: 0.875rem;
            }
        }
    }
}
