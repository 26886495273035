.editions-carousel-item {
    background: $color-gallery-gray;
    padding: 35px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    &.has-deal &__price strong {
        color: $color-ribbon-red;
    }

    &__sticker {
        @include text-sticker;

        position: absolute;
        left: 8px;
        top: 9px;
        border-radius: 2px;
    }

    &__edition-name {
        font-weight: $font-weight-medium;
        text-transform: none;
        text-align: center;
        height: 32px;
        margin-bottom: 3px;
    }

    &__model-name-wrap {
        position: relative;
    }

    &__model-name {
        @include text-heading;

        font-size: 40px;
        line-height: 1;
        color: rgba($color-mine-shaft-gray, 0.2);
        text-align: center;
        padding: 0 20px;
        margin-bottom: -24px;
    }

    &__image {
        position: relative;
        flex: 0 0 auto;
        margin: 0 auto 5px;
        width: 240px;
    }

    &__prices {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 22px;

        strong {
            @include text-heading;

            margin: 0;
        }
    }

    &__deal {
        @include text-small;

        font-weight: $font-weight-book;
        background: $color-white;
        border: 1px solid $color-alto-gray;
        padding: 7px 13px;
        color: $color-mine-shaft-gray;
        margin: -15px 0 20px;
        position: relative;

        .tooltip-toggler {
            margin-top: -2px;
            top: 3px;
            position: relative;
            width: 16px;
            height: 16px;

            .icon--tooltip {
                width: inherit;
                height: inherit;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: -1px;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 13px;
            height: 13px;
            background: $color-white;
            border: 1px solid $color-alto-gray;
            border-width: 1px 1px 0 0;
            border-radius: 0 2px 0 0;
        }

        & + .editions-carousel-item__deal {
            border-top: none;

            &::before {
                content: none;
            }
        }

        &--highlighted {
            color: $color-ribbon-red;
        }
    }

    &__colors {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 16px;
        margin-bottom: auto;
    }

    &__color {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: 0 8px 8px 0;
        box-shadow: inset 0 0 2px rgb(0 0 0 / 30%);

        &.active::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: url("#{$static-path}/svg/checkmark-white.svg") no-repeat center center;
            background-size: 16px auto;
        }

        &.active::after {
            content: "";
            position: absolute;
            left: -4px;
            top: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border-radius: 50%;
            border: 1px solid $color-stone-gray;
        }
    }

    &__usps {
        border-top: 1px solid $color-alto-gray;
        margin: 0 -20px 12px;
        padding: 19px 20px 0;

        li {
            position: relative;
            padding-left: 21px;
            margin-bottom: 5px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .icon--checkmark {
            width: 12px;
            height: 12px;
            position: absolute;
            top: 6px;
            left: 0;

            path {
                fill: $color-mine-shaft-gray;
            }
        }

        .tooltip-toggler {
            position: relative;
            top: 4px;

            .icon--tooltip {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__cta {
        margin-top: auto;
        margin-bottom: 16px;

        .button {
            width: 100%;
        }
    }

    &__actions {
        @include text-small;

        display: flex;
        justify-content: center;
        margin: 0;
        color: $color-mine-shaft-gray;
        font-weight: $font-weight-medium;
        text-transform: uppercase;

        .input-checkbox__label {
            font-size: inherit;
            font-weight: inherit;

            &::before,
            &::after {
                top: 0;
                background: $color-white;
            }
        }
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 32px;

        > div {
            width: 100%;
        }

        button {
            width: 100%;
            text-align: center;
        }
    }

    &__action:not(:only-child) {
        flex: 0 0 50%;

        &:first-child {
            border-right: 1px solid $color-alto-gray;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 40px 40px 25px;

        &__sticker {
            top: 16px;
            left: 16px;
            font-size: 16px;
        }

        &__image {
            width: 320px;
            margin-bottom: 13px;
        }

        &__model-name {
            margin-bottom: -30px;
        }

        &__prices {
            justify-content: center;
        }

        &__price {
            &:first-child {
                padding-right: 5px;
            }

            &:last-child {
                padding-left: 5px;
            }
        }

        &__usps {
            margin-left: -40px;
            margin-right: -40px;
            padding-left: 40px;
            padding-right: 40px;

            .icon--checkmark {
                width: 16px;
                height: 16px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__edition-name {
            margin-bottom: 6px;
        }

        &__usps {
            margin-bottom: 21px;
        }
    }

    @include media-breakpoint-up(xxl) {
        padding-top: 45px;

        &__image {
            width: 320px;
        }
    }
}
