.divider-block {
    position: relative;
    height: 56px;
    width: 100%;
    background-color: $color-white;

    &__inner {
        background-color: $color-gallery-gray;
        height: 100%;
    }

    &--top {
        transform: scaleY(-1);
    }

    @include media-breakpoint-up(md) {
        height: 80px;
    }

    @include media-breakpoint-up(lg) {
        height: 100px;
    }

    @include media-breakpoint-up(xl) {
        height: 120px;
    }
}

.next-column-grid-block {
    &--light + *:not(.divider-block) {
        background-color: $color-gallery-gray;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 40px;

        @include media-breakpoint-up(md) {
            padding-top: 80px;
        }

        @include media-breakpoint-up(xxl) {
            padding-top: 120px;
        }

        .divider-no-y-margin {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &--transparent + *:not(.divider-block) {
        background-color: transparent;
    }
}

.divider-block + .next-column-grid-block--light + *:not(.divider-block) {
    padding-top: 0;
}
