.page-footer {
    position: relative;
    background: $color-mine-shaft-gray;
    padding: 40px 0;
    color: $color-regent-gray;
    word-break: break-word;
    z-index: 2;

    a:hover {
        color: $color-white;
    }

    .row {
        position: relative;
    }

    &__col {
        margin-bottom: 20px;
    }

    &__title {
        @include text-heading-5;

        color: #fff;
        margin-bottom: 1.2em;

        &:not(.social-title) {
            font-weight: $font-weight-medium;
            text-transform: none;
        }
    }

    &__item {
        font-size: 14px;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $footer-color-body;

        &--whitespace {
            margin-top: 41px;
        }
    }

    &__logos {
        position: relative;
        z-index: 2;
        background: $color-mine-shaft-gray;
        border-bottom: 1px solid rgba($color-porcelain-gray, 0.1);
        padding: 20px 0;

        .block:not(:last-child) {
            margin-right: 20px;
        }

        & img {
            height: 100%;
            width: 100%;
        }
    }

    .social-item {
        flex: 0 0 auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border: 1px solid $white;
        border-radius: 50%;
        margin-right: 6px;

        .icon {
            width: auto;
            height: 15px;
            color: $white;
        }

        &:hover {
            border-color: $footer-color-body;

            .icon {
                color: $footer-color-body;
            }
        }
    }

    .private-lease-link {
        .icon {
            width: 50px;
            height: auto;
        }
    }

    @include media-breakpoint-up(md) {
        &__logos {
            margin-top: -20px;
        }
    }

    @include media-breakpoint-up(xl) {
        padding: 80px 0;

        &__logos {
            margin-top: -55px;
            padding-bottom: 64px;
            border-bottom: 0;

            .block:not(:last-child) {
                margin-right: 40px;
            }
        }

        // Make space for the social column
        &__col:nth-child(4) {
            padding-bottom: 114px;
        }

        // If there are 4 cols, place social on the bottom right
        &__col:nth-child(4) + &__social {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 0;
        }

        &__item--whitespace {
            margin-top: 44px;
        }

        &__item {
            font-size: 16px;
        }
    }
}
