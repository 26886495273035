.product-overview {
    --card-spacing: 1.25rem;

    margin-bottom: 40px;

    &__title {
        line-height: 1;
        margin-bottom: 1.5rem;
    }

    &__sorting {
        margin-bottom: 30px;

        .form-group {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 0;

            .input-label {
                margin-bottom: 0;
                align-self: center;
                margin-right: 16px;
                white-space: nowrap;
            }

            .input-select {
                flex: 0 0 285px;
                max-width: 285px;
            }
        }
    }

    &__list {
        > .row {
            row-gap: var(--card-spacing);
        }

        &.empty {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 340px;
            text-align: center;

            > .row {
                justify-content: center;
            }
        }
    }

    &__reset {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        margin-bottom: 14px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__filters-mobile {
        display: flex;
        margin-bottom: 1.5rem;

        > :first-child {
            flex: 1 1 100%;
        }

        > :last-child {
            position: relative;
            flex: 0 0 auto;
            border-left: 1px solid $white;

            .button {
                position: relative;
                z-index: 2;
                pointer-events: none;
            }

            .input-select__select {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: inherit;
                padding: 0;
                cursor: pointer;
            }
        }
    }

    &__filter-button {
        position: relative;
        width: 100%;
        margin-bottom: 1.125rem;
        background: $color-mine-shaft-gray;
        color: $color-white;
        line-height: 20px;
        justify-content: flex-start;
        padding: 0.625rem 3.5rem 0.625rem 1rem;
        border: none;

        svg {
            position: absolute;
            top: 0.75rem;
            right: 0.75rem;
            height: 1rem;
            width: auto;
            fill: currentcolor;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 2.5rem;
            bottom: 0;
            background: rgba($color-alto-gray, 0.2);
            width: 1px;
        }
    }

    .modal-title {
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        margin-bottom: 3px;
    }

    &__modal-section {
        margin-top: 40px;
    }

    &__pagination {
        margin-top: 1.5rem;
    }

    &__categories-mobile {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        .embla__container {
            column-gap: 0.5rem;
        }

        a {
            display: block;
            flex: 0 0 auto;
            min-width: 0;
            max-width: 100%;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        a span {
            display: flex;
            align-items: center;
            height: 1.5rem;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            border-radius: 0.75rem;
            font-weight: $font-weight-medium;
            background-color: $color-gallery-gray;
        }
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
    }

    &__categories {
        font-weight: $font-weight-medium;

        li {
            margin-bottom: 0.25rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .active {
            color: $color-ribbon-red;
        }

        a:not(.active):hover {
            text-decoration: underline;
        }
    }

    // Extra media query for real small phones.
    @media screen and (width <= 374.98px) {
        &__filters-mobile {
            .button {
                padding-left: 0.5rem;
                padding-right: 3rem;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        &__categories-mobile {
            margin-left: calc(#{$grid-gutter-width-half} * -1);
            width: calc(100% + #{$grid-gutter-width});
            border-top: 1px solid $border-color;

            .embla__viewport {
                padding-left: 1.125rem;
                padding-right: 1.125rem;
            }
        }

        &__filters-mobile {
            margin-left: calc(#{$grid-gutter-width-half} * -1);
            width: calc(100% + #{$grid-gutter-width});
        }
    }

    @include media-breakpoint-down(xl) {
        &__list {
            > .row {
                padding-left: 0.625rem;
                padding-right: 0.625rem;
            }

            [class^="col"] {
                padding-left: calc(var(--card-spacing) / 2);
                padding-right: calc(var(--card-spacing) / 2);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;

        &__sorting {
            margin-bottom: 36px;
        }

        &__list {
            &.empty {
                min-height: 500px;
            }
        }

        &__pagination {
            margin-top: 2.5rem;
        }

        &__categories-mobile {
            display: none;
        }
    }

    @include media-breakpoint-up(xl) {
        &__sidebar {
            margin-right: 35px;
        }
    }

    @include media-breakpoint-up(xxl) {
        --card-spacing: 2.5rem;

        margin-bottom: 120px;
    }
}
