.product-teaser-block {
    margin: 80px 0;
    position: relative;

    &__row {
        justify-content: center;
    }

    &__more-link {
        text-align: center;
        margin-top: 30px;
    }

    &.has-2-items &__row > .col-12 {
        &:first-child {
            margin-bottom: 18px;
        }
    }

    @include media-breakpoint-up(md) {
        margin: 120px 0;

        &__more-link {
            margin-top: 60px;
        }

        .glide {
            padding-bottom: 108px;
        }

        .glide-controls {
            margin-top: 56px;
        }

        &__controls-wrap {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
        }

        &.has-slider &__more-link {
            position: absolute;
            bottom: 5px;
            right: 20px;
            margin-top: 0;
        }

        &.has-2-items &__row > .col-12 {
            &:first-child {
                padding-right: 10px;
                margin-bottom: 0;
            }

            &:last-child {
                padding-left: 10px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin: 160px 0;

        &.has-2-items &__row > .col-12 {
            &:first-child {
                padding-right: 20px;
            }

            &:last-child {
                padding-left: 20px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .glide {
            padding-bottom: 132px;
        }

        .glide-controls {
            margin-top: 80px;
        }

        &__more-link {
            margin-top: 80px;
        }
    }
}
