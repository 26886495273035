.dealer-list {
    &__container {
        border-top: 1px solid $color-alto-gray;
    }

    .scroll-container {
        overflow-y: scroll;
        max-height: 620px;
    }

    .no-results-found {
        padding: 16px $grid-gutter-width-half;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__amount-found {
        @include text-small;

        font-weight: $font-weight-book;
    }

    &__search {
        flex: 0 0 auto;
    }

    &__header {
        padding: 1.5rem 1rem 1rem;
    }

    @include media-breakpoint-up(md) {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        &__container {
            flex: 1 0 0;
            position: relative;
        }

        .scroll-container {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            max-height: none;
        }
    }

    @include media-breakpoint-up(xl) {
        &__header {
            padding: 1.5rem 1.5rem 1rem;
        }
    }
}
