@use "sass:list";

$vitara-colors-map: (
    fdc: (
        #000,
        #4b4b3f,
    ),
    e86: (
        #000,
        #801726,
    ),
    e5g: (
        #000,
        #eeeee9,
    ),
    e85: (
        #000,
        #b4bbbf,
    ),
    ffz: (
        #000,
        #545554,
    ),
    wa1: (
        #4b4b3f,
    ),
    wbh: (
        #32415d,
    ),
    zhj: (
        #eeeee9,
    ),
    wbe: (
        #b4bbbf,
    ),
    wbf: (
        #545554,
    ),
    wb3: (
        #000,
    ),
);

.full-color-lineup {
    position: relative;
    background-color: $white;
    overflow-y: hidden;

    &__list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 0.5rem;
        margin-bottom: 2rem;
    }

    &__color {
        width: 100%;
        aspect-ratio: 1/1;
        transition:
            border 50ms ease-in,
            opacity 150ms ease-in;

        &:not(.selected):hover {
            opacity: 0.8;
        }

        &.selected {
            border: 5px solid #007cba;
        }
    }

    @each $id, $colors in $vitara-colors-map {
        &__color--#{$id} {
            position: relative;
            background-color: list.nth($colors, 1);

            @if list.length($colors) > 1 {
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    height: 50%;
                    width: 100%;
                    background-color: list.nth($colors, 2);
                }
            }
        }
    }

    &__background {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        padding-block: 1.5rem;
    }

    &__visual {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;

        > img:first-of-type {
            width: 100%;
            height: auto;
            padding-right: 15%;
        }

        > img:nth-child(2) {
            position: absolute;
            right: 0;
            top: 0;
            height: 90%;
            width: auto;
        }
    }

    &__name {
        text-align: center;
    }

    @include media-breakpoint-up(sm) {
        &__list {
            grid-template-columns: repeat(8, 1fr);
        }
    }

    @include media-breakpoint-up(md) {
        &__list {
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: 0;
        }

        &__content {
            padding-block: 3rem;
        }
    }

    @include media-breakpoint-up(lg) {
        &__list {
            gap: 1rem;
        }
    }

    @include media-breakpoint-up(xl) {
        &__list {
            gap: 1.5rem;
        }
    }
}
