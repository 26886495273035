.primary-nav {
    .header-vehicle-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        &__link {
            display: block;
            flex: 0 0 auto;
        }

        &__title {
            @include text-heading-5;

            margin-top: auto;
            margin-bottom: 6px;
            display: block;
            text-align: center;
        }

        &__label {
            display: block;
            text-align: center;
            font-size: 14px;
            font-weight: $font-weight-book;
            line-height: 18px;
            color: $font-color-body-light;
        }
    }

    .overview-block__image {
        max-width: 265px;
        margin: 0 auto 10px;
    }
}
