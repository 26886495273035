@import "../abstracts/fluid-sizes";

.layout-grid {
    --layout-grid-min: #{fluid(160px, 264px, 400px, 768px)};
    --layout-grid-col-gap: #{fluid(8px, 24px)};
    --layout-grid-row-gap: 1.5rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, var(--layout-grid-min)), 1fr));
    column-gap: var(--layout-grid-col-gap);
    row-gap: var(--layout-grid-row-gap);
}
