.youtube-block {
    .iframe-container {
        position: relative;
        padding-bottom: 56.25%; // = 16:9
    }

    iframe {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
    }

    &__lazy {
        .iframe-container {
            padding-bottom: 0;
        }
    }
}
