@import "./config";
@import "./base";
@import "./components";

:root {
    @include map-to-css-variables(
        (
            "btn": $button,
            "typo": $typography,
            "banner": $banner,
            "pc": $preference-center,
            "policy": $policy,
        )
    );
}

body {
    // Root container
    & > #onetrust-consent-sdk {
        // Banner container
        #onetrust-banner-sdk {
            background-color: var(--ot-lg-banner-background-color, #fff);
            border-radius: var(--ot-lg-banner-border-radius, 0);
            width: var(--ot-lg-banner-width, 768px);
            max-height: calc(100% - var(--ot-lg-banner-margin, 1rem));
            max-width: calc(100% - var(--ot-lg-banner-margin, 1rem));
            box-shadow: var(--ot-lg-banner-shadow, 0 0 18px rgba(0 0 0 / 20%));

            &:focus {
                outline: none;
            }

            .ot-sdk-container {
                &::after {
                    content: none;
                }

                .ot-sdk-row {
                    display: grid;
                    grid-template-rows: 2.75rem 1fr auto;
                    grid-template-columns: 1fr 2.75rem;
                    gap: 2rem 0.5rem;

                    #onetrust-group-container {
                        grid-area: 1 / 1 / 3 / 2;
                        display: flex;
                        flex-direction: column;

                        #onetrust-policy {
                            padding: var(--ot-lg-banner-padding, 1.5rem) 0 0
                                var(--ot-lg-banner-padding, 1.5rem);
                            margin-top: 0;

                            // Banner title.
                            #onetrust-policy-title {
                                font-family: var(--ot-lg-typo-title-font-family, sans-serif);
                                font-size: var(--ot-lg-typo-title-font-size, 1.25rem);
                                font-weight: var(--ot-lg-typo-title-font-weight, 700);
                                line-height: var(--ot-lg-typo-title-line-height, 1.5);
                                color: var(--ot-lg-typo-title-color);
                                float: none;
                                padding: 0;
                                width: 100%;
                                margin-bottom: 1rem;
                            }

                            // Banner text.
                            #onetrust-policy-text {
                                font-family: var(--ot-lg-typo-text-font-family, sans-serif);
                                font-size: var(--ot-lg-typo-text-font-size, 1rem);
                                font-weight: var(--ot-lg-typo-text-font-weight, 400);
                                line-height: var(--ot-lg-typo-text-line-height, 1.6);
                                color: var(--ot-lg-typo-text-color, #000);
                                margin: 0;

                                a {
                                    color: var(--ot-lg-typo-link-color);
                                    text-decoration: underline;

                                    &:hover {
                                        color: var(--ot-lg-typo-link-color-hover);
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }

                    // Banner button group.
                    #onetrust-button-group-parent {
                        grid-area: 3 / 1 / 4 / 3;
                        padding: 0 var(--ot-lg-banner-padding) var(--ot-lg-banner-padding);

                        #onetrust-button-group {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            gap: 1rem;

                            // Banner buttons.
                            #onetrust-reject-all-handler,
                            #onetrust-accept-btn-handler,
                            #onetrust-pc-btn-handler {
                                font-family: var(--ot-lg-btn-all-font-family);
                                font-size: var(--ot-lg-btn-all-font-size);
                                font-weight: var(--ot-lg-btn-all-font-weight);
                                line-height: var(--ot-lg-btn-all-line-height);
                                text-transform: var(--ot-lg-btn-all-text-transform);
                                letter-spacing: var(--ot-lg-btn-all-letter-spacing);
                                border-radius: var(--ot-lg-btn-all-border-radius);
                                border: var(--ot-lg-btn-all-border);
                                padding: var(--ot-lg-btn-all-padding);
                                width: 100%;
                                min-width: 0;
                                transition-duration: 150ms;
                                transition-timing-function: ease-in-out;
                                transition-property: color, border-color, background-color;
                                clip-path: var(--ot-lg-btn-all-clip-path);

                                @media screen and (width >= 768px) {
                                    width: auto;
                                }

                                &:hover,
                                &:focus {
                                    opacity: 1 !important;
                                }

                                &:focus {
                                    outline: none;
                                }
                            }

                            .banner-actions-container {
                                display: flex;
                                flex-wrap: wrap;
                                order: 1;
                                gap: 1rem;
                                float: none;
                                width: 100%;
                                max-width: none;

                                @media screen and (width >= 768px) {
                                    order: 2;
                                    width: auto;
                                }

                                #onetrust-reject-all-handler,
                                #onetrust-accept-btn-handler {
                                    margin: 0;
                                }

                                #onetrust-accept-btn-handler {
                                    order: 1;
                                    color: var(--ot-lg-btn-accept-all-color) !important;
                                    border-color: var(
                                        --ot-lg-btn-accept-all-border-color
                                    ) !important;
                                    background: var(--ot-lg-btn-accept-all-background) !important;
                                    box-shadow: var(--ot-lg-btn-accept-all-box-shadow) !important;
                                    text-shadow: var(--ot-lg-btn-accept-all-text-shadow) !important;

                                    @media screen and (width >= 768px) {
                                        order: 2;
                                    }

                                    &:hover {
                                        color: var(--ot-lg-btn-accept-all-color-hover) !important;
                                        border-color: var(
                                            --ot-lg-btn-accept-all-border-color-hover
                                        ) !important;
                                        background: var(
                                            --ot-lg-btn-accept-all-background-hover
                                        ) !important;
                                    }
                                }

                                #onetrust-reject-all-handler {
                                    order: 2;
                                    color: var(--ot-lg-btn-reject-all-color) !important;
                                    border-color: var(
                                        --ot-lg-btn-reject-all-border-color
                                    ) !important;
                                    background: var(--ot-lg-btn-reject-all-background) !important;
                                    box-shadow: var(--ot-lg-btn-reject-all-box-shadow) !important;
                                    text-shadow: var(--ot-lg-btn-reject-all-text-shadow) !important;

                                    @media screen and (width >= 768px) {
                                        order: 1;
                                    }

                                    &:hover {
                                        color: var(--ot-lg-btn-reject-all-color-hover) !important;
                                        border-color: var(
                                            --ot-lg-btn-reject-all-border-color-hover
                                        ) !important;
                                        background: var(
                                            --ot-lg-btn-reject-all-background-hover
                                        ) !important;
                                    }
                                }
                            }

                            #onetrust-pc-btn-handler {
                                order: 1;
                                float: none;
                                max-width: none;
                                margin: 0;
                                color: var(--ot-lg-btn-settings-color) !important;
                                border-color: var(--ot-lg-btn-settings-border-color) !important;
                                background-color: var(--ot-lg-btn-settings-background) !important;
                                box-shadow: var(--ot-lg-btn-settings-box-shadow) !important;
                                text-shadow: var(--ot-lg-btn-settings-text-shadow) !important;

                                &:hover {
                                    color: var(--ot-lg-btn-settings-color-hover) !important;
                                    border-color: var(
                                        --ot-lg-btn-settings-border-color-hover
                                    ) !important;
                                    background-color: var(
                                        --ot-lg-btn-settings-background-hover
                                    ) !important;
                                }
                            }
                        }
                    }

                    #onetrust-close-btn-container {
                        grid-area: 1 / 2 / 2 / 3;
                        place-items: center;
                        position: static;
                        width: auto;
                        height: auto;

                        // For the time being we disabled the close button to increase the cookie acceptance.
                        // display: grid;
                        display: none !important;

                        .ot-close-icon {
                            border-radius: 0;
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            transition: background-color 150ms ease-in-out;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        // Preference center container
        #onetrust-pc-sdk {
            #accept-recommended-btn-handler,
            .ot-pc-refuse-all-handler,
            .save-preference-btn-handler {
                font-family: var(--ot-lg-btn-all-font-family);
                font-size: var(--ot-lg-btn-all-font-size);
                font-weight: var(--ot-lg-btn-all-font-weight);
                line-height: var(--ot-lg-btn-all-line-height);
                text-transform: var(--ot-lg-btn-all-text-transform);
                letter-spacing: var(--ot-lg-btn-all-letter-spacing);
                border-radius: var(--ot-lg-btn-all-border-radius);
                border: var(--ot-lg-btn-all-border);
                padding: var(--ot-lg-btn-all-padding);
                width: 100%;
                min-width: 0;
                transition-duration: 150ms;
                transition-timing-function: ease-in-out;
                transition-property: color, border-color, background-color;
                clip-path: var(--ot-lg-btn-all-clip-path);

                &:hover,
                &:focus {
                    opacity: 1 !important;
                }
            }

            .ot-pc-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.5rem 0;
                height: auto;

                .ot-pc-logo {
                    margin-left: 30px;
                    height: 2rem;
                    width: auto;
                    justify-content: flex-start;
                }

                #close-pc-btn-handler {
                    width: 2.75rem;
                    height: 2.75rem;
                    background-size: 0.875rem;
                    transition: background-color 150ms ease-in-out;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            #ot-pc-content {
                & * {
                    float: none;
                }

                .privacy-notice-link {
                    color: $primary;
                    font-weight: $font-weight-light;
                    text-decoration: none;
                    margin-top: 1rem;

                    &:hover {
                        color: $primary;
                        text-decoration: underline;
                    }
                }

                // Preference center title.
                #ot-pc-title {
                    font-family: var(--ot-lg-typo-title-font-family);
                    font-size: var(--ot-lg-typo-title-font-size);
                    font-weight: var(--ot-lg-typo-title-font-weight);
                    line-height: var(--ot-lg-typo-title-line-height);
                    color: var(--ot-lg-typo-title-color);
                }

                // Preference center description.
                #ot-pc-desc {
                    font-family: var(--ot-lg-typo-text-font-family);
                    font-size: var(--ot-lg-typo-text-font-size);
                    font-weight: var(--ot-lg-typo-text-font-weight);
                    line-height: var(--ot-lg-typo-text-line-height);
                    color: var(--ot-lg-typo-text-color);
                }

                // Preference center accept all button.
                #accept-recommended-btn-handler {
                    color: var(--ot-lg-btn-accept-all-color) !important;
                    border-color: var(--ot-lg-btn-accept-all-border-color) !important;
                    background: var(--ot-lg-btn-accept-all-background) !important;
                    box-shadow: var(--ot-lg-btn-accept-all-box-shadow) !important;
                    text-shadow: var(--ot-lg-btn-accept-all-text-shadow) !important;
                    max-width: none;

                    &:hover {
                        color: var(--ot-lg-btn-accept-all-color-hover) !important;
                        border-color: var(--ot-lg-btn-accept-all-border-color-hover) !important;
                        background: var(--ot-lg-btn-accept-all-background-hover) !important;
                    }
                }

                // Panel category group.
                .ot-cat-grp {
                    padding-bottom: 1rem;

                    #ot-category-title {
                        font-family: var(--ot-lg-typo-subtitle-font-family);
                        font-size: var(--ot-lg-typo-subtitle-font-size);
                        font-weight: var(--ot-lg-typo-subtitle-font-weight);
                        line-height: var(--ot-lg-typo-subtitle-line-height);
                        color: var(--ot-lg-typo-subtitle-color);
                    }

                    // Category accordion item
                    .ot-cat-item {
                        border-radius: 0;

                        // Panel category item title.
                        .ot-cat-header {
                            font-family: var(--ot-lg-typo-category-header-font-family);
                            font-size: var(--ot-lg-typo-category-header-font-size);
                            font-weight: var(--ot-lg-typo-category-header-font-weight);
                            color: var(--ot-lg-typo-category-header-color);
                            line-height: 2;
                            width: auto;
                            max-width: none;
                        }

                        // Panel "always active" text.
                        .ot-always-active {
                            font-size: var(--ot-lg-typo-subtext-font-size);
                            color: var(--ot-lg-typo-subtext-color);
                            line-height: var(--ot-lg-typo-subtext-line-height);
                        }

                        // Panel category item description.
                        .ot-category-desc {
                            font-family: var(--ot-lg-typo-category-desc-font-family);
                            font-size: var(--ot-lg-typo-category-desc-font-size);
                            font-weight: var(--ot-lg-typo-category-desc-font-weight);
                            line-height: var(--ot-lg-typo-category-desc-line-height);
                            color: var(--ot-lg-typo-category-desc-color);
                            background-color: var(--ot-lg-typo-category-desc-background-color);
                            padding: 1.25rem;
                        }
                    }
                }
            }

            .ot-tgl {
                .ot-switch .ot-switch-nob {
                    background-color: var(--ot-lg-pc-toggle-off-background-color) !important;

                    &::before {
                        background-color: var(--ot-lg-pc-toggle-off-color) !important;
                    }
                }

                input:focus + .ot-switch .ot-switch-nob {
                    box-shadow: none;
                    outline: 0 !important;
                }

                input:checked + .ot-switch .ot-switch-nob {
                    background-color: var(--ot-lg-pc-toggle-on-background-color) !important;
                    border-color: $color-mine-shaft-gray;

                    &::before {
                        background-color: var(--ot-lg-pc-toggle-on-color) !important;
                    }
                }
            }

            .ot-pc-footer {
                .ot-btn-container {
                    // Panel refuse all button.
                    .ot-pc-refuse-all-handler {
                        color: var(--ot-lg-btn-reject-all-color) !important;
                        border-color: var(--ot-lg-btn-reject-all-border-color) !important;
                        background: var(--ot-lg-btn-reject-all-background) !important;
                        box-shadow: var(--ot-lg-btn-reject-all-box-shadow) !important;
                        text-shadow: var(--ot-lg-btn-reject-all-text-shadow) !important;

                        &:hover {
                            color: var(--ot-lg-btn-reject-all-color-hover) !important;
                            border-color: var(--ot-lg-btn-reject-all-border-color-hover) !important;
                            background: var(--ot-lg-btn-reject-all-background-hover) !important;
                        }
                    }

                    // Panel save preferences button.
                    .save-preference-btn-handler {
                        color: var(--ot-lg-btn-settings-color) !important;
                        border-color: var(--ot-lg-btn-settings-border-color) !important;
                        background: var(--ot-lg-btn-settings-background) !important;
                        box-shadow: var(--ot-lg-btn-settings-box-shadow) !important;
                        text-shadow: var(--ot-lg-btn-settings-text-shadow) !important;

                        &:hover {
                            color: var(--ot-lg-btn-settings-color-hover) !important;
                            border-color: var(--ot-lg-btn-settings-border-color-hover) !important;
                            background: var(--ot-lg-btn-settings-background-hover) !important;
                        }
                    }
                }

                // Disable the OneTrust logo.
                .ot-pc-footer-logo {
                    display: none;
                }
            }
        }

        // Floating cookie button
        #ot-sdk-btn-floating {
            display: none;
        }
    }

    // Open settings button
    #ot-sdk-btn {
        font-family: var(--ot-lg-btn-all-font-family);
        font-size: var(--ot-lg-btn-all-font-size);
        font-weight: var(--ot-lg-btn-all-font-weight);
        line-height: var(--ot-lg-btn-all-line-height);
        text-transform: var(--ot-lg-btn-all-text-transform);
        letter-spacing: var(--ot-lg-btn-all-letter-spacing);
        border-radius: var(--ot-lg-btn-all-border-radius);
        border: var(--ot-lg-btn-all-border);
        padding: var(--ot-lg-btn-all-padding);
        width: 100%;
        min-width: 0;
        transition-duration: 150ms;
        transition-timing-function: ease-in-out;
        transition-property: color, border-color, background-color;
        clip-path: var(--ot-lg-btn-all-clip-path);
        color: var(--ot-lg-btn-settings-color) !important;
        border-color: var(--ot-lg-btn-settings-border-color) !important;
        background: var(--ot-lg-btn-settings-background) !important;
        box-shadow: var(--ot-lg-btn-settings-box-shadow) !important;
        text-shadow: var(--ot-lg-btn-settings-text-shadow) !important;
        max-width: none;

        &:hover,
        &:focus {
            opacity: 1 !important;
        }

        &:hover {
            color: var(--ot-lg-btn-settings-color-hover) !important;
            border-color: var(--ot-lg-btn-settings-border-color-hover) !important;
            background: var(--ot-lg-btn-settings-background-hover) !important;
        }
    }

    // Cookie policy tables.
    #ot-sdk-cookie-policy {
        #ot-sdk-cookie-policy-v2 {
            #cookie-policy-title {
                font-family: var(--ot-lg-typo-title-font-family);
                font-size: var(--ot-lg-typo-title-font-size);
                font-weight: var(--ot-lg-typo-title-font-weight);
                line-height: var(--ot-lg-typo-title-line-height);
                color: var(--ot-lg-typo-title-color);
            }

            .ot-sdk-cookie-policy-group {
                font-family: var(--ot-lg-typo-subtitle-font-family);
                font-size: var(--ot-lg-typo-subtitle-font-size);
                font-weight: var(--ot-lg-typo-subtitle-font-weight);
                line-height: var(--ot-lg-typo-subtitle-line-height);
                color: var(--ot-lg-typo-subtitle-color);
            }

            .ot-sdk-cookie-policy-group-desc {
                font-family: var(--ot-lg-typo-text-font-family);
                font-size: var(--ot-lg-typo-text-font-size);
                font-weight: var(--ot-lg-typo-text-font-weight);
                line-height: var(--ot-lg-typo-text-line-height);
                color: var(--ot-lg-typo-text-color);
            }

            table {
                border-radius: 0;

                th {
                    color: var(--ot-lg-policy-table-heading-color);
                    background-color: var(--ot-lg-policy-table-heading-background-color);
                }

                td {
                    color: var(--ot-lg-policy-table-cell-color);
                    background-color: var(--ot-lg-policy-table-cell-background-color);
                }
            }
        }
    }
}
