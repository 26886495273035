.highlights-carousel-block {
    --slide-width: 92%;

    &__slide {
        flex: 0 0 var(--slide-width);
        max-width: var(--slide-width);
    }

    .embla__container {
        gap: 1.25rem;
    }

    .embla__nav {
        display: none;
        position: relative;
        justify-content: center;
        transform: none;
        margin-top: 1rem;
        column-gap: 1rem;
    }

    .embla__prev,
    .embla__next {
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        --slide-width: 83%;

        .embla__container {
            gap: 1.875rem;
        }

        .embla__nav {
            display: flex;
            margin-top: 2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        --slide-width: 40%;

        .embla__container {
            gap: 2.5rem;
        }

        .embla__nav {
            margin-top: 3.75rem;
        }
    }
}
