.skeleton-edition-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-gallery-gray;
    width: 100%;
    padding: 24px 24px 16px;

    &__title {
        height: 12px;
        width: 100%;
        margin-bottom: 24px;
    }

    &__image {
        aspect-ratio: 2/1;
        width: 100%;
        height: auto;
        margin-bottom: 24px;
    }

    &__price {
        height: 12px;
        width: 80px;
        margin-bottom: 30px;
    }

    &__usps {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-self: flex-start;
        margin-bottom: 30px;

        > * {
            height: 8px;
        }

        > :nth-child(even) {
            width: 120px;
        }

        > :nth-child(odd) {
            width: 144px;
        }
    }

    &__button {
        height: 40px;
        width: 100%;
        margin-bottom: 16px;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > :nth-child(1),
        > :nth-child(3) {
            height: 8px;
            width: 96px;
        }

        > :nth-child(2) {
            height: 24px;
            width: 1px;
        }
    }

    &__line {
        width: calc(100% + 48px);
        height: 1px;
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: 24px;
    }

    @include media-breakpoint-up(md) {
        padding: 32px 32px 16px;

        &__title {
            height: 20px;
        }

        &__button {
            height: 68px;
        }

        &__line {
            width: calc(100% + 64px);
            margin-left: -32px;
            margin-right: -32px;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 48px 48px 16px;

        &__line {
            width: calc(100% + 96px);
            margin-left: -48px;
            margin-right: -48px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__button {
            height: 84px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__button {
            height: 64px;
        }
    }
}
