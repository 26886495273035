.rating-block {
    --highlight-color: #f6bb00;

    position: relative;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    &__stars {
        position: relative;
        display: flex;
        column-gap: 0.125rem;
    }

    .icon {
        display: block;
        height: 1rem;
        flex: 0 0 auto;
        width: auto;
        fill: $color-alto-gray;
    }

    &__stars.highlight {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        white-space: nowrap;

        .icon {
            fill: var(--highlight-color);
        }
    }

    &__reviews {
        @include text-small;

        text-decoration: underline;
    }

    @include media-breakpoint-up(lg) {
        column-gap: 0.75rem;

        .icon {
            height: 1.25rem;
        }
    }

    @include media-breakpoint-up(xl) {
        .icon {
            height: 1.5rem;
        }
    }
}
