.trade-in-benefits {
    h5 {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 12px;
    }

    .icon {
        width: 16px;
        height: auto;
        fill: $color-mine-shaft-gray;
        top: 50%;
        transform: translate3d(0, -50%, 0);
    }

    .rich-text p {
        font-size: 14px;
        line-height: 21px;
    }

    [class^="col-"] {
        // We need to override the column offset and with
        margin-left: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    @include media-breakpoint-up(md) {
        h5 {
            font-size: 18px;
            line-height: 21px;
        }
    }

    @include media-breakpoint-up(lg) {
        h5 {
            font-size: 20px;
            line-height: 24px;
        }
    }

    @include media-breakpoint-up(xl) {
        h5 {
            font-size: 20px;
            line-height: 24px;
        }
    }
}
