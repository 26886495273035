.Cart {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 2rem;

    &-lineItems {
        display: grid;
        align-items: flex-start;

        // small flex hack to let this one always grow harder
        flex-grow: 9999;
        flex-basis: 0;
        padding: 0;
        margin: 0;
        gap: 1.5rem;
        list-style: none;

        /* stylelint-disable-next-line no-descending-specificity */
        > li {
            display: grid;
            padding: 1rem;
            border: 1px solid $color-alto-gray;
            gap: 1rem;
            grid-template-columns: max-content 1fr;
            grid-template-areas:
                "image content"
                "availability availability";

            > .Availability {
                grid-area: availability;
                align-self: flex-end;
            }

            @include media-breakpoint-up(md) {
                gap: 1rem 2rem;
                grid-template-areas:
                    "image content"
                    "image availability";
                padding: 2rem;
            }
        }

        figure {
            position: relative;
            grid-area: image;
            margin: 0;

            > img {
                block-size: auto;
                inline-size: 5rem;
                aspect-ratio: 3/2;
                object-fit: contain;
                display: grid;
                background-color: $white;
                font-size: 0;

                @include media-breakpoint-up(md) {
                    inline-size: 13rem;
                }

                &::before {
                    content: "";
                    inline-size: 100%;
                    block-size: 100%;
                    display: grid;
                    place-items: center;
                    padding: 1rem;
                    background-image: url($product-fallback-img);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100%;
                }
            }

            > .LineItems-badge {
                position: absolute;
                top: fluid(4px, 12px);
                left: 0;
                z-index: 1;
                background-color: $color-ribbon-red;
                color: $color-white;
                padding-inline: fluid(2px, 4px);
                font-size: fluid(12px, 14px);
                text-transform: uppercase;
                font-weight: 800;
            }
        }
    }

    &-lineItemsContent {
        display: grid;
        grid-area: content;
        align-self: stretch;
        gap: 1rem;
    }

    &-lineItemsHeader,
    &-lineItemsBody {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
    }

    &-lineItemsHeader {
        @include text-body;

        align-items: flex-start;

        > strong {
            font-weight: $font-weight-medium;
            max-inline-size: 23rem;

            @include media-breakpoint-up(md) {
                font-size: 21px;
            }
        }
    }

    &-lineItemsBody {
        align-items: center;

        > :first-child {
            width: auto;
        }
    }

    &-lineItemsRemove {
        --size: 1.5rem;

        width: var(--size);
        height: var(--size);
        mask-image: var(--remove);
        mask-size: var(--size);
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: currentcolor;
        flex-shrink: 0;

        > span {
            @include screenreader-only;
        }
    }

    &-aside {
        flex-basis: 25rem;
        flex-grow: 1;

        // aybe enable to show decimals in overview
        // .Money[data-value*=".00"] {
        //     > .Money-decimal::after {
        //         content: normal;
        //     }

        //     > .Money-fraction {
        //         display: initial;
        //     }
        // }
    }

    &-costOverview {
        display: grid;
        padding: 1rem;
        background-color: $color-gallery-gray;
        gap: 1rem;

        @include media-breakpoint-up(md) {
            padding: 2rem;
        }

        header {
            display: flex;
            justify-content: space-between;
        }

        small {
            @include text-small;
        }

        table {
            width: 100%;

            th {
                text-align: left;
            }

            td {
                text-align: right;
            }

            tbody {
                @include text-body;

                font-weight: $font-weight-medium;

                @include media-breakpoint-up(md) {
                    font-size: 21px;
                }

                > :last-child {
                    th,
                    td {
                        padding-block-end: 1rem;
                    }
                }
            }

            tfoot {
                &,
                .Money {
                    @include text-intro;

                    @include media-breakpoint-up(md) {
                        font-size: 27px;
                    }
                }

                border-block-start: 1px solid $color-mine-shaft-gray;

                th {
                    font-weight: $font-weight-medium;
                }

                .Money {
                    font-weight: $font-weight-heavy;
                }

                th,
                td {
                    padding-block-start: 1rem;
                }
            }
        }
    }

    &-paymentDescription {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        gap: 1rem;

        p {
            @include text-medium;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 0.5rem;
            margin: 0;
        }

        & img {
            width: auto;
            height: 2rem;
        }

        @include media-breakpoint-up(sm) {
            p {
                gap: 1rem;
            }
        }
    }

    &-quantityError,
    &-dealerWarning {
        padding: 1rem;
        border: 1px solid hsl(49deg 100% 48%);
        background-color: rgb(246 201 0 / 10%);
        font-size: 0.875rem;
        inline-size: 100%;
    }

    &-outOfStockError {
        padding: 1rem;
        border: 1px solid hsl(345deg 100% 44%);
        background-color: rgb(222 0 57 / 10%);
        color: hsl(345deg 100% 44%);
        font-size: 0.875rem;
        inline-size: 100%;
    }

    .button--primary {
        --size: 1rem;

        gap: 1rem;

        &::after {
            content: "";
            width: var(--size);
            height: var(--size);
            mask-image: var(--button-arrow);
            mask-size: var(--size);
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: currentcolor;
        }
    }

    .Money-currency,
    .Money-literal {
        display: inline-flex;
    }
}
