.shopping-cart-block {
    margin: 40px 0;

    &__title {
        font-weight: $font-weight-book;
        text-align: center;
        margin-bottom: 28px;
    }

    &__list {
        &-item {
            display: flex;
            border: 1px solid $color-alto-gray;
            margin-bottom: 16px;
            position: relative;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &-image {
            position: relative;
            flex: 0 0 140px;
            align-self: stretch;
            overflow: hidden;
            background-size: cover;
            background-position: center;
        }

        &-remove {
            @include text-small;

            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .icon {
                width: 14px;
                height: 14px;
                margin-right: 6px;
            }
        }

        &-content {
            flex: 1;
            padding: 16px;

            .input-select {
                width: auto;
                min-width: 100px;
            }
        }

        &-prices {
            margin-top: 10px;
        }

        &-normal-price {
            @include text-heading-5;

            margin-bottom: 0;
        }

        &-new-price {
            @include text-heading-5;

            margin-bottom: 0;
        }

        &-old-price {
            @include text-small;

            text-decoration: line-through;
        }

        &-available,
        &-unavailable {
            display: flex;
            align-items: flex-start;
            font-size: 14px;
            line-height: 21px;
            text-transform: initial;
            margin-bottom: 8px;

            .icon {
                flex: 0 0 auto;
                height: 14px;
                width: 14px;
                margin-right: 4px;
                margin-top: 3px;
            }
        }

        &-quantity {
            flex: 0 0 auto;
        }
    }

    &__receipt {
        background: $color-athens-gray;
        padding: 24px;
        margin-top: 40px;

        h4 {
            margin-bottom: 14px;
        }

        &-category {
            @include text-small;

            margin-bottom: 1em;
        }

        &-item {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: $font-weight-heavy;
            display: flex;
            margin-bottom: 16px;
            color: $color-mine-shaft-gray;

            &.total {
                margin-bottom: 0;

                & > * {
                    @include text-heading-5;

                    margin-bottom: 0;
                }
            }
        }

        &-title {
            flex: 1;
            margin-right: 16px;
            width: 50%;
            overflow-wrap: break-word;

            p {
                @include text-heading-5;

                margin-bottom: 0;
            }
        }

        &-amount {
            @include text-heading-5;

            font-weight: $font-weight-book;
            text-transform: initial;
            flex: 0 0 auto;
            margin-bottom: 0;
            margin-right: 16px;
        }

        &-remove {
            flex: 0 0 16px;
            align-self: flex-start;
            margin-right: 8px;

            .icon {
                width: 100%;
                pointer-events: none;
            }

            .icon use,
            .icon path {
                fill: $color-ribbon-red;
            }
        }

        &-prices {
            flex: 0 0 65px;
            margin-left: auto;
            text-align: right;

            &.total {
                flex: 1 0 auto;
            }
        }

        &-old-price {
            @include text-small;

            color: $font-color-body;
            text-decoration: line-through;
            margin-top: -2px;
        }

        &-new-price,
        &-normal-price {
            @include text-heading-5;

            margin-bottom: 0;
        }

        hr {
            margin-left: -24px;
            margin-right: -24px;
        }

        &-submit {
            width: 100%;
            margin-top: 16px;
        }

        &-available {
            @include text-small;

            text-transform: initial;
        }

        &-unavailable {
            @include text-small;

            text-transform: initial;
        }
    }

    @include media-breakpoint-up(md) {
        margin: 80px 0;

        &__receipt {
            &-prices {
                flex: 0 0 85px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__title {
            margin-bottom: 64px;
        }

        &__list {
            &-content {
                display: flex;
                align-items: center;
                padding: 24px 32px;
            }

            &-prices {
                flex: 0 0 auto;
                text-align: right;
            }

            &-remove {
                font-size: 14px;
                margin-bottom: 0;
            }

            &-remove .icon {
                position: relative;
                top: -1px;
            }

            &-image {
                flex: 0 0 160px;
            }
        }

        &__receipt {
            margin-top: 0;

            &-item .icon {
                position: relative;
                top: -1px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__receipt {
            padding: 40px;

            &-title p,
            &-amount,
            &-new-price,
            &-normal-price {
                font-size: 16px;
                line-height: 19px;
            }

            &-submit {
                padding: 14px 28px;
                font-size: 14px;
                margin-top: 20px;
            }
        }

        hr {
            margin-left: -40px;
            margin-right: -40px;
        }
    }

    @include media-breakpoint-up(xxl) {
        margin: 110px 0;

        &__receipt-item .icon {
            top: -3px;
        }
    }
}
