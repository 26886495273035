.accordion {
    &__header {
        position: relative;
        background-color: $color-gallery-gray;
        padding: 15px 30px;
        text-transform: uppercase;
        font-weight: bold;
        display: flex;
        border-radius: 2px;
        color: $color-mine-shaft-gray;
        margin-block-end: 24px;
        width: 100%;
        text-align: left;

        @include media-breakpoint-up(lg) {
            margin-block-end: 32px;
        }
    }

    &__body {
        max-height: 0;
        overflow: hidden;
        transition:
            max-height 0.3s ease,
            visibility 0s 0.3s;
        visibility: hidden;
    }

    &__icon {
        flex: 0 0 15px;
        align-self: center;
        margin-left: auto;
        height: 15px;
        width: auto;
        pointer-events: none;
        transform: rotate(90deg);
        transition: transform $animation-speed;
    }

    &__button {
        font-size: 18px;
        flex: 1 0 auto;
        text-align: left;

        // Rotate icon if accordion row is open.
        &.collapsed + .accordion__icon {
            transform: rotate(270deg);
        }

        // Stretched button link, to make the entire accordion header clickable.
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: 1;
            pointer-events: auto;
            background-color: rgb(0 0 0 / 0%);
        }

        @include media-breakpoint-up(xl) {
            font-size: 21px;
        }
    }

    &--secondary {
        .accordion__header {
            background-color: transparent;
            padding: 10px;
            margin: 0 -10px 8px;
            transition: background-color 0.3s ease;
            width: calc(100% + 20px);
            font-size: 16px;
        }

        button.accordion__header {
            cursor: pointer;

            &:hover {
                background-color: hsl(0deg 0% 0% / 10%);
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($secondary, 0.25);
            }
        }
    }
}
