.motorcycle-colors-block {
    margin: 40px 0;

    &__title {
        text-align: center;
        margin-bottom: 1.5em;
    }

    &__image {
        display: block;
        width: 655px;
        margin: 0 auto;
    }

    &__colors {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0;
        justify-content: center;
    }

    &__color {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 8px 8px 0;
        box-shadow: inset 0 0 2px rgb(0 0 0 / 30%);
        margin-right: 16px;

        &.active::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: url("#{$static-path}/svg/checkmark-white.svg") no-repeat center center;
            background-size: 16px auto;
        }

        &.active::after {
            content: "";
            position: absolute;
            left: -4px;
            top: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border-radius: 50%;
            border: 1px solid $color-stone-gray;
        }
    }

    &__current-color {
        text-align: center;
    }

    &__disclaimer {
        @include text-small;

        color: $color-stone-gray;
        margin: 40px auto 0;
        text-align: center;
        max-width: 500px;
    }

    @include media-breakpoint-up(md) {
        margin: 80px 0;
    }

    @include media-breakpoint-up(xxl) {
        margin: 110px 0;

        &__color {
            width: 48px;
            height: 48px;
        }

        &__image {
            width: 710px;
        }
    }
}
