.back-block {
    display: flex;
    align-items: center;
    background-color: $color-gallery-gray;
    padding: 0.75rem 1rem;
    margin-top: 1.5rem;

    &__title,
    &__model {
        line-height: 24px;
    }

    &__title {
        position: relative;
        padding-right: 1rem;

        &::after {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 1px;
            height: 100%;
            background-color: $border-color;
        }
    }

    &__model {
        padding-left: 1rem;
    }

    &__button {
        color: $color-ribbon-red;
    }

    @include media-breakpoint-up(lg) {
        padding: 0.75rem 1.5rem;
        margin-top: 2.5rem;

        &__title,
        &__model {
            line-height: 32px;
        }
    }
}
