// -- Folder structure: https://sass-guidelin.es/#architecture

// -- Abstracts (e.g. variables, mixins, placeholders, functions)
@import "abstracts/variables";
@import "abstracts/bootstrap";
@import "abstracts/reset";

// -- Vendors
@import "vendors/bootstrap";
@import "vendors/react-tabs";
@import "vendors/react-tooltip";
@import "vendors/tweakwise";
@import "~@adyen/adyen-web/dist/adyen.css";
@import "~@glidejs/glide/src/assets/sass/glide.core";
@import "~flatpickr/dist/flatpickr.min";
@import "~slick-carousel/slick/slick";

// -- Base
@import "base/bootstrap-overrides";
@import "base/fonts";
@import "base/icons.generated";
@import "base/typography";
@import "base/body";
@import "base/animations";
@import "base/modifiers";

// -- Components
@import "components/all";

// -- Pages
@import "pages/configurator";
@import "pages/dealer-service";
@import "pages/error";
@import "pages/empty";
@import "pages/splash";
@import "pages/articles-overview";
@import "pages/dealer-page";
