.page-error {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &--500 {
        .page-subfooter {
            border-top: none;

            &__bottom {
                border-top: none;
                margin-top: 0;
            }
        }
    }

    .page-content {
        flex: 1 0 auto;
        padding-top: 100px;
        padding-bottom: 100px;

        h1 {
            font-size: 100px;
            line-height: 1;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 24px;
            line-height: normal;
            margin-bottom: 32px;
        }

        p {
            font-weight: $font-weight-medium;
            color: $color-mine-shaft-gray;
            margin-bottom: 32px;
        }
    }

    @include media-breakpoint-up(md) {
        .page-content {
            padding-top: 100px;
            padding-bottom: 100px;

            h1 {
                font-size: 120px;
                margin-bottom: 24px;
            }

            h2 {
                font-size: 28px;
                margin-bottom: 40px;
            }

            p {
                margin-bottom: 40px;
            }
        }

        &--500 {
            .page-subfooter {
                justify-content: flex-end;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .page-content {
            padding-top: 120px;
            padding-bottom: 120px;

            h1 {
                font-size: 160px;
                margin-bottom: 40px;
            }

            h2 {
                font-size: 32px;
                margin-bottom: 40px;
            }

            p {
                margin-bottom: 80px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .page-content {
            padding-top: 160px;
            padding-bottom: 160px;

            h1 {
                font-size: 200px;
                margin-bottom: 60px;
            }
        }

        &--500 {
            .page-subfooter__bottom {
                width: 100%;
                padding-left: 0;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .page-content {
            padding-top: 160px;
            padding-bottom: 160px;
        }
    }
}
