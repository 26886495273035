.Money {
    $self: &;

    display: inline-flex;
    font-weight: $font-weight-heavy;
    font-size: 16px;
    line-height: 1.1;

    &-currency,
    &-literal {
        display: none;
    }

    &[data-value*=".00"] {
        > #{$self}-decimal::after {
            content: "-";
        }

        > #{$self}-fraction {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        font-size: 21px;
    }

    .product-order-block &,
    .accessory-order-block & {
        @include text-heading-2;

        margin: 0;
    }

    &-free {
        text-transform: uppercase;
        color: $color-limeade-green;
    }
}

.Price {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    justify-content: flex-end;
    gap: 0.5rem;

    .product-order-block &,
    .accessory-order-block & {
        gap: 0.5rem;

        @include media-breakpoint-up(md) {
            gap: 1rem;
        }

        @include media-breakpoint-up(xl) {
            gap: 1.5rem;
        }
    }

    &-compare {
        text-decoration: line-through;
        color: $color-stone-gray;
        font-size: 13px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }

        .product-order-block &,
        .accessory-order-block & {
            @include text-heading-3;

            margin-bottom: 0;
            color: $color-stone-gray;
        }
    }
}

[widget="Price"] {
    min-block-size: 18px;

    .product-detail & {
        min-block-size: 26px;
    }
}
