.primary-nav {
    &__expand {
        display: none;
    }

    &__list-item {
        font-weight: $font-weight-medium;
        text-transform: uppercase;
    }

    &__list-button * {
        pointer-events: none;
    }

    &__content {
        display: none;

        &-item {
            padding: 24px $grid-gutter-width-half;

            .primary-nav__content-animate-1,
            .primary-nav__content-animate-2 {
                transition:
                    opacity $animation-speed-lg,
                    transform $animation-speed-lg;
                transition-delay:
                    #{$animation-speed-lg * 0.8},
                    #{$animation-speed-lg * 0.8};
                opacity: 1;
                transform: translateY(0);
            }

            .primary-nav__content-animate-2 {
                transition-delay:
                    #{$animation-speed-lg * 1.3},
                    #{$animation-speed-lg * 1.3};
            }
        }

        &-item:not(.active) {
            padding: 0 !important;
            height: 0;
            overflow: hidden;

            .primary-nav__content-animate-1,
            .primary-nav__content-animate-2 {
                opacity: 0;
                transform: translateY(-20px);
            }
        }

        .primary-nav-content-active & {
            display: block;
        }

        &-back-button {
            @include text-heading-5;

            display: flex;
            align-items: center;
            margin-bottom: 43px;

            .icon {
                transform: scaleX(-1);
                margin-right: 12px;
                width: 16px;
                height: 16px;
                color: $color-ribbon-red;
            }
        }
    }

    .quick-nav {
        display: none;
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        position: fixed;
        background: white;
        left: 0;
        top: $header-height-md;
        width: 100%;
        box-shadow: inset 0 10px 15px 0 rgba($color-black, 0.05);
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        height: 0;
        transition: height $animation-speed-lg;

        &__content-item {
            position: absolute;
            inset: 0;
            max-height: calc(100vh - #{$header-height-md});
            -webkit-overflow-scrolling: touch;
            overflow-y: scroll;
        }

        .quick-nav {
            display: block;
            flex: 1 1 100%;
            padding: 0.6875rem 1rem 1.5rem;

            &__list {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
            }

            &__link {
                line-height: 40px;
            }
        }

        .primary-nav-active & {
            height: calc(100vh - #{$header-height-md});
        }

        // If an item is active, hide the menu
        .primary-nav-content-active &__list,
        .primary-nav-content-active & .quick-nav {
            display: none;
        }

        &__list-button {
            padding: 28px 33px;
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;

            > span {
                position: relative;
            }

            .icon {
                margin-left: auto;
                width: 22px;
                height: auto;
            }

            .icon path {
                fill: $color-ribbon-red;
            }
        }

        &__list-item {
            border-bottom: 1px solid $color-alto-gray;
        }
    }

    @include media-breakpoint-down(sm) {
        top: $header-height-sm;

        &__content-inner {
            padding-bottom: 64px;
        }

        &__list-button {
            font-size: 14px;
            padding: 21px 17px;
        }

        &__content-item {
            max-height: calc(100vh - #{$header-height-sm});
        }

        .primary-nav-active & {
            height: calc(100vh - #{$header-height-sm});
        }
    }

    @include media-breakpoint-up(md) {
        &__content-item {
            padding: 4%;
        }
    }

    // One extra breakpoint in the primary nav
    @media (min-width: $tablet-landscape) {
        .primary-nav__content-wrap {
            display: flex;
        }
    }

    @include media-breakpoint-up(lg) {
        height: 100%;
        margin-left: 31px;

        &__list {
            display: flex;
            height: 100%;
        }

        &__list-item {
            position: relative;
            transition: color $animation-speed-lg;

            &:not(:last-child) {
                margin-right: 24px;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                background: $color-ribbon-red;
                opacity: 0;
                transition: opacity $animation-speed-lg;
            }
        }

        &__list-item.active {
            color: $color-ribbon-red;

            &::after {
                opacity: 1;
            }
        }

        &__list-button {
            height: 100%;
            font-size: 14px;

            .icon {
                display: none;
            }
        }

        &__content {
            background: $color-white;
            position: absolute;
            left: 0;
            width: 100vw;
            margin-top: 1px;
            box-shadow: 0 10px 30px 0 rgba($color-black, 0.1);
            max-height: calc(100vh - #{$header-height-md});
            overflow-y: scroll;
            transition: height $animation-speed-lg;
        }

        &__content-back-button {
            display: none;
        }
    }

    @include media-breakpoint-up(xl) {
        &__list-button {
            font-size: 16px;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__list-button {
            font-size: 18px;
        }

        &__list-item:not(:last-child) {
            margin-right: 40px;
        }
    }
}

// When primary nav content is active, add an overlay on the page
// This way, the user can click outside the menu, without accidentally clicking some link
body.primary-nav-content-active::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}
