.occasion-description-block {
    margin-top: 40px;
    margin-bottom: 40px;

    &__description {
        overflow: hidden;
        position: relative;
        margin-bottom: 16px;

        &::after {
            content: "";
            display: block;
            position: absolute;
            inset: 0;
            background-image: linear-gradient(180deg, rgba($color-white, 0) 0%, $color-white 90%);
            opacity: 0;
            transform: translateY(100%);
            transition: all $animation-speed;
        }
    }

    &__description.collapse:not(.show),
    &__description.collapsing {
        position: relative;
        display: block;
        height: 210px;
        overflow: hidden;
        margin-bottom: 0;

        &::after {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    &__toggle-button {
        display: flex;
        color: $color-mine-shaft-gray;
        margin: 0 auto;
        width: 100%;

        &,
        &:hover {
            background: $color-athens-gray;
        }

        .close {
            display: none;
        }

        &::before {
            content: "";
            position: relative;
            height: 16px;
            width: 16px;
            background-image: url("#{$static-path}/svg/plus-blue.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            margin-right: 10px;
        }

        &[aria-expanded="true"] {
            .close {
                display: block;
            }

            .open {
                display: none;
            }

            &::before {
                background-image: url("#{$static-path}/svg/dash.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 16px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: 60px;
        margin-bottom: 60px;

        &__toggle-button {
            width: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        &__description.collapse:not(.show),
        &__description.collapsing {
            height: 160px;
        }
    }

    @include media-breakpoint-up(xl) {
        margin-top: 80px;
        margin-bottom: 100px;
    }
}
