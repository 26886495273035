.product-facets-modal {
    --modal-padding: 1rem;

    .modal-dialog {
        margin: 0 0 0 auto;
        min-height: 100%;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        padding: var(--modal-padding);
    }

    .modal-body {
        padding: 0;
    }

    .modal-title {
        @include text-heading-3;
    }

    .modal-content {
        min-height: 100vh;
    }

    .modal-header {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }

    .modal-footer {
        position: sticky;
        bottom: 0;
        border-top: 1px solid $border-color;
        border-radius: 0;
        background-color: $white;

        .button {
            margin: 0;
            width: 100%;
        }
    }

    .modal__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        padding: 0;
        border-radius: 0;

        .icon {
            display: block;
            height: 1rem;
            width: 1rem;
        }
    }

    .product-overview {
        margin-bottom: 0;
    }

    .product-facets {
        &__title,
        &__block {
            padding-left: var(--modal-padding);
            padding-right: var(--modal-padding);
        }

        &__block:last-of-type {
            border-bottom: 0;
        }

        &__title {
            margin-bottom: 0;
            border-top: 1px solid $border-color;

            h5 {
                display: none;
            }
        }

        &__reset {
            padding-top: 1.125rem;
            padding-bottom: 1.125rem;
        }
    }
}
