// Date picker wrapper
.date-picker-wrapper {
    border: 1px solid $color-alto-gray;
    padding: 20px;

    .flatpickr-calendar {
        box-shadow: none;
        top: 0;
        margin: 0 auto;
    }
}

.date-picker-message {
    background-color: $color-gallery-gray;
    background-image: url("#{$static-path}/svg/tooltip.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem 1.25rem;
    background-position: 1rem 50%;
    padding: 1rem 1rem 1rem 3rem;

    strong,
    a {
        font-weight: $font-weight-heavy;
    }

    a {
        text-decoration: underline;

        &:hover {
            color: $color-ribbon-red;
        }
    }

    @include media-breakpoint-up(md) {
        background-size: 1.5rem 1.5rem;
        background-position: 1.5rem 50%;
        padding: 1rem 1.5rem 1rem 4rem;
    }
}

// Day
.flatpickr-day {
    display: flex;
    align-items: center;
    aspect-ratio: 1/1;
    line-height: unset;
    height: unset;
    width: unset;
    max-width: unset;
    background: $color-porcelain-gray;
    border-color: $color-alto-gray;
    color: $color-trout-gray;

    &.flatpickr-disabled {
        color: $color-alto-gray;
    }

    &.prevMonthDay,
    &.nextMonthDay {
        color: $color-trout-gray;
        background: transparent;

        &.selected {
            background: $color-mine-shaft-gray;
            border-color: $color-mine-shaft-gray;
            color: $color-white;
        }

        &.flatpickr-disabled {
            color: $color-alto-gray;
        }
    }

    &.selected {
        background: $color-mine-shaft-gray;
        border-color: $color-mine-shaft-gray;
        color: $color-white;

        &:hover {
            background: $color-mine-shaft-gray;
            border-color: $color-mine-shaft-gray;
        }
    }
}

span.flatpickr-weekday {
    font-size: 14px;
    line-height: 1;
    font-weight: $font-weight-heavy;
    color: $font-color-body;
    text-transform: uppercase;
}

// Prev/next
.flatpickr-months {
    margin-bottom: 20px;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    &:hover svg {
        fill: $color-mine-shaft-gray;
    }
}

// Month input
.flatpickr-current-month .flatpickr-monthDropdown-months {
    @include text-heading;

    font-size: 16px;
    appearance: none;
    margin-bottom: 0;
    width: 105px;
    margin-top: 8px;
}

option {
    text-transform: none;
}

// Year input
.flatpickr-current-month input.cur-year {
    @include text-heading;

    font-size: 16px;
    margin-bottom: 0;

    &[disabled] {
        color: $color-mine-shaft-gray;
    }
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: $color-mine-shaft-gray;
    border-color: $color-mine-shaft-gray;
    color: $color-white;
}

// Responsiveness
.flatpickr-calendar.inline,
.flatpickr-rContainer,
.flatpickr-days {
    width: 100%;
}

.flatpickr-weekdays {
    margin-bottom: 1rem;
}

.dayContainer {
    display: grid;
    grid-template-columns: repeat(7, 36px);
    row-gap: 0.625rem;
    width: 100%;
    min-width: unset;
    max-width: unset;

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(7, 40px);
    }

    @include media-breakpoint-up(md) {
        font-size: 16px;
        grid-template-columns: repeat(7, 48px);
    }
}
