.input-radio {
    position: relative;

    &__input {
        position: absolute;
        clip: rect(0 0 0 0);
    }

    &__label {
        padding-left: 29px;
        color: $color-mine-shaft-gray;
        font-weight: $font-weight-medium;

        &::before,
        &::after {
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $color-gallery-gray;
            transform: translateY(-50%);
        }

        &::before {
            content: "";
            border: 1px solid $form-border-color;
        }
    }

    &__input:checked + &__label {
        &::after {
            content: "";
            background: $color-mine-shaft-gray;
            width: 14px;
            height: 14px;
            left: 3px;
        }
    }

    .form-group.is-invalid &__label::before {
        border: 1px solid $color-ribbon-red;
        background: rgba($color-ribbon-red, 0.05);
    }

    @include media-breakpoint-up(xl) {
        &__label {
            font-size: 16px;
            padding-left: 36px;

            &::before,
            &::after {
                width: 28px;
                height: 28px;
            }
        }

        &__input:checked + &__label {
            &::after {
                width: 20px;
                height: 20px;
                left: 4px;
            }
        }
    }
}
