$dimension: 32px;
$connector-height: 4px;

.stepper {
    display: flex;
    font-family: $font-family-sans-serif;
    isolation: isolate;

    &__step {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--completed {
            cursor: pointer;
        }

        &--current {
            .stepper__label {
                color: $color-ribbon-red;
            }

            .stepper__circle::after {
                border-bottom-color: $color-alto-gray;
            }
        }

        &--todo {
            .stepper__circle {
                background-color: $color-alto-gray;
                color: $color-mine-shaft-gray;

                &::after {
                    border-bottom-color: $color-alto-gray;
                }
            }
        }

        &:last-child {
            .stepper__circle::after {
                content: none;
            }
        }
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-family: $font-family-body;
        font-weight: $font-weight-heavy;
        background-color: $color-ribbon-red;
        color: $color-white;
        width: $dimension;
        height: $dimension;
        line-height: $dimension;
        text-align: center;
        font-size: 12px;

        &::after {
            content: "";
            width: calc(100% - #{$dimension});
            border-bottom: $connector-height solid $color-ribbon-red;
            position: absolute;
            left: calc(50% + #{$dimension / 2});
            top: ($dimension - $connector-height) / 2;
        }
    }

    &__label {
        display: none;
        font-size: 12px;
        color: $color-mine-shaft-gray;
        margin-top: 12px;
        font-family: $font-family-body;
        font-weight: $font-weight-heavy;
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    @include media-breakpoint-down(md) {
        position: relative;
        justify-content: space-between;

        &__step {
            position: initial;
            flex: 0 0 auto;
        }

        &__circle::after {
            width: calc(100% / (var(--step-count, 4) - 1));
            left: var(--step-line-pos);
            z-index: -2;
        }
    }
}
