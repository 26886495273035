.accessory-detail-block {
    // TODO: remove this template after e-commerce optimalisations
    margin-top: 48px;
    margin-bottom: 48px;

    &__title {
        font-size: 16px;
        line-height: 19px;
        font-weight: $font-weight-medium;
        text-transform: none;
        margin-bottom: 6px;
    }

    &__description {
        margin-bottom: 24px;
    }

    &__image {
        width: 100%;
    }

    &__specification {
        border-top: 1px solid $color-alto-gray;
        display: flex;
        padding: 8px 0;

        &:last-child {
            border-bottom: 1px solid $color-alto-gray;
        }

        &-title {
            font-weight: $font-weight-medium;
            position: relative;
            display: flex;
            align-items: center;
            flex: 0 0 auto;
            margin-bottom: 0;
            margin-right: 20px;
        }

        &-text {
            flex: 0 1 auto;
            margin-left: auto;
            text-align: right;
        }
    }

    @include media-breakpoint-only(md) {
        &__specification {
            display: block;

            &-title {
                margin-bottom: 8px;
            }

            &-text {
                margin-left: 0;
                text-align: left;
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: 64px;
        margin-bottom: 64px;

        &__specification {
            padding: 16px 0;
        }
    }

    @include media-breakpoint-up(xl) {
        margin-top: 120px;
        margin-bottom: 120px;

        &__specification-variant {
            &.input-select::before {
                right: 48px;
            }

            .input-select__select {
                padding: 14px 72px 14px 24px;
                background-position: right 18px center;
            }
        }

        &__title {
            font-size: 21px;
            line-height: 25px;
            margin-bottom: 10px;
        }

        &__description {
            margin-bottom: 20px;
        }

        &__specification {
            padding: 14px 0;
        }
    }
}
