.feature-slider {
    --slide-width: 92%;

    &__slide {
        flex: 0 0 var(--slide-width);
        max-width: var(--slide-width);
    }

    .embla__container {
        gap: 1.25rem;
    }

    .embla__nav {
        display: none;
        position: relative;
        justify-content: center;
        transform: none;
        margin-top: 1rem;
        column-gap: 1rem;
    }

    .embla__prev,
    .embla__next {
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        --slide-width: 83%;

        .embla__container {
            gap: 1.875rem;
        }

        .embla__nav {
            display: flex;
            margin-top: 2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        --slide-width: 40%;

        .embla__container {
            gap: 2.5rem;
        }

        .embla__nav {
            margin-top: 3.75rem;
        }
    }
}

.feature-item {
    --padding: 1.5rem;

    display: grid;
    grid-template-rows: max-content 1fr;
    gap: var(--padding);
    padding: var(--padding);
    block-size: 100%;
    border: 1px solid $color-alto-gray;
    background-color: $white;

    &__image {
        aspect-ratio: 2 / 1;

        > img {
            aspect-ratio: 2 / 1;
            block-size: 100%;
            inline-size: 100%;
            object-fit: contain;
        }
    }

    &__title {
        font-weight: $font-weight-medium;
        text-transform: none;
        margin-bottom: 10px;
        text-wrap: balance;
    }

    &__text {
        text-wrap: pretty;
    }

    @include media-breakpoint-up(md) {
        --padding: 2.5rem;
    }
}
