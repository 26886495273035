@use "sass:color";
@import "../../abstracts/fluid-sizes";

.trade-in-form {
    $self: &;
    $color-yellow-background: #f6c900;
    $color-blue-background: #00368f;
    $color-gray-background: $color-porcelain-gray;
    $color-gray-border: $color-alto-gray;

    position: relative;
    background-color: $color-white;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        row-gap: 1rem;
        column-gap: #{fluid(16px, 32px)};
        align-items: flex-end;
        border: 1px solid $color-geyser-gray;
        width: 100%;
        padding: 24px 24px 32px;

        #{$self}--modal & {
            row-gap: #{fluid(16px, 40px)};
            padding: 0 0 #{fluid(24px, 40px)};
            border-width: 0 0 1px;
        }
    }

    &__errors {
        border: 1px solid $color-geyser-gray;
        border-top: none;
        padding: 24px;
    }

    &__item {
        flex: 1;

        &--action {
            min-inline-size: fit-content;
        }

        @include media-breakpoint-down(sm) {
            min-inline-size: 100%;

            &--action > .button {
                inline-size: 100%;
            }
        }

        @include media-breakpoint-up(lg) {
            &--action > .button {
                max-block-size: 3rem;
            }
        }

        #{$self}--modal & {
            inline-size: auto;
            min-inline-size: fit-content;
        }
    }

    &__next-button {
        justify-self: flex-end;

        @include media-breakpoint-down(sm) {
            inline-size: 100%;
        }
    }

    &__version {
        flex-grow: 1;
        inline-size: 100%;
    }

    &__group {
        display: flex;
        width: 100%;

        &:focus-within {
            box-shadow: 0 0 0 0.2rem rgb(50 50 50 / 25%);
        }

        &--license-plate {
            .trade-in-form__addon {
                background-color: $color-blue-background;
                color: $color-white;
            }

            .input-text {
                font-size: 20px;
                line-height: 24px;
                font-weight: $font-weight-heavy;
                background-color: $color-yellow-background;
                border-color: transparent;
                text-align: center;
                margin-left: -1px;

                &:hover {
                    background-color: color.scale($color-yellow-background, $lightness: 5%);
                }

                .car-trade-in-form & {
                    text-align: left;
                }
            }
        }

        &--mileage {
            .trade-in-form__addon {
                background-color: $color-gallery-gray;
                color: $color-mine-shaft-gray;
            }

            .trade-in-form__input {
                font-size: 1rem;
                font-weight: $font-weight-medium;
                letter-spacing: 0.57px;
                background-color: $color-gray-background;
                border: 1px solid $form-border-color;
                text-align: left;
                padding: 6px 16px;
                margin-right: -1px;

                &:hover {
                    background-color: color.scale($color-gray-background, $lightness: 5%);
                }
            }
        }
    }

    &__addon {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5rem;
        width: 2.5rem;
        font-size: 0.9375rem;
        font-weight: $font-weight-medium;
        line-height: 1;
        text-align: center;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
            height: 3rem;
            width: 3rem;
        }

        #{$self}--modal & {
            @include media-breakpoint-up(xl) {
                block-size: 4rem;
                inline-size: 4rem;
            }
        }
    }

    .input-text {
        &:focus {
            outline: 0;
            box-shadow: none;
        }

        @include media-breakpoint-up(md) {
            block-size: 3rem;
            padding: 12px 24px;
        }

        @include media-breakpoint-up(xl) {
            #{$self}--modal & {
                block-size: 4rem;
            }
        }
    }

    .input-select {
        > select:focus {
            outline: 0;
        }

        &:focus-within {
            box-shadow: 0 0 0 0.2rem rgb(50 50 50 / 25%);
        }
    }

    &--modal {
        display: grid;
        row-gap: #{fluid(24px, 40px)};
        margin-bottom: 0;

        .input-text {
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            .input-text {
                block-size: 4rem;
            }
        }
    }
}

.next-column-grid-block + .trade-in-block,
.column-grid-block + .trade-in-block {
    // We need a negative margin-top for the previous column grid block.
    // .column-grid-block margins:
    // Mobile: 40px
    // Tablet: 80px
    // Desktop XL: 120px
    margin-top: -24px; // This will be 16px

    @include media-breakpoint-up(md) {
        margin-top: -38px; // This will be 42px
    }

    @include media-breakpoint-up(xxl) {
        margin-top: -78px; // This will be 42px
    }

    // We also need to change the margin-top of the next block.
    & + .column-grid-block,
    & + .next-column-grid-block + .column-grid-block {
        margin-top: 24px;

        @include media-breakpoint-up(md) {
            margin-top: 52px;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: 52px;
        }
    }
}
