// Use this file to override all bootstrap default variables, all variables
// which can be overridden here are listed in this file:
// https://raw.githubusercontent.com/twbs/bootstrap/v4-dev/scss/_variables.scss

$grid-breakpoints: (
    xs: 0 /* mobile */,
    sm: 576px /* mobile landscape */,
    md: 768px /* tablet*/,
    lg: 1200px /* desktop*/,
    xl: 1440px /* desktop xl*/,
    xxl: 1600px /* desktop xxl*/,
) !default;

// To all values 40px have been added, to account for grid gutter.
$container-max-widths: (
    // xs: 548px,
    sm: 580px,
    md: 744px,
    lg: 1178px,
    xl: 1180px,
    xxl: 1440px
) !default;
$grid-columns: 12 !default;
$grid-gutter-width: 40px !default;
$grid-gutter-width-half: $grid-gutter-width / 2;
$grid-row-columns: 6 !default;
$primary: $color-ribbon-red;
$secondary: $color-mine-shaft-gray;
$border-color: $color-alto-gray;
$card-border-color: $border-color;
$border-radius: 0;

// Z-index
$zindex-modal-backdrop: 2010;
$zindex-modal: 2020;
$zindex-tooltip: 2030;

// Modal
$modal-backdrop-opacity: 0.3;
