.pagination {
    display: flex;
    justify-content: center;

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__item {
        margin-right: 24px;

        &:last-of-type {
            margin-right: 0;
        }

        &--disabled {
            opacity: 0.2;

            .pagination__button {
                cursor: default;

                &:hover,
                &:focus {
                    background-color: $color-mine-shaft-gray;
                }
            }
        }
    }

    &__button {
        flex: 0 0 auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background-color: $color-mine-shaft-gray;
        border-radius: 50%;
        transition: background-color 0.3s ease;

        svg {
            width: 13px;
            height: auto;
            fill: $color-white;
        }

        &--right svg {
            transform: rotate(180deg);
        }

        &:hover,
        &:focus {
            background-color: $color-dove-gray;
        }
    }

    &__text {
        flex: 0 0 auto;

        @include text-heading;

        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
    }
}
