@use "sass:map";

.comparison-bar {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        padding: 15px 42px 15px 15px;
        border-left: 1px solid rgba($color-alto-gray, 20%);

        &:first-of-type {
            border-left: none;
        }

        &:last-of-type {
            @include media-breakpoint-up(md) {
                border-right: 1px solid rgba($color-alto-gray, 20%);
            }
        }

        &--placeholder,
        &--placeholder:last-of-type {
            border-right: none;
        }

        &-title {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
        }

        h5 {
            font-weight: $font-weight-medium;
            font-size: 10px;
            line-height: 14px;
            color: $color-white;
            margin-bottom: 0;
        }

        p {
            font-size: 12px;
            line-height: 19px;
            color: rgba($color-white, 90%);
        }
    }

    &__remove {
        flex: 0 0 auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: none;
        line-height: 1;
        text-decoration: none;
        cursor: pointer;
        margin-left: 10px;

        svg {
            display: inline-block;
            height: 12px;
            width: 12px;
            fill: $color-white;
        }
    }

    &__select {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background-image: url("#{$static-path}/svg/arrow-caret-fat-down-white.svg");
        background-repeat: no-repeat;
        background-position: right 15px center;
        background-size: 12px 9px;

        select {
            opacity: 0;
            width: 100%;
            height: 100%;
            appearance: none;
            border: none;
            color: transparent;
            background-color: transparent;

            &::-ms-expand {
                display: none;
            }

            option {
                color: $color-mine-shaft-gray;

                &[disabled] {
                    color: $color-trout-gray;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__item {
            h5 {
                font-size: 12px;
                line-height: 14px;
            }

            p {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__item {
            height: 104px;
            padding: 30px 50px 30px 15px;

            &:first-of-type {
                border-left: 1px solid rgba($color-alto-gray, 20%);
            }

            h5 {
                font-size: 14px;
                line-height: 18px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &__select {
            background-size: 20px 15px;
        }

        &__remove svg {
            height: 20px;
            width: 20px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__item {
            &-title {
                align-items: center;
                height: 100%;
            }

            h5 {
                font-size: 18px;
                line-height: 22px;
            }

            p {
                font-size: 18px;
                line-height: 27px;
            }

            svg {
                height: 20px;
                width: 20px;
            }
        }

        &__remove {
            margin-left: 40px;
            align-self: flex-start;
        }
    }
}

.bottom-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 104px;
    transition: height 0.2s ease;

    &--expanded {
        height: 100%;

        .bottom-overlay-content {
            background-color: white;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            height: calc(100% - 87px);

            @include media-breakpoint-up(xl) {
                height: calc(100% - 104px);
            }
        }
    }

    &-bar {
        background-color: $color-mine-shaft-gray;
        position: relative;

        &__actions {
            height: 48px;
            padding: 8px;
            z-index: 2;
            border-bottom: 1px solid rgba($color-alto-gray, 20%);

            .button {
                background-color: $color-gallery-gray;
                border-color: $color-gallery-gray;
                flex: 0 0 auto;
                padding: 4px 24px;
            }
        }

        @include media-breakpoint-up(lg) {
            &__actions {
                border-bottom: none;
                position: absolute;
                display: flex;
                height: 100%;
                padding-left: 32px;
                align-items: center;
                max-width: calc((100vw - #{map.get($container-max-widths, lg)}) * 0.5);

                .button {
                    padding: 12px 28px;
                }
            }
        }
    }

    &-content {
        height: 0;
        overflow: hidden;
    }
}

.comparison-page {
    padding-top: 24px;
    background-color: $color-athens-gray;

    &__title {
        font-weight: $font-weight-book;
    }

    .react-tabs {
        margin-top: 56px;
    }

    .color .color__circle {
        margin: 6px;
    }

    @include media-breakpoint-down(sm) {
        .grade-image__grade {
            font-size: 24px;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 50px;

        .react-tabs {
            margin-top: 100px;
        }
    }
}

.comparison-item {
    &__title {
        font-weight: $font-weight-medium;
        text-transform: none;
        text-align: center;
        margin-bottom: 6px;
    }

    &__price {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 12px;
    }

    &__remove {
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 1;
        margin-top: 14px;
        color: $color-ribbon-red;

        svg {
            fill: currentcolor;
            width: 15px;
            height: 15px;
        }
    }

    .label {
        align-self: flex-start;
    }

    @include media-breakpoint-up(lg) {
        &__title {
            margin-bottom: 10px;
        }

        &__price {
            margin-bottom: 20px;
        }

        &__remove {
            font-size: 14px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.compare-table {
    border-bottom: none;

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__header,
    &__cell {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        border: 1px solid $color-porcelain-gray;
    }

    &__header {
        @include text-heading;

        margin-bottom: 0;
        font-size: 16px;
        background-color: $color-porcelain-gray;

        @include media-breakpoint-up(lg) {
            background-color: white;
            text-align: left;
            border-bottom: none;
        }
    }

    &__cell {
        word-break: break-word;

        a {
            @include text-link;
        }

        @include media-breakpoint-up(lg) {
            border-bottom: none;
            border-left: none;
        }
    }

    @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $color-porcelain-gray;
    }
}
