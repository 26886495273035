.visual-teaser-item {
    position: relative;
    display: block;

    &:hover {
        .visual-teaser-item__image img {
            transform: scale(1.1);
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px 16px 16px 24px;
        background-color: rgba($color-mine-shaft-gray, 0.6);
    }

    &__image,
    &__image img {
        width: 100%;
        vertical-align: top;
    }

    &__image img {
        height: auto;
        object-fit: cover;
        transition: transform $animation-speed;
    }

    &__title {
        font-weight: $font-weight-medium;
        text-transform: none;
        color: $color-white;
        margin-bottom: 0;
        margin-right: 8px;
    }

    &.is-1 {
        flex: 1;
    }

    &.is-2,
    &.is-3,
    &.is-4 {
        flex: 0 0 100%;
        margin-bottom: 8px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        &__content {
            padding: 20px 20px 20px 28px;
        }

        &.is-2 {
            flex: 1 1 0;
            margin-bottom: 0;

            &:first-child {
                margin-right: 20px;
            }
        }

        &.is-3 {
            flex: 1 1 40%;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &:first-child {
                margin-right: 20px;
            }

            &:last-child {
                flex: 0 0 50%;
            }
        }

        &.is-4 {
            flex: 1 1 40%;

            &:not(:last-child) {
                margin-bottom: 0;
            }

            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: 20px;
            }

            &:nth-child(odd) {
                margin-right: 20px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &.is-3 {
            &,
            &:last-child {
                flex: 1 1 0;
            }

            &:not(:last-child) {
                margin-bottom: 0;
                margin-right: 20px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__content {
            padding: 24px 24px 24px 32px;
        }

        &.is-2 {
            &:first-child {
                margin-right: 40px;
            }
        }

        &.is-3 {
            &:not(:last-child) {
                margin-right: 40px;
            }
        }

        &.is-4 {
            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: 40px;
            }

            &:nth-child(odd) {
                margin-right: 40px;
            }
        }
    }
}
