.slick-slider {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
}

.slick-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    z-index: 1;
    cursor: pointer;
    transform: translate3d(0, -50%, 0);
    background-color: $color-mine-shaft-gray;
    border-radius: 50%;

    > * {
        pointer-events: none;
    }

    svg > * {
        fill: $white;
    }
}

.slick-prev {
    left: 10px;
    transform: translate3d(0, -50%, 0) scaleX(-1);
}

.slick-next {
    right: 10px;
}

.slick-thumb {
    height: 160px;
}

.slick-initialized {
    .slick-slide {
        display: flex;
        height: 100%; // no space needed for the bar on mobile
        align-items: center;
        justify-content: center;
    }
}

.slick-slide {
    > div {
        width: 100%;
        height: 100%;
    }

    > div > div {
        height: 100%;
        display: flex !important;
        align-items: center;
        outline: none;
    }

    img {
        width: auto;
        max-height: 100%;
        margin: 0 auto;
    }
}

.overlay .slick-slide {
    min-height: 100vh;
    height: 100vh;

    @include media-breakpoint-up(sm) {
        min-height: calc(100vh - 106px);
        height: calc(100vh - 106px);
    }
}

.gallery-toolbar {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 5px;
}

.category-group {
    align-items: flex-end;
    justify-content: center;
    display: flex;

    .list {
        margin: 0;
    }
}

.thumbnail-group {
    display: none;

    @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        overflow: hidden;
        width: 100%;
        height: 120px;
        scroll-behavior: smooth;

        &__container {
            display: inline-flex;
            height: 105px;
            white-space: nowrap;
            align-items: flex-end;
            margin-top: 15px;
        }
    }
}

.thumbnail-item {
    display: inline-block;
    height: 90px;
    margin: 0 2.5px;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    will-change: height;
    transition: height 250ms $ease-in-out-cubic;

    &::after {
        content: "";
        pointer-events: none;
        background-color: red;
        opacity: 0.7;
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        z-index: 2;
    }

    img {
        width: auto;
        height: 100%;
        position: relative;
        z-index: 1;
    }

    &.is-active-image {
        height: 100px;
    }

    &.is-active-gallery {
        &::after {
            opacity: 0;
        }
    }

    &.is-first-in-category {
        margin-left: 27.5px;
    }
}
