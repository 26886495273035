.buttons-block {
    .button {
        flex: 0 0 auto;
    }

    @include media-breakpoint-down(sm) {
        .button {
            width: 100%;
        }

        .button:not(:first-child) {
            margin-top: 8px;

            &.button--text {
                margin-top: 17px;
            }
        }

        .button--text {
            justify-content: flex-start;
        }
    }

    @include media-breakpoint-up(md) {
        &__inner {
            display: flex;
            align-items: center;
        }

        .button:not(:last-child) {
            margin-right: 24px;
        }
    }

    @include media-breakpoint-up(xl) {
        .button:not(:last-child) {
            margin-right: 40px;
        }
    }
}
