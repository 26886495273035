.product-order-block {
    $self: &;

    &__prices {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
    }

    &__normal-price {
        @include text-heading-2;

        margin-bottom: 0;
    }

    &__new-price {
        @include text-heading-2;

        margin-bottom: 0;
    }

    &__old-price {
        text-decoration: line-through;
        margin-left: 10px;
    }

    &__price {
        display: flex;
        align-items: center;
        gap: 1rem;

        > #{$self}__discount {
            border: 1px solid $color-ribbon-red;
            color: $color-ribbon-red;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            padding-inline: 0.5rem;
            font-weight: 400;
            max-inline-size: 27ch;

            > a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__submit {
        width: 100%;
        padding-top: 9px;
        padding-bottom: 9px;

        .icon {
            margin-left: 0;
            margin-right: 10px;
            height: 18px;
            width: auto;
            fill: currentcolor;
        }
    }

    &__dealer-btn {
        .icon {
            margin-left: 0;
            margin-right: 10px;
            height: 18px;
            width: auto;
            fill: currentcolor;
        }
    }

    &__available,
    &__unavailable {
        text-align: center;
        margin-bottom: 1.375rem;

        svg {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }

        p {
            font-size: 14px;
            line-height: 21px;
            margin-top: 4px;
        }
    }

    &__payment-logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;

        > img {
            height: 2rem;
            width: auto;
        }
    }

    @include media-breakpoint-up(sm) {
        &__payment-logos {
            gap: 1rem;
        }
    }

    @include media-breakpoint-up(md) {
        &__available,
        &__unavailable {
            text-align: left;
            margin-bottom: 1.875rem;
        }

        &__submit {
            padding-top: 13px;
            padding-bottom: 13px;

            .icon {
                margin-right: 15px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__submit {
            width: auto;
            flex: 0 0 auto;
        }

        &__add-form-group {
            display: flex;
            align-items: flex-end;

            .form-group {
                margin-bottom: 0;
            }

            .input-select__select {
                min-width: 120px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__submit {
            padding-top: 21px;
            padding-bottom: 21px;
        }

        &__available,
        &__unavailable {
            svg {
                width: 24px;
                height: 24px;
                margin-right: 6px;
            }

            p {
                margin-top: 10px;
            }
        }
    }
}
