.form {
    &__title {
        margin-bottom: 10px;
    }

    &__section {
        margin-top: 32px;
        margin-bottom: 32px;
    }

    &__address {
        &.is-valid {
            input[name="street_name"],
            input[name="city"] {
                background-image: var(--checkmark-green);
                background-size: 1rem 1rem;
                background-position: calc(100% - 1rem) 50%;
                background-repeat: no-repeat;
            }
        }
    }

    &--page {
        margin-top: 32px;
        margin-bottom: 32px;

        &.receipt {
            top: 150px;
        }
    }

    @include media-breakpoint-up(md) {
        &__title {
            margin-bottom: 16px;
        }

        &--page {
            margin-top: 48px;
            margin-bottom: 48px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__section {
            margin-top: 48px;
            margin-bottom: 48px;
        }

        &--page {
            margin-top: 64px;
            margin-bottom: 64px;
        }
    }
}
