.article-meta-block {
    margin-top: 20px;

    + .column-grid-block {
        margin-top: 20px;
    }

    @include media-breakpoint-up(md) {
        margin-top: 70px;

        + .column-grid-block {
            margin-top: 40px;
        }
    }
}
