.page-subfooter {
    position: relative;
    color: $footer-color-body;
    background: $color-mine-shaft-gray;
    font-weight: $font-weight-medium;
    font-size: 14px;
    text-transform: uppercase;
    z-index: 2;

    a:hover {
        color: $color-white;
    }

    &__product-groups {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }

    &__product-group {
        padding: 35px 4px 0;
        border-top: 1px solid transparent;
        margin: 0 8px;

        &.active {
            color: $color-white;
            border-top-color: $color-white;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__link {
        padding: 7px 0;
    }

    &__bottom {
        border-top: 1px solid rgba($color-porcelain-gray, 0.1);
        padding-left: $grid-gutter-width-half;
        display: flex;
        justify-content: space-between;
        margin-top: 53px;
    }

    &__copyright {
        display: flex;
        align-items: center;
    }

    &__logo {
        display: flex;
        align-items: center;
        height: 71px;
        margin-right: 18px;

        img,
        svg {
            height: 48px;
            width: auto;
        }
    }

    .comparebar-open & {
        padding-bottom: 104px;
    }

    @include media-breakpoint-up(md) {
        border-top: 1px solid rgba($color-porcelain-gray, 0.1);

        &__bottom {
            padding-left: $grid-gutter-width;
            margin-top: 33px;
        }

        &__product-groups {
            margin-top: -1px;
            margin-bottom: 35px;
        }

        &__links {
            flex-direction: row;
            justify-content: center;
        }

        &__link {
            margin: 0 12px;
        }
    }

    @include media-breakpoint-up(xl) {
        display: flex;
        padding-left: $grid-gutter-width;

        &__product-groups {
            margin-bottom: 0;
        }

        &__product-group {
            padding: 25px 4px;
        }

        &__links {
            margin: 0 auto;
        }

        &__bottom {
            border: 0;
            margin: 0;
        }

        &__logo {
            margin-left: $grid-gutter-width;
            margin-right: 32px;

            > img,
            > svg {
                height: 32px;
            }
        }
    }
}
