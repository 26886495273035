.occasion-specifications-block {
    margin-top: 40px;
    margin-bottom: 40px;
    word-break: break-word;

    &__options {
        margin-bottom: 32px;
    }

    &__cta {
        width: 100%;
    }

    &__options li {
        position: relative;

        @include text-intro;

        margin-bottom: 8px;
        padding-left: 25px;

        svg {
            position: absolute;
            left: 0;
            top: -2px;
            width: 16px;
            height: 30px;
        }
    }

    &__specifications {
        table {
            width: 100%;
        }

        td {
            background: transparent;
            width: 50%;
        }

        td:first-child {
            @include text-heading-5;
        }

        td:last-child {
            text-align: center;
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: 80px;
        margin-bottom: 80px;

        &__options {
            columns: 2;
            margin-bottom: 50px;
        }

        &__cta {
            width: auto;
        }
    }

    @include media-breakpoint-up(xl) {
        &__specifications td:first-child {
            font-size: 18px;
            line-height: 24px;
        }

        &__options li {
            svg {
                top: 2px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 120px;
        margin-bottom: 120px;
    }
}
