.model-teaser-block {
    --peek-width: 60px;
    --slide-width: calc(100vw - (var(--peek-width) * 2));

    &__nav-icon {
        display: none;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $color-mine-shaft-gray;

        .icon {
            width: 14px;
            height: auto;
        }
    }

    .embla__slide {
        flex: 0 0 var(--slide-width);
        max-width: var(--slide-width);
    }

    .embla__nav {
        position: initial;
        top: initial;
        transform: none;
    }

    .embla__next,
    .embla__prev {
        justify-content: flex-start;
        position: absolute;
        top: 50%;
        left: 0;
        height: 100%;
        width: var(--peek-width);
        transform: translateY(-50%);
        margin: 0;
        padding: 10px;
        border-radius: none;
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    .embla__next {
        justify-content: flex-end;
        right: 0;
        left: auto;
    }

    @include media-breakpoint-up(md) {
        --peek-width: 150px;

        &__nav-icon {
            display: flex;
        }
    }

    @media (width >= 1024px) {
        --peek-width: 250px;
    }

    @include media-breakpoint-up(lg) {
        --peek-width: 300px;

        .embla__prev,
        .embla__next {
            padding: 20px;
        }
    }

    @include media-breakpoint-up(xl) {
        --peek-width: 310px;

        &__nav-icon {
            width: 60px;
            height: 60px;

            .icon {
                width: 21px;
            }
        }

        .embla__prev,
        .embla__next {
            padding: 30px;
        }
    }

    @include media-breakpoint-up(xxl) {
        --peek-width: 380px;

        &__nav-icon {
            width: 88px;
            height: 88px;

            .icon {
                width: 30px;
            }
        }

        .embla__prev,
        .embla__next {
            padding: 60px;
        }
    }

    @media (width >= 1920px) {
        --peek-width: 430px;
    }
}
