.image-block {
    picture {
        max-width: 100%;

        img {
            height: auto;
        }
    }

    &__description {
        @include text-small;

        margin: 5px 0 0;
    }

    @include media-breakpoint-up(md) {
        &__description {
            margin-top: 11px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__description {
            margin-top: 16px;
        }
    }
}
