.search-results-block {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;

    &__filters {
        display: none;

        li {
            margin-bottom: 5px;
        }

        a {
            font-weight: $font-weight-medium;
            color: $color-mine-shaft-gray;

            &:not(.active):hover {
                text-decoration: underline;
            }

            &.active {
                color: $color-ribbon-red;
            }

            &.disabled {
                pointer-events: none;
                cursor: default;
                color: $color-dove-gray;
            }
        }
    }

    &__filters-mobile {
        display: block;
        margin-bottom: 30px;
    }

    &__form {
        position: relative;
        margin-bottom: 16px;
    }

    &__amount {
        @include text-small;

        font-size: 14px;
        line-height: 21px;
        margin-bottom: 32px;
    }

    @include media-breakpoint-up(md) {
        &__form {
            margin-bottom: 30px;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-top: 94px;
        margin-bottom: 94px;

        &__filters {
            display: block;
        }

        &__filters-mobile {
            display: none;
        }
    }

    @include media-breakpoint-up(xl) {
        &__filters a {
            font-size: 16px;
            line-height: 24px;
        }

        &__form {
            .page-search-box__input {
                font-size: 20px;
                line-height: 30px;
                padding: 9px 17px;
            }

            .page-search-box__button {
                width: 50px;

                > .icon {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}
