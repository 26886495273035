.overview-block {
    padding-top: 40px;
    padding-bottom: 40px;

    [class^="col-"] {
        margin-bottom: 20px;
    }

    .primary-nav__content & {
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
    }

    &__actions,
    &__title,
    &__price,
    &__image,
    &__image-link {
        position: relative;
        top: 0;
        transition:
            opacity $animation-speed,
            top $animation-speed;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        text-align: center;
        padding-top: 32px;
        height: 100%;
    }

    &__item--with-comparison .overview-block__actions {
        flex-direction: column !important;
        align-items: center !important;
        justify-content: space-between !important;
    }

    &__label {
        position: absolute;
        top: 32px;
        left: 16px;
        transform-origin: bottom;
        line-height: 0;
        text-align: left;
    }

    &__image {
        display: block;
        height: auto;
        max-width: 100%;
        flex: 0 0 auto;
        margin-bottom: 20px;
    }

    .overview-block__image-link {
        display: block;
        width: 100%;
    }

    &__title {
        margin-bottom: 12px;
        max-width: 100%;
    }

    &__actions {
        display: flex;
        align-items: flex-end;
        margin-top: 10px;
    }

    &__action {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }

    .compare-action {
        margin-top: 16px;
    }

    @include media-breakpoint-up(md) {
        &__item--segment &__title {
            margin-top: auto;
        }

        &__item--segment &__image {
            margin-top: auto;
        }

        &__image {
            margin-bottom: 30px;
        }

        &__button {
            padding: 18px 20px;
        }
    }

    @include media-breakpoint-up(lg) {
        [class^="col-"] {
            margin-bottom: 40px;
        }

        &__item {
            border: 1px solid transparent;
            border-radius: 2px;
            transition: all $animation-speed;
            padding-top: 40px;
        }

        &__item:hover {
            background: $color-white;
            border-color: $color-alto-gray;
            box-shadow: 0 30px 40px 0 rgb(0 0 0 / 5%);

            .overview-block__image-link,
            .overview-block__image,
            .overview-block__title,
            .overview-block__price,
            .overview-block__actions {
                top: -32px;
            }

            .overview-block__image-link {
                margin-top: -8px;
                padding-top: 8px;

                & > .overview-block__image {
                    top: 0;
                }
            }

            .overview-block__actions {
                opacity: 1;
            }
        }

        &__actions {
            opacity: 0;
        }

        &__button {
            padding: 18px 25px;
        }
    }

    @include media-breakpoint-up(xl) {
        [class^="col-"] {
            margin-bottom: 70px;
        }

        &__button {
            padding: 17px 30px;
        }

        &__image {
            margin-bottom: 50px;
        }

        &__price p {
            display: inline-block;
        }
    }
}
