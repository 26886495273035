.accessories-overview-card {
    // TODO: remove this template after e-commerce optimalisations
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border: 1px solid $color-alto-gray;
    height: 100%;
    width: 100%;
    position: relative;
    transition: all $animation-speed;

    &:hover {
        border-color: $color-geyser-gray;
        box-shadow: 0 30px 40px 0 rgb(0 0 0 / 5%);
    }

    > * {
        flex: 0 0 auto;
    }

    &__image {
        &,
        img {
            width: 100%;
        }

        .commerce & {
            img {
                aspect-ratio: 4/3;
                object-fit: cover;
                display: grid;
                background-color: $color-alto-gray;
                font-size: 0;

                &::before {
                    content: "";
                    inline-size: 100%;
                    block-size: 100%;
                    display: grid;
                    place-items: center;
                    padding: 1rem;
                    background-image: url($product-fallback-img);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100%;
                }
            }
        }
    }

    &__label {
        position: absolute;
        display: inline-block;
        left: 8px;
        top: 8px;
    }

    &__content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 20px 8px;

        > * {
            flex: 0 0 auto;
        }
    }

    &__title {
        font-weight: $font-weight-book;
        text-transform: none;
        text-align: center;
        margin-bottom: 4px;
    }

    &__available {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 21px;
        text-align: center;

        svg {
            width: 14px;
            height: 14px;
            margin-right: 4px;
        }

        svg .circle {
            fill: $color-limeade-green;
        }
    }

    &__prices {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
        padding-top: 16px;
        text-align: center;
    }

    &__prices-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__normal-price {
        @include text-heading-5;

        margin-bottom: 0;
    }

    &__new-price {
        @include text-heading-5;

        margin-bottom: 0;
        margin-right: 7px;
    }

    &__old-price {
        @include text-small;

        text-decoration: line-through;
    }

    .tooltip-toggler {
        top: 0;
        position: relative;
        margin-left: 7px;

        .icon--tooltip {
            height: 16px;
            width: 16px;
        }
    }

    @include media-breakpoint-up(md) {
        &__old-price {
            margin-left: 8px;
            order: 1;
        }
    }

    @include media-breakpoint-up(xl) {
        &__available {
            svg {
                height: 16px;
                width: 16px;
                margin-right: 8px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        &__content {
            padding: 24px;
        }
    }
}
