.hero-full-block {
    &__visual {
        position: relative;
    }

    &__visual-inner {
        position: relative;
    }

    &--no-buttons {
        .hero-full-block__content {
            background-color: $color-mine-shaft-gray;
        }

        .hero-full-block__title,
        .hero-full-block__content .rich-text p {
            color: $color-white;
        }
    }

    // Option 1: image
    &__image {
        width: 100%;

        &::after {
            content: "";
            display: block;
            width: 100%;
            background: rgba($color-black, 0.1);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    // Option 2: video
    &__video iframe {
        width: 100%;
    }

    // Option 3: text with a background
    &__only-text {
        background: $color-porcelain-gray;
        min-height: 320px;
    }

    &__only-text + &__content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 25px;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__only-text + &__content &__title {
        margin-top: 20px;
        text-shadow: none;
    }

    &__content {
        padding: 24px $grid-gutter-width-half;
    }

    &__content .rich-text {
        margin-bottom: 16px;

        p {
            @include text-intro;
        }

        ul,
        ol {
            display: inline-block;
            text-align: left;
        }
    }

    &__info {
        font-size: 16px;
        line-height: 18px;
        display: inline-block;
        margin-bottom: 14px;

        // See: https://css-tricks.com/multi-line-padded-text/#aa-adam-campbells-box-decoration-break-method
        span {
            text-transform: uppercase;
            background: $color-white;
            color: inherit;
            padding: 3px 8px;
            box-decoration-break: clone;

            &.is-dark {
                color: $font-color-body;
            }
        }

        b {
            font-weight: $font-weight-heavy;
        }
    }

    &__title {
        font-weight: $font-weight-book;
        margin-bottom: 15px;

        b {
            font-weight: $font-weight-heavy;
        }
    }

    .button:not(.hero-full-block__back-button) {
        width: 100%;
        margin-bottom: 8px;
    }

    &__back-button {
        position: absolute;
        top: 16px;
        left: 16px;
        margin: 0;
        z-index: 3;
        color: $color-white;

        [class*="g-circle"] {
            fill: $color-white;
        }

        [class*="g-arrow"] {
            fill: $color-mine-shaft-gray;
        }

        &:hover {
            color: $color-alto-gray;

            [class*="g-circle"] {
                fill: $color-alto-gray;
            }

            [class*="g-arrow"] {
                fill: $color-mine-shaft-gray;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &--no-buttons {
            .hero-full-block__content {
                background-color: rgba($color-mine-shaft-gray, 60%);
            }

            .hero-full-block__title,
            .hero-full-block__content .rich-text p {
                color: rgba($color-white, 0.9);
            }
        }

        &__image-wrap::after {
            height: 120px;
            bottom: -40px;
            background-position: right 21% bottom;
        }

        &__only-text {
            min-height: 400px;
        }

        .button:not(.hero-full-block__back-button) {
            width: auto;

            &:not(:last-child) {
                margin-right: 8px;
            }
        }

        &__actions {
            margin-top: 48px;

            .button--primary {
                background: $color-gallery-gray;
                border-color: $color-gallery-gray;
                color: $color-mine-shaft-gray;

                &:hover {
                    background: $white;
                    border-color: $white;
                }
            }

            .button--secondary {
                background: transparent;
                border-color: $color-gallery-gray;
                color: $color-gallery-gray;

                &:hover {
                    background: transparent;
                    border-color: $color-white;
                    color: $color-white;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 50%;
            padding-left: 40px;
            padding-right: 40px;
            background-color: rgba($color-mine-shaft-gray, 60%);
            z-index: 2;

            .rich-text {
                margin-bottom: 0;
            }

            &.align-left {
                left: 0;
                right: auto;
            }

            &.align-right {
                left: auto;
                right: 0;
            }
        }

        &__image-wrap + &__content,
        &__video-wrap + &__content {
            .rich-text {
                > * {
                    color: rgba($color-white, 0.9);
                }

                a {
                    color: rgba($color-white, 0.9);
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }

                ul li {
                    background-image: url("#{$static-path}/svg/diamond-white.svg");
                }
            }
        }

        &__only-text + &__content &__title {
            max-width: 620px;
            color: $color-mine-shaft-gray;
            margin-top: 20px;
        }

        &__title {
            color: $color-white;
            max-width: 430px;
        }

        &__back-button {
            top: 50px;
            left: 50px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__only-text + &__content &__title {
            margin-top: 30px;
        }

        &__actions {
            margin-top: 64px;
        }

        &__info {
            font-size: 21px;
            line-height: 24px;
            padding: 4px 8px;
            margin-bottom: 24px;
        }

        &__content {
            padding-left: 60px;
            padding-right: 60px;
            width: 33.3333%;
        }
    }

    @include media-breakpoint-up(xl) {
        &__image-wrap::after {
            height: 140px;
        }

        &__actions {
            margin-top: 72px;
        }

        &__title {
            max-width: 80%;
        }

        &__only-text {
            min-height: 480px;
        }

        &__only-text + &__content &__title {
            margin-top: 40px;
            max-width: 900px;
        }

        &__content {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
}
