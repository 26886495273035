.dealer-intro {
    --spacing: 2rem;

    &__blocks {
        display: grid;
        gap: var(--spacing);
    }

    @include media-breakpoint-up(md) {
        --spacing: 2.5rem;
    }

    @include media-breakpoint-up(lg) {
        --spacing: 3rem;
    }

    @include media-breakpoint-up(xl) {
        --spacing: 4rem;
    }
}
