.hero-small-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 256px;
    padding: 65px 0;

    &.has-background {
        background: $color-gallery-gray;
    }

    &__center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        @include text-heading-2;

        display: inline-block;
        max-width: 620px;
        text-align: center;
        margin-bottom: 0;
    }

    .rich-text {
        max-width: 620px;
        margin-top: 24px;
    }

    &__actions {
        margin-top: 32px;
    }

    &__back-button {
        position: absolute;
        top: 16px;
        left: 16px;
    }

    &__info {
        display: inline-block;
        background: $color-ribbon-red;
        color: $color-white;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 18px;
        padding: 3px 8px;
        margin-bottom: 14px;

        b {
            font-weight: $font-weight-heavy;
        }

        &.is-dark {
            color: $font-color-body;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 80px 0;
    }

    @include media-breakpoint-up(lg) {
        padding: 120px 0;

        &__back-button {
            position: absolute;
            top: 50px;
            left: 50px;
        }
    }

    @include media-breakpoint-up(xxl) {
        min-height: 240px;

        &__title,
        .rich-text {
            max-width: 900px;
        }
    }
}
