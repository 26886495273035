.list-links-block {
    margin-bottom: 20px;

    &__label {
        @include text-heading-5;

        margin-bottom: 1.125rem;
        color: rgba($color-mine-shaft-gray, 0.4);
    }

    &__title {
        @include text-heading-5;

        margin-bottom: 20px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-bottom: 20px;
    }

    &__item a {
        @include text-heading-5;

        display: block;
        font-weight: $font-weight-medium;
        padding: 3px 0;

        &:hover {
            color: $color-ribbon-red;
        }
    }

    &__item:last-child a {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 30px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;

        &__list {
            margin-left: 0;
        }

        &__item a {
            font-size: 14px;
            line-height: 16px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__item a {
            font-size: 16px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__label {
            font-size: 18px;
            margin-bottom: 1.25rem;
        }
    }
}
