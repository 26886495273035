.hero-model-block {
    margin-bottom: 16px;

    &__container {
        background-color: $color-gallery-gray;
    }

    &__info {
        @include text-intro;

        margin-bottom: 40px;
    }

    &__title {
        font-size: 48px;
        line-height: 44px;
        font-weight: $font-weight-book;
        margin-bottom: 12px;

        b {
            font-weight: $font-weight-heavy;
        }
    }

    &__visual {
        position: relative;
        padding: 40px 16px 48px;
        text-align: center;
    }

    &__visual-background {
        background: $color-porcelain-gray;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;
    }

    &__image {
        flex: 0 0 auto;
        position: relative;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        img {
            height: auto;
            max-height: 240px;
        }
    }

    &__actions {
        .hero-model-block__action:first-of-type {
            margin-bottom: 8px;
        }

        .button {
            width: 100%;
            white-space: nowrap;
        }

        .button--secondary {
            background: transparent;
            color: $color-white;
        }

        &.is-light {
            .button--primary {
                color: $color-white;
            }
        }

        &.is-dark {
            .button--primary {
                color: $font-color-body;
            }
        }
    }

    &__prices-and-actions {
        background-color: $color-mine-shaft-gray;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    &__prices {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
    }

    &__price {
        flex: 0 0 50%;
        padding: 0 10px;
        text-align: center;

        &-permonth {
            @include text-heading-5;

            color: $color-gallery-gray;
            margin: 0;
        }

        &-amount {
            @include text-heading-3;

            color: $color-gallery-gray;
            margin: 0;
        }

        &-top {
            @include text-heading-5;
            @include text-heading-light;

            color: $color-gallery-gray;
            margin-bottom: 4px;
        }

        .tooltip-toggler {
            top: 3px;
            position: relative;
        }
    }

    @include media-breakpoint-up(md) {
        &__info {
            max-width: 550px;
            margin: 0 auto 48px;
        }

        &__image img {
            max-height: 370px;
        }

        &__price:not(:only-child) {
            display: flex;
            justify-content: flex-end;

            &:first-child {
                padding-right: 28px;
            }

            &:last-child {
                padding-left: 28px;
                justify-content: flex-start;
            }
        }

        &__actions {
            display: flex;
            justify-content: center;

            .button {
                margin-bottom: 0;
            }
        }

        &__action:not(:only-child) {
            flex: 0 0 50%;

            &:first-child {
                padding-right: 4px;
            }

            &:last-child {
                padding-left: 4px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__info {
            max-width: none;
            margin-bottom: 0;
        }

        &__visual {
            display: flex;
            align-items: center;
            min-height: 600px;
            padding: 80px;
            text-align: left;
        }

        &__content {
            flex: 0 1 760px;
            margin-right: 40px;
        }

        &__title {
            font-size: 104px;
            line-height: 96px;
            margin-bottom: 30px;
        }

        &__actions {
            justify-content: center;

            .hero-model-block__action:first-of-type {
                margin-bottom: 0;
            }

            .button {
                width: 100%;
                max-width: 360px;
                min-width: fit-content;
            }
        }

        &__action:not(:only-child) {
            flex: 0 0 50%;

            &:first-child {
                text-align: right;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__image img {
            max-height: 500px;
        }

        &__prices-and-actions {
            display: flex;
            align-items: center;
            padding-top: 64px;
            padding-bottom: 64px;
        }

        &__prices {
            margin-bottom: 0;
            flex: 1 0 auto;
            justify-content: flex-start;
        }

        &__price {
            &:only-child,
            &:not(:only-child) {
                text-align: left;
                justify-content: flex-start;
                flex: 0 0 auto;
            }

            &:first-child {
                padding-left: 0;
            }
        }

        &__actions {
            flex: 0 0 auto;
            justify-content: flex-end;

            .button {
                width: auto;
            }
        }

        &__action:only-child,
        &__action:not(:only-child) {
            flex: 0 0 auto;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__image img {
            max-height: 590px;
        }
    }
}
