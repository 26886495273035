.configurator-page {
    .page-content {
        margin-bottom: 80px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 120px;
        }
    }

    &.configurator-navigation-padding {
        padding-bottom: $configurator-navigation-padding-md;

        @include media-breakpoint-down(sm) {
            padding-bottom: $configurator-navigation-padding-sm;
        }
    }

    .gear-selection {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-block: 24px;

        @include media-breakpoint-up(md) {
            gap: 24px;
            margin-block: 40px;
        }
    }

    .accessory-search {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        &__input {
            position: relative;
        }

        &__btn-submit,
        &__btn-clear {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);

            svg {
                display: block;
                height: 1.25rem;
                width: 1.25rem;
            }
        }

        &__btn-clear {
            display: none;
            right: 3rem;

            svg {
                height: 1rem;
                width: 1rem;
            }

            &.active {
                display: block;
            }
        }

        .form-control {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.5rem;

            .form-control {
                max-width: 290px;
                padding-right: 4.25rem;
            }
        }
    }

    .accessory-grid {
        --gap: 16px;

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        gap: var(--gap);
        margin-block-end: 24px;

        @include media-breakpoint-up(lg) {
            --gap: 24px;

            margin-block-end: 40px;
        }

        > h5 {
            grid-column: 1 / -1;
            margin-block-end: 0;
        }
    }

    .share-buttons {
        .share-buttons__tooltip-toggle {
            @include text-small;

            gap: 8px;
            color: $color-ribbon-red;
        }

        .tooltip {
            left: auto;
            right: 0;
            transform: none;

            .arrow {
                left: auto;
                right: 30px;
            }
        }
    }
}
