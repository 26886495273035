.dealer-filter {
    margin-top: 1.5rem;

    h5 {
        font-size: 12px;
        margin-bottom: 5px;
    }

    &__select-list {
        width: 100%;
    }

    &__radio-list {
        display: none;
    }

    @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;
        border-top: none;

        h5 {
            @include text-small;

            text-transform: initial;
            margin-bottom: 0;
            margin-right: 14px;
        }

        &__select-list {
            display: none;
        }

        &__radio-list {
            display: flex;
            gap: 0.5rem;
        }

        &__radio-item {
            input {
                position: absolute;
                clip: rect(0 0 0 0);
            }

            label {
                border-radius: 16px;
                background: $color-gallery-gray;
                color: $color-mine-shaft-gray;
                font-size: 12px;
                font-weight: $font-weight-heavy;
                text-transform: uppercase;
                padding: 7px 20px;
                display: inline-block;
                cursor: pointer;
            }

            input:checked + label {
                background: $color-mine-shaft-gray;
                color: $color-white;
            }
        }
    }
}
