.accessory-order-block {
    // TODO: remove this template after e-commerce optimalisations
    &__prices {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
    }

    &__normal-price {
        @include text-heading-2;

        margin-bottom: 0;
    }

    &__new-price {
        @include text-heading-2;

        margin-bottom: 0;
    }

    &__old-price {
        text-decoration: line-through;
        margin-left: 10px;
    }

    &__submit {
        width: 100%;
        padding-top: 9px;
        padding-bottom: 9px;

        .icon {
            margin-left: 0;
            margin-right: 10px;
            height: 18px;
            width: auto;
            fill: currentcolor;
        }
    }

    &__available,
    &__unavailable {
        text-align: center;

        svg {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }

        p {
            font-size: 14px;
            line-height: 21px;
            margin-top: 4px;
        }
    }

    &__add-form-group {
        margin-bottom: 20px;
    }

    @include media-breakpoint-up(md) {
        &__available,
        &__unavailable {
            text-align: left;
        }

        &__submit {
            padding-top: 13px;
            padding-bottom: 13px;

            .icon {
                margin-right: 15px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__submit {
            width: auto;
            flex: 0 0 auto;
            margin-left: 16px;
        }

        &__add-form-group {
            display: flex;
            align-items: flex-end;

            .form-group {
                margin-bottom: 0;
            }

            .input-select__select {
                min-width: 120px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__submit {
            padding-top: 21px;
            padding-bottom: 21px;
        }

        &__available,
        &__unavailable {
            svg {
                width: 24px;
                height: 24px;
                margin-right: 6px;
            }

            p {
                margin-top: 10px;
            }
        }
    }
}
