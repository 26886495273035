.product-facets {
    &__reset {
        font-size: 14px;
        line-height: 21px;
        color: $color-ribbon-red;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25rem;
    }

    &__block {
        border-top: 1px solid $border-color;

        &:last-of-type {
            border-bottom: 1px solid $border-color;
        }
    }

    &__block-content {
        margin-bottom: 1.5rem;

        .form-group {
            margin-bottom: 0.75rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .collapse .form-group,
        .collapsing .form-group {
            margin-top: 0.75rem;
        }

        .input-checkbox__label {
            font-weight: $font-weight-light;
        }
    }

    &__block-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 40px;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .icon {
            transition: rotate 200ms $ease-in-out-cubic;
            width: 12px;
            height: auto;
            rotate: 180deg;
        }

        &.collapsed .icon {
            rotate: initial;
        }
    }

    &__block-show-more {
        margin-top: 0.875rem;
        color: $color-ribbon-red;
    }

    &__filter {
        &:last-of-type .form-group {
            margin-bottom: 0;
        }
    }
}
