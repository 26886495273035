.dealer-map-modal {
    .dealers-map {
        margin: 0;
    }

    .modal__close:only-child {
        position: absolute;
        margin-block-end: 0;
        height: 2rem;
        width: 2rem;
        padding: 0.5rem;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .dealer-list__header {
        padding-top: 2rem;
    }

    @include media-breakpoint-up(md) {
        .dealers-map .side-panel,
        .dealers-map .google-map {
            height: 100vh;
        }
    }

    @include media-breakpoint-up(lg) {
        .modal__close:only-child {
            position: absolute;
            margin-block-end: 0;
            height: 3rem;
            width: 3rem;
            padding: 1rem;
        }
    }
}
