@import "../../abstracts/fluid-sizes";

.native-scroller {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min(90%, 26rem);
    gap: #{fluid(16px, 40px)};
    margin-block-end: #{fluid(24px, 68px)};

    @media (width < 36em) {
        margin-inline: -20px;
        padding-inline: 20px;
        scroll-padding-inline: 20px;
    }

    @include media-breakpoint-down(sm) {
        overflow-x: auto;
        overscroll-behavior-x: contain;
        scroll-snap-type: x mandatory;
        scroll-snap-stop: always;

        /* For Webkit-based browsers (Chrome, Safari and Opera) */
        &::-webkit-scrollbar {
            display: none;
        }

        /* For IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &__snap {
            scroll-snap-align: start;
        }
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
    }
}
