.CartQuickView {
    $self: &;

    --padding: 1.5rem;

    padding: 0;
    border: 1px solid $color-alto-gray;
    margin: 0;
    isolation: isolate;

    > :not(#{$self}-backdrop) {
        display: grid;
        box-sizing: border-box;
        padding: var(--padding);
        background-color: $color-white;
        grid-template-rows: 1fr max-content;

        @media (width <= 47.9375em) {
            max-block-size: 90vh;
            max-block-size: 100dvh;
        }

        @media (width >= 48em) {
            max-block-size: 30rem;
        }
    }

    @media (width <= 47.9375em) {
        --padding: 1.25rem;

        position: fixed;
        z-index: 10;
        width: auto;
        inset: 0;

        &-backdrop {
            position: fixed;
            z-index: -1;
            background-color: hsl(0deg 0% 0% / 25%);
            inset: 0;
        }
    }

    @media (width >= 48em) {
        inline-size: 30rem;
        inset: 58px 0 auto auto;

        &::before,
        &::after {
            position: absolute;
            top: var(--pos-block-start);
            right: var(--pos-inline-end);
            border-right: var(--triangle) solid transparent;
            border-bottom: var(--triangle) solid var(--triangle-color);
            border-left: var(--triangle) solid transparent;
            content: "";
        }

        &::before {
            --triangle: 1rem;
            --triangle-color: #{$color-alto-gray};
            --pos-block-start: -1rem;
            --pos-inline-end: 1rem;
        }

        &::after {
            --triangle: calc(1rem + 1px);
            --triangle-color: #{$color-white};
            --pos-block-start: calc((1rem - 1.5px) * -1);
            --pos-inline-end: calc(1rem - 1px);
        }
    }

    @media (width >= 75em) {
        inset: 60px 0 auto auto;
    }

    @media (width >= 90em) {
        inset: 63px 18px auto auto;
    }

    @media (width >= 100em) {
        inset: 71px 20px auto auto;
    }

    &-scroll {
        overflow-y: scroll;
        margin-inline: calc(var(--padding) * -1);
        padding-inline: var(--padding);
        overscroll-behavior-y: contain;
    }

    &-notice {
        --size: 1rem;

        @include text-body;

        color: $color-limeade-green;
        padding-block-end: var(--padding);
        text-transform: none;
        display: inline-flex;
        align-items: center;
        gap: 0.25rem;
        font-size: 0.875rem;

        &::before {
            content: "";
            width: var(--size);
            height: var(--size);
            mask-image: var(--checkmark);
            mask-size: var(--size);
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: currentcolor;
        }
    }

    &-actions {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        padding-block-start: var(--padding);

        > * {
            display: flex;
            flex: 1 1 0%;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
        }
    }

    .LineItems {
        margin-inline: calc(var(--padding) * -1);
        text-transform: none;

        > li {
            padding-block: 1rem;
            padding-inline: var(--padding);
        }
    }

    .Money-currency,
    .Money-literal {
        display: inline-flex;
    }
}
