#twn-starter-overlay .twn-facet-selection {
    display: none !important;
}

#twn-starter-overlay .twn-dropdown-facet {
    display: none;
}

.twn-product-tile__image {
    background: #f1f1f1;
}

.twn-product-tile__image img {
    mix-blend-mode: multiply;
}
