.product-overview-card {
    position: relative;
    display: flex;
    height: 100%;
    background-color: $color-white;
    border: 1px solid $color-alto-gray;
    transition: all $animation-speed;

    &:hover {
        border-color: $color-geyser-gray;
        box-shadow: 0 30px 40px 0 rgb(0 0 0 / 5%);
    }

    > a {
        display: flex;
        flex-direction: column;
    }

    &__image {
        img {
            width: 100%;
            height: 100%;
            aspect-ratio: 3/2;
            object-fit: contain;
            display: grid;
            background-color: $color-white;
        }
    }

    &__content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 20px 8px;
    }

    &__title {
        font-weight: $font-weight-book;
        text-transform: none;
        text-align: center;
    }

    &__prices {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
        padding-top: 1rem;
        text-align: center;
    }

    &__prices-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__normal-price {
        @include text-heading-5;

        margin-bottom: 0;
    }

    &__new-price {
        @include text-heading-5;

        margin-bottom: 0;
        margin-right: 7px;
    }

    &__old-price {
        @include text-small;

        text-decoration: line-through;
    }

    .tooltip-toggler {
        top: 0;
        position: relative;
        margin-left: 7px;

        .icon--tooltip {
            height: 16px;
            width: 16px;
        }
    }

    @media screen and (width <= 374.98px) {
        &__prices-inner {
            flex-direction: column;
        }
    }

    @include media-breakpoint-up(md) {
        &__old-price {
            margin-left: 8px;
            order: 1;
        }
    }

    @include media-breakpoint-up(xl) {
        &__available {
            svg {
                height: 16px;
                width: 16px;
                margin-right: 8px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        &__content {
            padding: 24px;
        }
    }
}
