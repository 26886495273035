[widget="AddToCartButton"] {
    min-block-size: 40px;
}

.AddToCartButton {
    gap: 1rem;

    .AddToCartButton-icon {
        --size: 1.25em;
        --icon: var(--cart);

        display: grid;
        width: var(--size);
        height: var(--size);
        mask-image: var(--icon);
        mask-size: var(--size);
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: currentcolor;
    }

    &.AddToCartButton-added {
        background: $color-mine-shaft-gray;
        color: $color-white;

        > .AddToCartButton-icon {
            --icon: var(--checkmark);
        }
    }

    &[aria-busy="true"] {
        > .AddToCartButton-icon {
            animation: load 1s steps(8) infinite;
            mask: conic-gradient(from 22deg, #0003, #000);

            &,
            &::before {
                --g: linear-gradient(#{$color-white} 0 0) 50%; /* update the color here */

                background:
                    var(--g) / 34% 8% space no-repeat,
                    var(--g) / 8% 34% no-repeat space;
            }

            &::before {
                content: "";
                transform: rotate(45deg);
            }

            @keyframes load {
                from {
                    transform: rotate(0turn);
                }

                to {
                    transform: rotate(1turn);
                }
            }
        }
    }

    &-stockMessage {
        @include text-small;

        margin-block: 1rem 0;
        color: $color-smalt-blue;
    }
}
