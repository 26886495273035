.list-useful-links-block {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 30px;

    &__title {
        display: none;
    }

    &__label {
        @include text-heading-5;

        margin-bottom: 1.125rem;
        color: rgba($color-mine-shaft-gray, 0.4);
    }

    &__list {
        background: $color-gallery-gray;
    }

    &__item a {
        color: $font-color-heading;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        display: block;
        margin: 0;
        padding: 16px 24px;
    }

    &__item:not(:last-child) a {
        border-bottom: 1px solid $color-iron-gray;
    }

    &__logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;
    }

    &__logos-item {
        height: 3rem;
        width: auto;
    }

    @media (min-width: $tablet-landscape) {
        flex: 0 0 220px;
        font-size: 12px;
        margin-top: 0;
        margin-left: 54px;

        &__title {
            display: block;
        }

        &__logos {
            justify-content: flex-end;
        }

        &__logos-item {
            height: auto;
            max-height: 4rem;
        }
    }

    @include media-breakpoint-up(xl) {
        flex: 0 0 240px;
        margin-left: 70px;
    }

    @include media-breakpoint-up(xxl) {
        flex: 0 0 320px;
        margin-left: 90px;
        font-size: 14px;
    }
}
