.downloads-block {
    &__title {
        margin-bottom: 20px;
    }

    &__item:not(:last-child) {
        margin-bottom: 16px;
    }

    &__url {
        display: flex;
        margin-bottom: 0;
        font-weight: $font-weight-medium;
        color: $font-color-body;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;

        &:hover {
            color: $color-ribbon-red;
        }

        .icon {
            position: relative;
            flex: 0 0 16px;
            margin-right: 12px;
        }

        span {
            display: block;
        }
    }

    @include media-breakpoint-up(lg) {
        &__title {
            margin-bottom: 32px;
        }

        &__url {
            font-size: 16px;
            line-height: 27px;
        }
    }
}
