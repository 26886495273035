.embla-thumbs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;

    &__item {
        background-color: transparent;
        display: block;
        text-decoration: none;
        cursor: pointer;
    }

    @include media-breakpoint-up(md) {
        margin-top: 1.5rem;
    }
}
