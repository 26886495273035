.input-date-select {
    position: relative;
    border: 1px solid $form-border-color;
    display: inline-block;
    width: 100%;

    &::before {
        content: "";
        display: block;
        width: 1px;
        background: $form-border-color;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        z-index: 1;
    }

    input[type="text"] {
        padding: 10px 56px 10px 16px;
        appearance: none;
        background: url("#{$static-path}/svg/arrow-caret-fat-down.svg") no-repeat right 14px center;
        background-size: 12px auto;
        z-index: 1;
        position: relative;
        color: $color-mine-shaft-gray;
        line-height: 20px;
        font-weight: $font-weight-medium;
        width: 100%;
        height: 40px;
        border: 0;
        box-shadow: none;

        &::placeholder {
            opacity: 1;
            color: $color-mine-shaft-gray;
        }

        &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    @include media-breakpoint-up(md) {
        &::before {
            right: 48px;
        }

        input[type="text"] {
            padding: 14px 72px 14px 24px;
            background-position: right 18px center;
            height: 48px;
        }
    }

    @include media-breakpoint-up(xl) {
        &::before {
            right: 64px;
        }

        input[type="text"] {
            padding: 22px 88px 22px 24px;
            background-position: right 26px center;
            height: 64px;
        }
    }
}
