.column-grid-block {
    margin-top: 40px;
    margin-bottom: 40px;

    &__inner {
        & img,
        .image-block picture img {
            height: 100%;
            width: 100%;
        }
    }

    &__inner > *:not(:last-child) {
        margin-bottom: 24px;
    }

    @include media-breakpoint-down(md) {
        > .container > .row > .col-12:not(:last-child) {
            margin-bottom: 2em;
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: 80px;
        margin-bottom: 80px;

        &__inner > *:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 120px;
        margin-bottom: 120px;

        &__inner > *:not(:last-child) {
            margin-bottom: 60px;
        }
    }
}
