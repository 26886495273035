.soft-lead-button {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem 0.75rem 0.75rem;
    border: 1px solid $border-color;
    column-gap: 1rem;

    &__text {
        @include text-heading;

        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 0;
    }

    &__icon {
        display: block;
        flex: 0 0 18px;
        max-width: 18px;
        height: auto;
        margin-left: auto;
    }

    @include media-breakpoint-up(md) {
        &__text {
            font-size: 14px;
            line-height: 17px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__text {
            font-size: 16px;
            line-height: 21px;
        }
    }
}
