.dealer-reviews {
    &__items {
        display: grid;

        @include media-breakpoint-up(md) {
            row-gap: 2rem;
            column-gap: 4rem;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: masonry;
        }

        @include media-breakpoint-up(lg) {
            row-gap: 2.5rem;
            column-gap: 10rem;
        }
    }

    &__pagination {
        padding-top: 1.5rem;

        @include media-breakpoint-up(md) {
            padding-top: 2rem;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 2.5rem;
        }
    }

    &__no-results {
        grid-column: 1 / span 2;
    }
}

.dealer-reviews-intro {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;

    &__image {
        flex: 0 1 156px;
        margin-right: 20px;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__dealer-info {
        flex: 0 0 auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .rating-block {
        --highlight-color: $color-mine-shaft-gray;

        align-items: center;
    }

    .rating-block__stars {
        line-height: 1;
    }

    @include media-breakpoint-up(md) {
        align-items: center;
        margin-bottom: 2.5rem;

        &__image {
            margin-right: 30px;
        }

        &__dealer-info {
            flex-direction: row;
            align-items: center;
        }

        .rating-block {
            margin-right: 21px;
        }

        &__review {
            margin-right: 21px;
            color: $color-mine-shaft-gray;
        }
    }

    @include media-breakpoint-up(xl) {
        &__review {
            font-size: 16px;
            margin-top: 0;
        }
    }
}

.dealer-reviews-item {
    padding: 1.125rem 0;
    border-bottom: 1px solid $card-border-color;

    .rating-block {
        --highlight-color: $color-mine-shaft-gray;

        margin-bottom: 1.125rem;
    }

    &__answer {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 0.75rem;

        &::before,
        &::after {
            position: relative;
            font-family: $font-family-headings;
            font-weight: $font-weight-heavy;
            font-size: 1.5rem;
            line-height: 1;
            color: $color-mine-shaft-gray;
        }

        &::before {
            content: "“";
            margin-right: -2px;
        }

        &::after {
            content: "”";
            margin-left: -4px; // End quote needs a slightly larger offset than start quote for some reason.
        }

        @include media-breakpoint-up(xl) {
            font-size: 1rem;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 0;
        border-bottom: none;
    }
}

.dealer-reviews-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 2.5rem;
    }
}
