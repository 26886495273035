/**
* @description Overlay
*
*/

.overlay {
    inset: 0;
    position: fixed;
    z-index: 9999;

    &__background {
        inset: 0;
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;

        &::before,
        &::after {
            inset: 0;
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
        }

        &::before {
            background-color: rgba($color-black, 0.7);
            z-index: 1;
        }

        &::after {
            // background-color: $color-white;
            z-index: 2;
        }
    }

    &__body {
        inset: 0;
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 3;
        -webkit-overflow-scrolling: touch;
        overflow-y: hidden;

        &-iframe {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    &__btn-close {
        background-color: transparent;
        border: 0;
        display: block;
        height: 30px;
        width: 30px;
        position: absolute;
        right: 40px;
        top: 40px;
        z-index: 4;

        svg {
            pointer-events: none;
        }

        .icon {
            fill: $color-white;
            filter: drop-shadow(1px 1px 1px rgba($color-black, 0.3));
        }

        &:active,
        &:hover {
            text-decoration: none;
        }
    }

    &__body-iframe.is-youtube {
        padding-top: 50px;
        background-color: black;
    }

    &.is-gallery {
        .overlay {
            &__background {
                &::before {
                    background-color: red;
                }

                &::after {
                    display: none;
                }
            }

            &__btn-close {
                i {
                    color: $color-white;
                }
            }
        }
    }

    .slick-arrow {
        @include media-breakpoint-up(md) {
            width: 48px;
            height: 48px;

            svg {
                width: 10px;
                height: auto;
            }
        }

        @include media-breakpoint-up(xl) {
            width: 96px;
            height: 96px;

            svg {
                width: 20px;
                height: auto;
            }
        }
    }

    .slick-prev {
        transform: translate3d(0, -50%, 0) scaleX(-1);

        @include media-breakpoint-up(xl) {
            left: 40px;
        }
    }

    .slick-next {
        @include media-breakpoint-up(xl) {
            right: 40px;
        }
    }
}
