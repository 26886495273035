.block-table {
    &__title {
        text-align: center;
    }

    .rich-text {
        margin-top: 16px;
    }

    &__table {
        table {
            border-collapse: collapse;
            width: 100%;
        }

        td,
        th {
            background: $color-white;
            text-align: left;
            word-break: break-word;
        }

        th,
        .table-header {
            @include text-heading;

            font-size: 16px;
            line-height: 1;
            margin-bottom: 0;
            white-space: nowrap;
        }

        &--responsive,
        &--specifications {
            td,
            th {
                border-right: 1px solid $border-color;
                border-top: 1px solid $border-color;
                padding: 12px 15px;
            }
        }

        &--specifications {
            border-bottom: 1px solid $border-color;

            .table-header {
                white-space: normal;
                vertical-align: middle;
            }

            .table-content {
                text-align: center;
                vertical-align: middle;
            }

            .table-content a {
                @include text-link;
            }

            svg {
                width: 24px;
                height: 24px;
            }

            th,
            td {
                border-left: 1px solid $border-color;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__table--responsive {
            table {
                border-bottom: 1px solid $border-color;
                border-left: 1px solid $border-color;
            }
        }

        &__table--specifications {
            th,
            td {
                width: 50%;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__table--specifications {
            .table-header {
                font-size: 16px;
                line-height: 19px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__table {
            &--responsive {
                table,
                tbody,
                td,
                th,
                thead,
                tr {
                    display: block;
                }

                thead tr {
                    left: -9999px;
                    position: absolute;
                    top: -9999px;
                }

                tr {
                    border-top: 1px solid $border-color;
                    padding-bottom: 15px;

                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }

                td {
                    border: 1px solid $border-color;
                    border-top: none;
                    display: flex;
                    flex-direction: row;
                    padding: 0;

                    &::before,
                    span {
                        height: 100%;
                        padding: 12px 15px;
                    }

                    span {
                        width: 55%;
                    }

                    &::before {
                        @include text-heading-5;

                        margin-bottom: 0;
                        align-items: center;
                        border-right: 1px solid $border-color;
                        content: attr(data-title);
                        display: inline-flex;
                        flex-grow: 0;
                        flex-shrink: 0;
                        height: inherit;
                        width: 45%;
                    }
                }
            }

            &--specifications {
                table,
                tbody,
                td,
                th,
                thead,
                tr {
                    display: block;
                    text-align: center;
                }

                .table-header {
                    background-color: $color-porcelain-gray;
                }
            }
        }
    }
}
