// Slides
.glide__slide {
    height: auto;
}

.glide__slides {
    align-items: stretch;
}

// Prev/next controls
.glide-controls {
    margin-top: 48px;
    justify-content: center;

    &.hidden {
        display: none !important;
    }

    &__prev,
    &__next {
        display: inline-flex;
        align-items: center;
        transform: perspective(1px);
        padding: 17px;
        background-color: $color-mine-shaft-gray;
        border-radius: 50%;

        svg {
            width: 14px;
            height: 14px;
            color: $white;
        }

        &:not([disabled]):hover {
            background-color: $color-dove-gray;
        }

        &[disabled] {
            cursor: not-allowed;
            opacity: 0.2;
        }
    }

    &__prev {
        transform: scaleX(-1);
        margin-right: 6px;
    }
}
