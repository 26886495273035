.occasion-finance {
    &__price-block {
        padding: 24px;
        border: 1px solid $color-porcelain-gray;
    }

    &__monthly-price {
        padding: 24px;
        background: $color-porcelain-gray;

        .cta {
            width: 100%;
        }
    }
}

.occasion-finance-payment-summary {
    &__title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__change {
        color: $color-mine-shaft-gray;
    }
}

.occasion-price-summary {
    text-align: center;

    .tooltip_svg__tooltip-background-color {
        fill: $color-mine-shaft-gray;
    }

    &__trade-in-warning {
        border: 1px solid #899da7;
        background: #e5eaec;
        padding: 8px 12px;
        display: flex;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 16px;

        svg {
            flex: 0 0 16px;
        }
    }

    @include media-breakpoint-up(md) {
        text-align: left;

        &__default-price {
            display: flex;

            h3:last-child {
                margin-left: auto;
            }
        }
    }
}
