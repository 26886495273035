/*
    Modal has non-BEM classes: those belong to Bootstrap
*/
.modal {
    $self: &;

    overflow-x: hidden;
    overflow-y: auto;

    &-content {
        border: 0;
        border-radius: 2px;
    }

    &-header {
        padding: 48px 48px 0;
        border-bottom: 0;
        border-radius: 0;
    }

    &-title {
        @include block-title;
    }

    &-body {
        padding: 0 24px 24px;
    }

    &-dialog {
        max-width: 582px;
    }

    &-xl {
        max-width: 100%;
        margin: 0;

        .modal-header,
        .modal-body {
            padding: 0;
        }

        .modal-header {
            z-index: 2;
        }
    }

    &__close {
        position: relative;
        background: $color-mine-shaft-gray;
        right: -24px;
        top: -24px;
        border-top-right-radius: 0.125rem;
        z-index: 1;
        width: 3rem;
        height: 3rem;
        color: $white;

        .icon {
            width: 18px;
            height: 18px;
        }

        .icon path {
            fill: $color-white;
        }
    }

    // When the sticky navigation button is shown, we need to add some extra padding
    // to ensure the modal does not get partially hidden.
    &--extra-padding {
        padding-bottom: 63px;
    }

    @include media-breakpoint-up(lg) {
        &.modal-lg-wide .modal-dialog {
            max-width: 1100px;
        }
    }

    @include media-breakpoint-down(md) {
        &__close {
            position: absolute;
            right: 0;
            top: 0;
        }

        .form__success-block .rich-text > *:first-child,
        &-title {
            padding-right: 20px;
        }
    }

    @include media-breakpoint-up(md) {
        &-header {
            padding: 50px 60px 0;
        }

        &-body {
            padding: 0 60px 50px;
        }

        &__close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 12px 16px;
        }

        &-xl {
            max-width: 90%;
            margin: 1.5rem auto;
        }

        &--extra-padding {
            padding-bottom: 83px;
        }
    }

    @include media-breakpoint-up(xl) {
        &-dialog {
            max-width: 670px;
        }

        &-xl {
            max-width: 90%;
        }
    }

    @include media-breakpoint-up(xxl) {
        &-dialog {
            max-width: 790px;
        }

        &-xl {
            max-width: 90%;
        }
    }

    &-full-screen {
        #{$self}-open & {
            overflow: hidden;
        }

        #{$self}-dialog {
            display: flex;
            align-items: stretch;
            margin: 0;
            width: 100%;
            max-width: none;
            height: 100%;
        }

        #{$self}-content {
            border-radius: 0;
            flex: 1;
        }

        #{$self}-body {
            padding: 0;
            background-color: $color-white;
        }

        #{$self}-header {
            padding: 0;
        }

        #{$self}__title {
            margin: 0;
            padding-block: 24px 16px;
            padding-inline: 16px;
            font-weight: 300;
        }

        #{$self}__close {
            position: static;
            border-radius: 0;

            &:only-child {
                margin-block-end: 16px;
                margin-inline-start: auto;
            }
        }
    }
}

.react-modal {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
}

.react-modal-backdrop {
    opacity: $modal-backdrop-opacity;
}

.react-modal-enter {
    .modal-dialog {
        transform: $modal-fade-transform;
    }

    .modal-backdrop {
        opacity: 0;
    }
}

.react-modal-enter-active {
    .modal-dialog {
        transform: translate(0, 0);
        transition: $modal-transition;
    }

    .modal-backdrop {
        opacity: $modal-backdrop-opacity;
        transition: $transition-fade;
    }
}

.react-modal-exit {
    .modal-dialog {
        display: none;
    }

    .modal-backdrop {
        opacity: $modal-backdrop-opacity;
    }
}

.react-modal-exit-active {
    .modal-backdrop {
        opacity: 0;
        transition: $transition-fade;
    }
}
