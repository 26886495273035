.editions-carousel-block {
    margin: 40px 0;

    &__controls-wrap {
        padding-right: 10px;
    }

    @include media-breakpoint-up(md) {
        margin: 80px 0 60px;

        .glide-controls {
            margin-top: 48px;
        }
    }

    @include media-breakpoint-up(xxl) {
        margin: 100px 0 120px;

        .glide-controls {
            margin-top: 72px;
        }
    }
}
