.CouponCodeForm {
    // Some modern css to allow transition on block-size
    // and have nice progressive enhancement
    // https://developer.mozilla.org/en-US/docs/Web/CSS/interpolate-size
    interpolate-size: allow-keywords;
    display: grid;
    overflow-y: clip;
    padding-block-start: 1rem;
    border-block-start: 1px solid $color-alto-gray;

    // this is how you can style the details of a details element
    // https://drafts.csswg.org/css-pseudo-4/#details-content-pseudo
    &::details-content {
        block-size: 0;
        transition:
            block-size 0.3s ease,
            content-visibility 0.3s ease;
        transition-behavior: allow-discrete;
    }

    // this is how you can style the details of a details element when it is open
    &[open]::details-content {
        block-size: auto;
    }

    > div {
        padding-block-start: 1.5rem;

        .Form {
            gap: 1rem;
            box-sizing: border-box;

            > .Form-control {
                max-inline-size: none;

                > input {
                    font-size: 1.125rem;
                }

                > label {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border-width: 0;
                }
            }

            > .Form-actions {
                display: grid;
                gap: 0.5rem;
                margin-block-end: 0.5rem;
                text-align: left;

                > button {
                    border: 2px solid $color-mine-shaft-gray;
                    color: $color-mine-shaft-gray;
                    line-height: 20px;
                    background: transparent;

                    .icon {
                        fill: currentcolor;
                    }

                    &:hover,
                    &--active {
                        background: transparent;
                        color: $color-dove-gray;
                        border-color: $color-dove-gray;
                    }

                    &--active:hover {
                        box-shadow: none;
                        cursor: default;
                    }

                    &.disabled,
                    &:disabled {
                        background: $color-alto-gray;
                    }
                }
            }
        }
    }
}

.CouponCodeForm-summary {
    --size: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;

    &::after {
        content: "";
        width: var(--size);
        height: var(--size);
        background-image: var(--arrow-caret-circle);
        background-size: var(--size);
        background-repeat: no-repeat;
        background-position: center;
        rotate: 90deg;
        transition: rotate 0.3s ease;

        .CouponCodeForm[open] & {
            rotate: -90deg;
        }
    }
}
