.breadcrumb {
    padding: 24px 0;

    &__back-button {
        @include text-small;

        font-weight: $font-weight-medium;
        text-transform: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > .icon {
            fill: currentcolor;
            width: 6px;
            height: 9px;
        }
    }

    &__list {
        display: none;
    }

    &__item {
        @include text-small;

        color: $color-mine-shaft-gray;

        &:not(.active):hover {
            text-decoration: underline;
        }

        &.active {
            font-weight: $font-weight-medium;
        }

        + .breadcrumb__item {
            padding-left: 8px;

            &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%23323232'/%3E%3C/svg%3E");
                padding-right: 8px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: 32px 0;

        &__back-button {
            display: none;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
