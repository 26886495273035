.Checkout {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 2rem;

    > :first-child {
        // small flex hack to let this one always grow harder
        flex-grow: 9999;
        flex-basis: 50%;
        counter-reset: steps;
        display: grid;

        > h1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-block-end: 1.75rem;

            @include media-breakpoint-up(xl) {
                margin-block-end: 1rem;
            }

            > span {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }
    }

    .Step {
        border-block-start: 1px solid $color-alto-gray;
        padding-block: 1.5rem;
        position: relative;
        counter-increment: steps;
        display: grid;
        gap: 1.5rem;

        @include media-breakpoint-down(sm) {
            margin-inline: -1.25rem;
            padding-inline: 1.25rem;
        }

        > button {
            --icon-size: 1.5rem;

            border: none;
            background-color: transparent;
            text-align: left;
            display: grid;
            gap: 0.5rem;
            grid-template-columns: max-content 1fr max-content;

            &::before {
                @include text-heading-5;

                content: counter(steps);
                grid-row: 1;
                color: $color-white;
                background-color: $color-mine-shaft-gray;
                width: var(--icon-size);
                height: var(--icon-size);
                border-radius: 50%;
                display: grid;
                place-items: center;
                margin: 0;
                font-weight: $font-weight-medium;
            }

            @include media-breakpoint-up(xl) {
                --icon-size: 2rem;

                &::before {
                    position: relative;
                    top: -0.25rem;
                }
            }

            &[aria-expanded="false"]:not(:disabled) {
                &::before {
                    content: "";
                    background-color: $color-limeade-green;
                    mask-image: var(--checkmark-circle-mask);
                    mask-size: var(--icon-size);
                    mask-position: center;
                    mask-repeat: no-repeat;
                }
            }

            &:disabled {
                cursor: not-allowed;

                &::before {
                    opacity: 0.2;
                }
            }

            h2 {
                @include text-heading-5;

                margin: 0;
                grid-row: 1;
                line-height: 24px !important;
            }

            p {
                @include text-small;

                grid-column: 2;
            }
        }

        > div[aria-hidden="true"] {
            display: none;
        }

        &-edit {
            --size: 0.5rem;

            @include text-body;

            display: grid;
            grid-template-columns: max-content 1fr;
            align-items: center;
            gap: 0.5rem;
            text-transform: uppercase;
            grid-column: 3;
            grid-row: 1;

            &::before,
            &::after {
                content: "";
                grid-column: 1;
                grid-row: 1;
            }

            &::before {
                mask-image: var(--arrow-caret);
                mask-size: var(--size);
                mask-repeat: no-repeat;
                mask-position: center;
                width: var(--size);
                height: var(--size);
                background-color: $color-white;
                margin: 0.5rem;
            }

            &::after {
                width: 1.5rem;
                aspect-ratio: 1;
                background-color: $color-mine-shaft-gray;
                border-radius: 50%;
            }
        }
    }

    .StepAddress-summary,
    .StepShipping-summary {
        @include text-small;

        display: grid;
        gap: 1.5rem;
        grid-column: 2 / -1;
        grid-template-columns: 1fr 1fr;

        > :first-child {
            grid-column: 1 / -1;
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr 1fr;

            > :first-child {
                grid-column: 1;
            }
        }

        h3 {
            @include text-small;

            text-transform: none;
            font-weight: $font-weight-medium;
            margin: 0;
        }
    }

    .StepPayment {
        display: grid;
        gap: 2rem;

        > .StepPayment-actions {
            text-align: right;
        }
    }

    &-overview {
        display: grid;
        flex-basis: 25rem;
        flex-grow: 1;
        padding-inline: 2rem;
        padding-block: 1.5rem;
        background-color: $color-gallery-gray;
        gap: 1.5rem;

        @include media-breakpoint-down(sm) {
            margin-inline: -1.25rem;
            padding-inline: 1.25rem;
        }

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > :first-child {
                @include text-heading-5;

                margin: 0;
            }

            > a {
                --size: 0.5rem;

                @include text-body;

                font-size: 1rem !important;
                display: grid;
                grid-template-columns: max-content 1fr;
                align-items: center;
                gap: 0.5rem;
                text-transform: uppercase;

                &::before,
                &::after {
                    content: "";
                    grid-column: 1;
                    grid-row: 1;
                }

                &::before {
                    mask-image: var(--arrow-caret);
                    mask-size: var(--size);
                    mask-repeat: no-repeat;
                    mask-position: center;
                    width: var(--size);
                    height: var(--size);
                    background-color: $color-white;
                    margin: 0.5rem;
                }

                &::after {
                    width: 1.5rem;
                    aspect-ratio: 1;
                    background-color: $color-mine-shaft-gray;
                    border-radius: 50%;
                }
            }
        }

        small {
            @include text-small;
        }

        table {
            width: 100%;

            th {
                text-align: left;
            }

            td {
                text-align: right;
            }

            tbody {
                @include text-body;

                font-weight: $font-weight-medium;

                @include media-breakpoint-up(md) {
                    font-size: 21px;
                }

                > :last-child {
                    th,
                    td {
                        padding-block-end: 1rem;
                    }
                }
            }

            tfoot {
                &,
                .Money {
                    @include text-intro;

                    @include media-breakpoint-up(md) {
                        font-size: 27px;
                    }
                }

                border-block-start: 1px solid $color-mine-shaft-gray;

                th {
                    font-weight: $font-weight-medium;
                }

                .Money {
                    font-weight: $font-weight-heavy;
                }

                th,
                td {
                    padding-block-start: 1rem;
                }
            }
        }

        .Money-currency,
        .Money-literal {
            display: inline-flex;
        }

        // aybe enable to show decimals in overview
        // .Money[data-value*=".00"] {
        //     > .Money-decimal::after {
        //         content: normal;
        //     }

        //     > .Money-fraction {
        //         display: initial;
        //     }
        // }

        .LineItems-title {
            word-break: break-word;
        }
    }

    .StepShipping-dealerWarning {
        padding: 1rem;
        border: 1px solid hsl(49deg 100% 48%);
        background-color: rgb(246 201 0 / 10%);
        font-size: 0.875rem;
        inline-size: 100%;
        margin-block-end: 2rem;
    }

    &-error {
        padding: 1rem;
        border: 1px solid hsl(345deg 100% 44%);
        background-color: rgb(222 0 57 / 10%);
        color: hsl(345deg 100% 44%);
        font-size: 0.875rem;
        inline-size: 100%;
        margin-block-start: 2rem;

        > a {
            text-decoration: underline;

            &:is(:hover, :focus-visible) {
                text-decoration: none;
            }
        }
    }

    [data-name="differentAddress"][data-value="false"] + div {
        display: none;
    }

    [data-required="true"] {
        > label,
        > legend {
            &::after {
                content: "*";
            }
        }
    }

    // Global styling for the primary button within the checkout
    .button--primary {
        --size: 1rem;

        gap: 1rem;

        &::after {
            content: "";
            width: var(--size);
            height: var(--size);
            mask-image: var(--button-arrow);
            mask-size: var(--size);
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: currentcolor;
        }
    }

    .Loader {
        --size: 1.5rem;

        display: grid;
        place-items: center;
        inline-size: var(--size);
        block-size: var(--size);
        mask-image: var(--spinner);
        mask-size: var(--size);
        mask-repeat: no-repeat;
        background-color: $color-mine-shaft-gray;
        margin-block: 1rem;
        margin-inline: auto;
        animation: spin 1s linear 0s infinite;

        > span {
            display: none;
        }
    }
}

@media (width < 36.25em) {
    [widget="CheckoutComplete"] {
        margin-inline: -1.25rem;
    }
}
