// only use hundreds, to support IE11 (e.g.: don't use 350)
$font-weight-book: 300;
$font-weight-medium: 500;
$font-weight-heavy: 900;

// Colors
// http://chir.ag/projects/name-that-color/
$color-porcelain-gray: #eaeced;
$color-submarine-gray: #b5c1c6;
$color-hit-gray: #9cacb3;
$color-silver-gray: #c6c7c8;
$color-regent-gray: #8196a0;
$color-athens-gray: #f4f4f5;
$color-stone-gray: #7f8185;
$color-silver: #ccc;

// New Colors
$color-ribbon-red: #de0039;
$color-smalt-blue: #002e9f;
$color-limeade-green: #489f02;
$color-mine-shaft-gray: #323232;
$color-trout-gray: #495057;
$color-dove-gray: #666;
$color-iron-gray: #d2d5d6;
$color-geyser-gray: #dce1e4;
$color-alto-gray: #ddd;
$color-gallery-gray: #eaeaea;
$color-white: #fff;
$color-black: #000;
$color-license-plate-yellow: #f6c900;
$color-license-plate-yellow-hover: rgb(227 188 9);
$color-license-plate-blue: #00368f;

// Font
$font-family-headings: "ITCFranklinGothicStd";
$font-family-body: $font-family-headings;
$font-color-body: $color-mine-shaft-gray;
$font-color-body-light: $color-trout-gray;
$font-color-heading: $color-mine-shaft-gray;
$animation-speed: 0.3s;
$animation-speed-lg: 0.6s;
$static-path: "/static" !default;
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$tablet-landscape: 992px;

// Layout
$header-height-sm: 65px;
$header-height-md: 81px;
$header-height-xxl: 97px;
$pre-header-height: 40px;
$footer-color-body: #aaa;
$form-border-color: $color-alto-gray;
$configurator-navigation-padding-sm: 63px;
$configurator-navigation-padding-md: 83px;

// Assets
$product-fallback-img: "#{$static-path}/images/commerce-image-not-available.webp";
