.financial-step-trade-in {
    &__placeholder {
        text-align: center;
        padding: 24px;
    }

    &__add-car {
        width: 100%;
    }

    &__remove-old-car {
        color: $color-ribbon-red;
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: auto;
    }

    .icon--car {
        width: 48px;
    }

    .icon--trash {
        width: 16px;
        height: 16px;
    }

    .trade-in-modal__section {
        margin: 0;
        width: 100%;
    }

    .trade-in-modal__card {
        border: none;
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        button {
            flex: 0 0 auto;
            width: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        &__placeholder {
            display: flex;
            align-items: center;
            padding: 40px;
            text-align: left;
        }

        .icon--car {
            margin-right: 24px;
            flex: 0 0 auto;
        }

        &__text {
            margin-right: 24px;
        }

        button {
            margin-left: auto;
        }
    }
}
