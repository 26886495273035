.occasion-summary-block {
    margin-bottom: 60px;

    &__carousel {
        margin-bottom: 26px;
    }

    // This seems like a lot of code for positioning a label,
    // but it was needed to correctly position it above a carousel with images changing size
    &__label {
        width: 100%;

        & > div {
            width: 100%;
        }

        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        padding: 0 20px;

        @include media-breakpoint-up(lg) {
            padding: 0 15px;
        }

        & .label {
            &--right {
                float: right;
                position: relative;
                top: 13px;
                right: 10px;
            }
        }

        &__inner {
            width: 100%;
            margin: 0 auto;

            @include media-breakpoint-up(md) {
                max-width: 540px;
            }

            @include media-breakpoint-up(lg) {
                max-width: none;
                padding: 0 15px;
            }

            @include media-breakpoint-down(md) {
                max-width: 450px;
            }
        }
    }

    .glide {
        &:hover .controls-wrap {
            opacity: 1;
        }

        .glide__slide {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        img {
            border: 4px;
            filter: brightness(90%);
            pointer-events: none;
            max-height: 300px;
            height: auto;
        }
    }

    .glide--image {
        margin-bottom: 20px;

        .controls-wrap {
            opacity: 0;
            transition: opacity $animation-speed;
        }

        &:hover .controls-wrap {
            opacity: 1;
        }
    }

    .glide--thumbnails {
        .glide__slide {
            border: 2px solid transparent;
            height: 42px;
        }

        .glide__slide.selected {
            border-color: $color-mine-shaft-gray;
        }
    }

    .glide .controls-wrap {
        width: 100%;

        .glide-controls {
            margin-top: 2px;
        }

        .glide-controls__prev,
        .glide-controls__next {
            position: absolute;
            top: 50%;
            left: -10px;
            transform: translateY(-50%) scaleX(-1);
            padding: 9px;
            background: $color-mine-shaft-gray;

            g,
            path {
                stroke: $color-white;
            }
        }

        .glide-controls__next {
            left: auto;
            right: -10px;
            transform: translateY(-50%) scaleX(1);
        }
    }

    &__prices {
        .price {
            display: inline-block;
        }

        em {
            font-size: 0.6em;
            text-transform: lowercase;
        }
    }

    &__options-link {
        width: 100%;
        margin-bottom: 16px;
    }

    &__contact-option {
        display: flex;
        color: $color-mine-shaft-gray;

        &:not([href^="tel:"]) span {
            text-decoration: underline;
        }

        svg {
            flex: 0 0 20px;
            margin-right: 5px;
            top: 3px;
            position: relative;
        }

        svg path {
            fill: $color-mine-shaft-gray;
        }
    }

    @include media-breakpoint-up(md) {
        &__ctas .divider-block {
            height: 60px;
        }

        &__carousel {
            margin-bottom: 0;
        }

        &__options-link {
            width: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        &__ctas .divider-block {
            height: 90px;
        }

        &__info {
            padding-left: 75px;
        }

        .glide .controls-wrap {
            opacity: 0;
            transition: opacity $animation-speed;
        }

        .glide img {
            max-height: 400px;
        }

        .glide--thumbnails .glide__slide {
            height: 78px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__ctas .divider-block {
            height: 100px;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__ctas .divider-block {
            height: 130px;
        }

        .glide img {
            max-height: 500px;
        }

        .glide--thumbnails .glide__slide {
            height: 100px;
        }
    }
}
