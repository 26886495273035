.top-tasks-bar {
    background-color: $color-mine-shaft-gray;
    padding-top: 40px;
    padding-bottom: 40px;

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__item {
        flex: 0 0 50%;
        margin-bottom: 8px;

        &:nth-child(odd) {
            padding-right: 4px;
        }

        &:nth-child(even) {
            padding-left: 4px;
        }
    }

    &__link {
        display: flex;
        background-color: transparent;
        border: 1px solid rgba($color-alto-gray, 20%);
        color: $white;
        height: 100%;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
        text-align: center;
        transition:
            color $animation-speed,
            box-shadow $animation-speed,
            border $animation-speed;

        &:hover {
            color: rgba($white, 50%);
            box-shadow: 0 30px 40px 0 rgba($color-black, 0.05);
        }
    }

    &__icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        color: inherit;
        margin-bottom: 20px;

        .icon {
            fill: currentcolor;
            height: 100%;
            max-width: none;
            width: auto;

            path {
                fill: currentcolor;
            }
        }
    }

    &__title {
        font-size: 14px;
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        word-break: break-word;
        color: inherit;
        max-width: 100%;
    }

    &__description {
        display: none;
        text-transform: uppercase;
    }

    &__item:hover &__description {
        text-decoration: underline;
    }

    @include media-breakpoint-up(md) {
        padding-top: 60px;
        padding-bottom: 60px;

        &__list {
            flex-wrap: nowrap;
        }

        &__item {
            flex: 0 1 25%;
            margin-bottom: 0;

            &:nth-child(n) {
                padding-right: 8px;
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &__title {
            font-size: 16px;
        }

        &__link {
            padding: 23px 10px;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 80px;
        padding-bottom: 80px;

        &__link {
            min-height: 152px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__description {
            display: block;
            font-size: 14px;
            font-weight: $font-weight-medium;
            margin-top: 10px;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__title {
            font-size: 21px;
        }

        &__description {
            font-size: 16px;
        }
    }
}
