.skeleton-summary {
    &__back {
        margin-block: 1.875rem;
    }

    &__title {
        height: 0.875rem;

        &:nth-child(1) {
            width: 60%;
        }

        &:nth-child(2) {
            width: 80%;
        }
    }

    &__text {
        width: 100%;
        height: 0.75rem;
        margin-bottom: 2rem;
    }

    &__cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1rem;
        padding: 1.5rem;
        margin-bottom: 1rem;
    }

    &__cta-line {
        &:nth-child(1) {
            width: 60%;
            margin-bottom: 1rem;
        }

        &:nth-child(2) {
            width: 90%;
            margin-bottom: 0.5rem;
        }

        &:nth-child(3) {
            width: 50%;
            margin-bottom: 1.5rem;
        }

        &:nth-child(4) {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        &__back {
            margin-block: 2.875rem;
        }

        &__cta-line {
            &:nth-child(4) {
                width: 40%;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__cta {
            flex-direction: row;
            text-align: left;
            align-items: flex-start;
        }
    }
}
