.articles-overview-page {
    .page-content {
        margin-bottom: 40px;
    }

    @include media-breakpoint-up(md) {
        .page-content {
            margin-bottom: 80px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .page-content {
            margin-bottom: 120px;
        }
    }
}
