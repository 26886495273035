.iframe-block {
    iframe {
        width: 1px;

        // Using min-width to set the width of the iFrame,
        // works around an issue in iOS that can prevent the iFrame from sizing correctly.
        min-width: 100%;
    }

    &.fullscreen {
        iframe {
            width: 100vw;
            height: 100vh;
        }
    }
}

#cwp-include {
    padding: 35px 0;
}

.insurance-iframe {
    width: 100%;
    height: 100%;
}
