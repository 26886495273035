.CartCounter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100vh;
    background-color: $color-ribbon-red;
    block-size: 1.125rem;
    color: $color-white;
    font-size: 0.625rem;
    font-weight: $font-weight-medium;
    min-inline-size: 1.125rem;
    padding-inline: 0.25rem;
}
