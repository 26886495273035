.articles-overview-block {
    margin-top: 30px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
        margin-top: 16px;
        margin-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 56px;
    }

    @include media-breakpoint-only(md) {
        .row {
            margin-right: -10px;
            margin-left: -10px;
        }

        [class^="col-"] {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}
