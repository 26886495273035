.toggle-box {
    $self: &;

    position: relative;
    inline-size: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    background-color: $color-white;
    border: 1px solid $color-alto-gray;
    font-weight: 300;

    &:not(#{$self}--option) {
        @include media-breakpoint-up(md) {
            max-inline-size: 320px;
        }
    }

    &__trigger {
        cursor: pointer;
        inline-size: 100%;
        padding: 0;
        text-align: left;
        font-family: inherit;
        background-color: transparent;
        border: none;
        align-self: flex-start;

        @include media-breakpoint-down(md) {
            padding: 16px;
        }
    }

    &__content {
        --size: 16px;

        position: relative;

        #{$self}--option & {
            --icon: var(--plus);
            --icon-color: #{$color-black};
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            inset: 0 0 auto auto;
            inline-size: 24px;
            aspect-ratio: 1;
        }

        &::before {
            border: 1px solid $color-alto-gray;
            background-color: $color-white;
        }

        &::after {
            display: grid;
            width: var(--size);
            height: var(--size);
            margin: 4px;
            mask-image: var(--icon);
            mask-size: var(--size);
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: var(--icon-color);
        }

        @include media-breakpoint-down(md) {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 16px;

            #{$self}__img {
                max-inline-size: 100px;
            }

            #{$self}__text {
                padding-inline-end: 56px;
            }
        }

        @include media-breakpoint-up(lg) {
            &::before,
            &::after {
                inset: 16px 16px auto auto;
            }

            #{$self}__text {
                padding-block: 16px;
                padding-inline: 16px 56px;
            }
        }

        #{$self}__img {
            display: block;
            inline-size: 100%;
            aspect-ratio: 3/2;
            overflow: hidden;

            > img {
                inline-size: 100%;
                block-size: 100%;
                object-fit: cover;
            }
        }

        .title {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.8px;
            color: $color-black;
            text-transform: capitalize;
        }

        .subtitle {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.7px;
            color: $color-mine-shaft-gray;
        }
    }

    &__actions {
        padding-block: 8px;
        padding-inline: 16px;
        border-block-start: 1px solid $color-alto-gray;
        color: $color-mine-shaft-gray;
        inline-size: 100%;

        > .button-link {
            color: $color-mine-shaft-gray;
        }
    }

    &.is-checked {
        border-color: $color-black;

        #{$self}__content {
            --icon: var(--checkmark);
            --icon-color: #{$color-white};

            &::before {
                background-color: $color-black;
                border-color: $color-black;
            }
        }

        .title {
            font-weight: 400;
        }
    }

    @include media-breakpoint-up(lg) {
        &--option {
            &.is-checked {
                #{$self}__content::before {
                    border-color: $color-white;
                }
            }
        }
    }
}
