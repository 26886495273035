.configurator {
    &__header {
        border-bottom: 1px solid $border-color;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .row {
            align-items: center;
            row-gap: 1rem;
        }
    }

    &__title {
        margin-bottom: 0;
        font-weight: $font-weight-light;
    }

    &__lease-info {
        margin-top: 32px;

        h6 {
            @include text-heading;

            font-size: 12px;
            line-height: 25px;
        }
    }

    &__disclaimer {
        font-size: 14px;
        line-height: 21px;
        margin-top: 32px;
    }

    @include media-breakpoint-up(md) {
        &__header {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        &__lease-info {
            h6 {
                font-size: 14px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__header {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &__title {
            margin-bottom: 4px;
        }

        &__lease-info {
            h6 {
                font-size: 16px;
            }
        }

        &__sticky {
            position: sticky;
            top: 40px;
            z-index: 3;
        }
    }

    @include media-breakpoint-up(xl) {
        &__header {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
        }

        &__title {
            font-size: 18px;
            line-height: 21px;
        }

        &__lease-info {
            h6 {
                font-size: 21px;
            }
        }
    }
}
