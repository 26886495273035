.newsletter-block {
    display: flex;
    align-items: center;
    background-color: #222;
    color: $color-white;
    padding-top: 40px;
    padding-bottom: 40px;

    &__initial {
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &__title {
        font-weight: $font-weight-medium;
        text-transform: none;
        color: $color-white;
        margin-bottom: 10px;
    }

    &__text {
        color: rgba($color-white, 0.9);
        margin-bottom: 20px;
    }

    &__form {
        .input-text {
            flex: 1 1 auto;
            font-weight: $font-weight-book;
            color: $color-white;
            background: rgba($color-black, 0.3);
            border: 0;
            padding: 8px 15px;

            &::placeholder {
                color: $footer-color-body;
            }
        }

        .form-group {
            text-align: left;
        }

        .input-checkbox__label {
            font-size: 14px;
            font-weight: $font-weight-book;
            color: $white;
            text-align: left;
        }

        .button {
            flex: 0 0 auto;
            margin-left: 4px;
            border: none;
            background-color: $color-white;

            &:hover {
                background-color: $color-gallery-gray;
                color: $font-color-body;
            }
        }
    }

    &__error {
        display: block;

        @include text-small;

        color: $color-ribbon-red;
        margin-top: 8px;
        text-align: left;
    }

    &__disclaimer {
        @include text-small;

        color: $footer-color-body;
        margin-top: 24px;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 40px;
        height: 40px;
        width: 40px;
        background-color: white;
        border-radius: 50%;
        margin-right: 12px;

        .icon {
            display: block;
            height: 24px;
            width: 24px;
            color: $color-mine-shaft-gray;
        }
    }

    &__success,
    &__failed {
        display: none;
        justify-content: center;
    }

    @include media-breakpoint-up(md) {
        padding-top: 50px;
        padding-bottom: 50px;

        &__form {
            .button {
                margin-left: 0;
            }
        }

        &__icon {
            margin-right: 20px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__initial {
            text-align: left;
        }

        &__text {
            margin-bottom: 0;
        }

        &__disclaimer {
            margin-top: 16px;
        }

        &__form {
            .button {
                margin-left: 4px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__form {
            .input-text {
                padding: 10px 16px 11px;
            }

            .button {
                margin-left: 8px;
            }
        }
    }
}
