.CartSkeleton {
    $self: &;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 2rem;

    > :first-child {
        display: grid;
        flex-basis: 24rem;

        // small flex hack to let this one always grow harder
        flex-grow: 9999;
        gap: 1.5rem;
    }

    > :last-child {
        display: grid;
        flex-basis: 25rem;
        flex-grow: 1;
        padding: 1rem;
        background-color: $color-alto-gray;
        gap: 1rem;
    }

    &-item {
        display: grid;
        padding: 1rem;
        border: 1px solid $color-alto-gray;
        gap: 1rem;
        grid-template-columns: max-content 1fr;

        img {
            width: 150px;
            animation: skeleton-loading 1s linear infinite alternate;
            aspect-ratio: 3/2;
        }

        > :not(img) {
            display: grid;
            gap: 0.5rem;
        }

        #{$self}-text {
            width: 70%;
        }

        > div > div:nth-child(2) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > #{$self}-select {
                width: 4rem;
            }

            > #{$self}-text {
                width: 3rem;
            }
        }
    }

    #{$self}-text,
    #{$self}-select {
        height: 0.7rem;
        margin-bottom: 0.5rem;
        animation: skeleton-loading 1s linear infinite alternate;
    }

    #{$self}-select {
        height: 2rem;
    }

    @keyframes skeleton-loading {
        0% {
            background-color: hsl(0deg 0% 80%);
        }

        100% {
            background-color: hsl(0deg 0% 70%);
        }
    }
}
