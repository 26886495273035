[widget="Availability"] {
    min-block-size: 24px;
}

.Availability {
    @include text-small;

    &-stock {
        --size: 1rem;

        &::before {
            content: "";
            width: var(--size);
            height: var(--size);
            mask-image: var(--icon);
            mask-size: var(--size);
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: currentcolor;
            display: inline-flex;
            vertical-align: -3px;
            margin-inline-end: 0.25rem;
        }

        > p {
            display: contents;

            > a {
                text-decoration: underline;

                &:is(:hover, :focus-visible) {
                    text-decoration: none;
                }
            }
        }
    }

    &-hasStock {
        --icon: var(--checkmark-circle-mask);

        color: $color-limeade-green;
    }

    &-outOfStock {
        --icon: var(--cross-circle-v2);

        color: $color-ribbon-red;
    }

    &-notAvailable {
        --icon: var(--tooltip);

        color: $color-license-plate-blue;
    }
}
