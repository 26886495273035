.skeleton-toggle-box {
    background-color: $white;
    border: 1px solid $color-alto-gray;
    height: 79px;
    flex: 0 0 100%;
    padding: 1rem;

    &__title {
        height: 16px;
        width: 120px;
        margin-bottom: 10px;
    }

    &__subtitle {
        height: 12px;
        width: 100px;
    }

    &__toggle {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
    }

    @include media-breakpoint-up(md) {
        flex: 0 1 320px;
        max-width: 320px;
    }
}
