.motor-insurance {
    max-width: 750px;

    & .license-plate {
        @include media-breakpoint-down(sm) {
            width: 100%;
            max-width: 100%;
        }
    }

    & .submit-button {
        & button {
            height: 48px;

            @include media-breakpoint-down(sm) {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

.license-plate {
    max-width: 254px;

    &__input-group {
        height: 48px;

        &__label {
            font-size: 12px;
        }

        &__prepend {
            height: 100%;
            background-color: $color-license-plate-blue;
            border: 1px solid $color-license-plate-blue;
            color: white;
            min-width: 48px;
        }

        &__input {
            height: 100%;
            background-color: $color-license-plate-yellow;
            border: 1px solid $color-license-plate-yellow;
            border-radius: 0;
            font-size: 24px;
            font-weight: 900;
            width: 100%;
            text-transform: uppercase;

            &:hover {
                background-color: $color-license-plate-yellow-hover;
                border-color: $color-license-plate-yellow-hover;
            }

            &:focus-within,
            &:focus,
            &:focus-visible {
                border: 1px solid $color-license-plate-yellow;
                outline: none !important;
            }
        }

        &.validation-error {
            & input {
                border-color: $red;
                border-left-color: $color-license-plate-yellow;
                background-color: rgba($color: $red, $alpha: 5%);
            }
        }
    }
}
