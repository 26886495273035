.quick-links-block {
    margin-top: 40px;
    margin-bottom: 40px;

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        flex: 0 0 50%;
        margin-bottom: 8px;

        &:nth-child(odd) {
            padding-right: 4px;
        }

        &:nth-child(even) {
            padding-left: 4px;
        }
    }

    &__link {
        display: flex;
        background: $color-gallery-gray;
        height: 100%;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
        text-align: center;
    }

    &__icon {
        height: 30px;
        margin-bottom: 17px;

        .icon {
            max-width: 33px;
            width: auto;
            height: 100%;
            color: $color-mine-shaft-gray;
        }
    }

    &__title {
        font-size: 14px;
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        max-width: 100%;
    }

    &__description {
        display: none;
        text-transform: uppercase;
        color: $font-color-body-light;
    }

    &__item:hover &__description {
        text-decoration: underline;
    }

    @include media-breakpoint-up(md) {
        &__list {
            flex-wrap: nowrap;
        }

        &__item {
            flex: 1 1 25%;
            margin-bottom: 0;

            &:nth-child(n) {
                padding-right: 8px;
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &__title {
            font-size: 16px;
        }

        &__link {
            padding: 23px 10px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__link {
            min-height: 160px;
            justify-content: center;
        }

        &__icon {
            height: 35px;
        }
    }

    @include media-breakpoint-up(xl) {
        margin-top: 80px;
        margin-bottom: 80px;

        &__icon {
            height: 40px;
        }

        &__description {
            display: block;
            font-size: 14px;
            font-weight: $font-weight-medium;
            margin-top: 6px;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__title {
            font-size: 21px;
        }

        &__description {
            font-size: 16px;
        }
    }
}
