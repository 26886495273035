/**
* @description UI - Gallery group
*
*/

.gallery-group {
    overflow: hidden;
    width: calc(100% - 30px);
    margin: auto;

    &__container {
        display: flex;
        flex-wrap: nowrap;
        transition: transform 300ms $ease-in-out-cubic;
        will-change: tranform;
    }

    .gallery {
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
    }
}
