.trade-in-modal {
    h2 {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 8px;
    }

    .text-small {
        font-size: 12px;
        line-height: 19px;
    }

    &__paragraph {
        font-size: 16px;
        line-height: 24px;
    }

    &__title {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 14px;
    }

    &__section {
        margin-top: 20px;
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__card {
        position: relative;
        padding: 24px 24px 16px;
        border: 1px solid $color-alto-gray;
        border-radius: 4px;
    }

    &__card-header {
        display: flex;
        align-items: center;
        gap: 0.625rem;

        .text-link {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.5px;
            color: $color-ribbon-red;
        }

        .icon {
            color: $color-ribbon-red;
            height: 1rem;
            width: 1rem;
        }
    }

    &__card-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        gap: 12px;
    }

    &__card-item {
        flex: 1 0 calc(50% - 12px * 2 / 3);
        max-width: 100%;

        .text-small {
            margin-bottom: 6px;
        }
    }

    &__cta {
        background-color: $color-porcelain-gray;
        padding: 24px;
        margin-bottom: 16px;

        .button {
            width: 100%;
        }

        .button.disabled:hover {
            background-color: $color-alto-gray;
        }

        h5 {
            margin-bottom: 6px;
        }

        p:not(.text-small) {
            font-size: 16px;
            line-height: 24px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__card-edit {
        border-top: 1px solid $color-alto-gray;
        padding-top: 21px;
        margin-top: 21px;

        h5 {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 6px;
        }
    }

    .feature-label {
        font-size: 16px;
        line-height: 24px;
        font-weight: $font-weight-book;
        text-transform: none;
    }

    .trade-in-form {
        margin-bottom: 0;
        margin-top: 20px;
    }

    &__suggestions {
        display: flex;
        flex-direction: column;
    }

    &__suggestions-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 auto;
        margin-bottom: 35px;
        padding: 20px;
        border: 1px solid transparent;
        border-radius: 2px;
        cursor: pointer;

        h5 {
            margin-bottom: 8px;
        }

        img {
            margin-bottom: 1rem;
            width: 172px;
            height: auto;
        }

        div {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: $font-weight-heavy;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: $color-mine-shaft-gray;
            text-transform: uppercase;
        }

        .icon {
            fill: $color-limeade-green;
            margin-right: 8px;
        }

        &:hover {
            span {
                color: $color-limeade-green;
            }
        }

        &.is-selected {
            border-color: $color-geyser-gray;
            background: #fff;
            box-shadow: 0 30px 40px 0 rgb(0 0 0 / 5%);

            span {
                color: $color-limeade-green;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__proposal {
        background-color: $color-athens-gray;

        p {
            font-size: 12px;
            font-weight: $font-weight-book;
            line-height: 19px;
            letter-spacing: 0.5px;
            margin-bottom: 6px;
        }

        hr {
            margin-top: 12px;
            margin-bottom: 12px;
        }
    }

    &__proposal-block {
        &:first-of-type {
            padding: 26px 24px 20px;
        }

        + .trade-in-modal__proposal-block {
            padding: 20px 24px;
            border-top: 1px solid $color-alto-gray;

            span {
                color: $color-ribbon-red;
            }
        }
    }

    &__proposal-row {
        display: flex;
        margin-bottom: 8px;

        &:last-of-type {
            margin-bottom: 0;
        }

        span {
            font-size: 12px;
            font-weight: $font-weight-heavy;
            color: $color-mine-shaft-gray;
            line-height: 16px;
            letter-spacing: 0.5px;

            &:first-of-type {
                flex: 1 1 auto;
                margin-right: 16px;
            }

            &:last-of-type {
                flex: 0 0 auto;
                margin-left: auto;
            }
        }

        .tooltip-toggler svg {
            top: 3px;
            position: relative;
        }
    }

    .contactinfo-select-dealer {
        position: relative;

        &__search {
            position: absolute;
            right: 14px;
            bottom: 9px;
        }
    }

    &__disclaimer {
        border-top: 1px solid $border-color;
        margin-top: 1rem;
        padding-top: 1rem;
    }

    @include media-breakpoint-up(md) {
        &__cta .button {
            width: auto;
        }

        .contactinfo-select-dealer {
            &__search {
                bottom: 14px;
            }
        }
    }

    &__trade-options {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .button {
            flex: 1 1 100%;
        }

        .button:first-child {
            margin-bottom: 8px;
        }
    }

    @include media-breakpoint-up(md) {
        &__trade-options {
            .button {
                flex: 0 0 auto;
            }

            .button:first-child {
                margin-right: 16px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        h2 {
            font-size: 32px;
            line-height: 35px;
            margin-bottom: 16px;
        }

        .text-small {
            font-size: 14px;
            line-height: 21px;
        }

        &__title {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 18px;
        }

        &__section {
            margin-top: 34px;
            margin-bottom: 34px;
        }

        &__card {
            p {
                margin-bottom: 20px;
            }
        }

        &__card-item {
            flex: 1;

            h5 {
                font-size: 16px;
                line-height: 25px;
            }
        }

        &__cta {
            h5 {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 3px;
            }
        }

        .trade-in-form {
            margin-top: 32px;

            &__form {
                flex-wrap: wrap;
            }

            &__item {
                flex: 1 0 calc(50% - 12px);
                max-width: 100%;
                margin-right: 0;
                margin-bottom: 24px;

                &:first-of-type {
                    margin-right: 24px;
                }

                &:last-of-type {
                    flex: 0 0 auto;
                    margin-bottom: 0;
                }
            }
        }

        &__proposal {
            hr {
                margin-top: 8px;
                margin-bottom: 8px;
            }

            p {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 3px;
            }
        }

        &__proposal-row {
            margin-bottom: 3px;

            span {
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.33px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__suggestions {
            flex-direction: row;
        }

        &__suggestions-item {
            flex: 1 1 0;
            margin-right: 1rem;
            margin-bottom: 0;
            padding: 16px 0;

            h5 {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 15px;
                text-align: center;
            }

            img {
                margin-bottom: 25px;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}
