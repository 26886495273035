@import "../../abstracts/fluid-sizes";

.product-detail {
    $self: &;

    margin-bottom: 72px;

    &__title {
        margin-bottom: 1.25rem;
    }

    &__description {
        margin-bottom: 1.5rem;
    }

    &__carousel {
        position: relative;
        margin-bottom: 1.5rem;

        > #{$self}__discount-label,
        > #{$self}__warranty-logo {
            position: absolute;
            z-index: 1;
            pointer-events: none;
        }

        > #{$self}__discount-label {
            top: fluid(16px, 32px, 600px, 1400px);
            right: 0;
            display: inline-flex;
            color: $color-white;
            background-color: $color-ribbon-red;
            font-size: fluid(18px, 21px, 600px, 1400px);
            font-weight: 800;
            padding-inline: 0.25em;
            text-transform: uppercase;
        }

        > #{$self}__warranty-logo {
            inset-block-start: 0;
            inset-inline-start: 1.5rem;
            aspect-ratio: 5 / 8;
            block-size: 6rem;

            @include media-breakpoint-up(md) {
                block-size: 8rem;
            }

            > img {
                block-size: 100%;
                inline-size: 100%;
                object-fit: contain;
            }
        }
    }

    &__table {
        --flow-spacer: 1.125rem;

        width: 100%;

        th,
        td {
            border-left: none;
            border-right: none;
            padding: 0.875rem 0;
        }

        th {
            @include text-body;
            @include text-heading-medium;

            width: 25%;
            padding-right: $grid-gutter-width-half;
        }

        td {
            width: 75%;
            padding-left: $grid-gutter-width-half;
        }
    }

    &__specifications {
        border-top: 1px solid $border-color;
        margin-top: 2rem;
        padding-top: 2rem;

        // Full-width container for mobile
        @include media-breakpoint-down(xs) {
            margin-left: $grid-gutter-width-half * -1;
            width: calc(100% + #{$grid-gutter-width});
            padding-left: $grid-gutter-width-half;
            padding-right: $grid-gutter-width-half;
        }
    }

    &__section {
        margin-top: 1.625rem;
        margin-bottom: 1.625rem;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__section-title {
        @include text-heading-4;
    }

    &__section-subtitle {
        --flow-spacer: 1.5rem;

        @include text-heading;

        margin: 0;
        font-size: 18px;
        line-height: 21px;

        @include media-breakpoint-up(xl) {
            font-size: 21px;
            line-height: 25px;
        }
    }

    &__usps {
        margin-top: 2rem;
    }

    &__usps-item {
        position: relative;
        padding-left: 1.5rem;
        margin-bottom: 0.5rem;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 1.5px;
            left: 0;
            width: 16px;
            height: 16px;
        }
    }

    &__contact {
        margin-top: 1.25rem;
        border: 1px solid $border-color;
        padding: 0.75rem 1rem;
    }

    &__message {
        border: 1px solid $color-license-plate-blue;
        background: rgba($color-license-plate-blue, 0.1);
        padding: 0.75rem 1rem;
        margin-bottom: 1.25rem;
    }

    &__variant {
        margin-top: 1.125rem;
    }

    &__included-products {
        margin-block-start: 2rem;
        padding-block-start: 2rem;
        border-block-start: 1px solid $border-color;

        > div {
            --max-cols-size: 8rem;

            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(var(--max-cols-size), 1fr));
            gap: #{fluid(16px, 24px)};

            @include media-breakpoint-up(md) {
                --max-cols-size: 12rem;
            }
        }

        figure {
            margin: 0;

            > img {
                inline-size: 100%;
                height: auto;
                aspect-ratio: 3 / 2;
                object-fit: contain;
                background-color: $color-gallery-gray;
            }

            > figcaption {
                font-weight: 500;
                margin-block-start: 1rem;
            }
        }
    }

    &__collapse {
        position: relative;

        &::after {
            position: absolute;
            inset: 0;
            content: "";
            background-image: linear-gradient(180deg, #fff0 50%, #fff 100%);
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s;
            pointer-events: none;
        }

        > :last-child {
            margin-block-end: 1.5rem;
        }

        &.collapse:not(.show),
        &.collapsing {
            display: block;
            block-size: 30rem;
            overflow: hidden;

            &::after {
                opacity: 1;
            }
        }
    }

    &__collapse-toggle {
        --flow-spacer: 0;

        display: flex;
        align-items: center;

        &::before,
        &::after {
            content: "";
            flex-grow: 1;
            height: 1px;
            background-color: #ddd;
        }

        .open,
        .close {
            --icon-size: 0.75rem;

            display: flex;
            align-items: center;
            gap: 0.5rem;

            &::after {
                content: "";
                inline-size: var(--icon-size);
                block-size: var(--icon-size);
                mask-image: var(--icon);
                mask-size: var(--icon-size);
                mask-repeat: no-repeat;
                mask-position: center;
                background-color: currentcolor;
            }

            @include media-breakpoint-up(md) {
                --icon-size: 1rem;
            }
        }

        .open {
            --icon: var(--plus);
        }

        .close {
            --icon: var(--dash);
        }

        > [aria-expanded="false"] {
            > .close {
                display: none;
            }
        }

        > [aria-expanded="true"] {
            > .open {
                display: none;
            }
        }
    }

    &__related {
        margin-top: 3.75rem;
    }

    &__feature-slider {
        padding-block: var(--padding, 3.5rem);
        background-color: $color-gallery-gray;

        @include media-breakpoint-up(md) {
            --padding: 5rem;
        }

        @include media-breakpoint-up(lg) {
            --padding: 6.25rem;
        }

        @include media-breakpoint-up(xl) {
            --padding: 7.5rem;
        }
    }

    .input-select__select[disabled] {
        background-color: $color-gallery-gray;
    }

    &__documents {
        --flow-spacer: 1.125rem;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__documents-item {
        display: flex;
        align-items: center;
        font-weight: $font-weight-medium;
        color: $font-color-body;
        font-size: 14px;
        line-height: 21px;
        gap: 0.75rem;
        text-transform: uppercase;

        .icon {
            position: relative;
            flex: 0 0 16px;
        }

        &:hover {
            color: $color-ribbon-red;
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 96px;

        &__specifications {
            margin-top: 3rem;
            padding-top: 3rem;
        }

        &__included-products {
            margin-block-start: 3rem;
            padding-block-start: 3rem;
        }

        &__section {
            margin-top: 2.75rem;
            margin-bottom: 2.75rem;
        }

        &__usps-item {
            margin-bottom: 0.625rem;

            .icon {
                top: 2.5px;
            }
        }

        &__variant {
            margin-top: 1.5rem;
        }

        &__contact {
            margin-top: 1.75rem;
            padding: 1.25rem 1.5rem;
        }

        &__message {
            padding: 1.25rem 1.5rem;
            margin-bottom: 1.5rem;
        }
    }

    @include media-breakpoint-up(lg) {
        &__related {
            margin-top: 6rem;
        }

        &__carousel {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 120px;

        &__title {
            margin-bottom: 2.5rem;
        }

        &__description {
            margin-bottom: 1.25rem;
        }

        // &__carousel {
        //     margin-right: 2.5rem;
        // }
    }
}
