.list-text-block {
    margin-bottom: 40px;

    &__label {
        @include text-heading-5;

        margin-bottom: 1.125rem;
        color: rgba($color-mine-shaft-gray, 0.4);
    }

    &__title {
        margin-bottom: 20px;
    }

    &__link {
        margin-top: 16px;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 50px;
    }

    @include media-breakpoint-up(lg) {
        &__link {
            margin-top: 24px;

            &.button--secondary {
                padding: 16px 24px;
                font-size: 14px;
            }
        }
    }
}
