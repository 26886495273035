.accessories-overview-form {
    // TODO: remove this template after e-commerce optimalisations
    margin-top: 40px;
    margin-bottom: 40px;

    &__sorting {
        margin-bottom: 30px;

        .form-group {
            display: flex;
            margin-bottom: 0;

            .input-label {
                margin-bottom: 0;
                align-self: center;
                margin-right: 16px;
                white-space: nowrap;
            }
        }
    }

    &__list {
        margin-bottom: 16px;

        &.empty {
            min-height: 340px;
        }

        > [class*="col-"] {
            margin-bottom: 16px;
        }
    }

    &__reset {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        margin-bottom: 14px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__categories {
        font-weight: $font-weight-medium;
        margin-bottom: 20px;

        li {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
        }

        .active {
            color: $color-ribbon-red;
        }

        a:not(.active):hover {
            text-decoration: underline;
        }
    }

    &__filter-button {
        position: relative;
        width: 100%;
        margin-bottom: 18px;
        background: $color-mine-shaft-gray;
        color: $color-white;
        line-height: 20px;
        justify-content: flex-start;
        padding: 10px 16px;
        border: none;

        svg {
            position: absolute;
            top: 12px;
            right: 12px;
            height: 16px;
            width: auto;
            fill: currentcolor;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 40px;
            bottom: 0;
            background: rgba($color-alto-gray, 0.2);
            width: 1px;
        }
    }

    &__no-results {
        text-align: center;
        flex: 0 1 560px;
        align-self: center;
        margin: 0 auto;
    }

    .modal-title {
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        margin-bottom: 3px;
    }

    &__modal-section {
        margin-top: 40px;
    }

    // Use custom grid width
    @include media-breakpoint-down(md) {
        &__list > [class*="col-"] {
            &:nth-child(odd) {
                padding-right: 8px;
            }

            &:nth-child(even) {
                padding-left: 8px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__sorting {
            .input-select {
                &::before {
                    right: 40px;
                }

                &__select {
                    padding: 10px 56px 10px 16px;
                    background-position: right 14px center;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-top: 80px;
        margin-bottom: 80px;

        &__sorting {
            margin-bottom: 36px;
        }

        &__list {
            padding: 0 7px;

            &.empty {
                min-height: 500px;
            }
        }

        // Custom gutters
        &__list > [class*="col-"] {
            padding-right: 12px;
            padding-left: 12px;
            margin-bottom: 24px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__sorting {
            .input-select {
                &::before {
                    right: 40px;
                }

                &__select {
                    padding: 10px 56px 10px 16px;
                    background-position: right 14px center;
                }
            }
        }

        &__filters {
            .input-select {
                &::before {
                    right: 48px;
                }

                &__select {
                    padding: 14px 72px 14px 24px;
                    background-position: right 18px center;
                }
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 120px;
        margin-bottom: 120px;
    }
}

// Modal styling
#modal-accessory-filter {
    .modal-dialog {
        margin: 0 0 0 auto;
        min-height: 100%;
    }

    .accessories-overview-form {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        height: 100%;

        > * {
            flex: 0 0 auto;
        }

        &__close-modal {
            width: auto;
            border-top: 1px solid $color-alto-gray;
            padding: 16px 24px 0;
            margin: auto -24px 0;

            .button {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .accessories-overview-form__close-modal {
            padding: 16px 60px 0;
            margin: auto -60px 0;
        }
    }
}
