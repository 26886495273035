.article-highlight-item {
    position: relative;
    border: 1px solid $color-alto-gray;
    background-color: $white;
    height: 100%;
    transition: all $animation-speed;
    word-break: break-word;

    &:hover {
        border-color: $color-geyser-gray;
        box-shadow: 0 30px 40px 0 rgb(0 0 0 / 5%);

        .article-highlight-item__image img {
            transform: scale(1.1);
        }
    }

    &__title {
        font-weight: $font-weight-medium;
        text-transform: none;
        margin-bottom: 10px;
    }

    &__link {
        display: block;
    }

    &__image {
        margin-bottom: 16px;

        &,
        img {
            width: 100%;
            height: 100%;
            transition: transform $animation-speed;
        }
    }

    &__arrow {
        position: absolute;
        bottom: 8px;
        right: 8px;
        height: 32px;
        width: 32px;

        svg {
            display: block;

            .g-circle {
                fill: $color-gallery-gray;
            }

            .g-arrow {
                fill: $color-mine-shaft-gray;
            }
        }
    }

    &__promotion {
        position: absolute;
        left: 8px;
        top: 8px;
    }

    &__content {
        padding: 0 16px 44px;
    }

    &__date {
        @include text-small;

        margin-bottom: 8px;

        .icon {
            position: relative;
            top: 5px;
        }
    }

    @include media-breakpoint-down(sm) {
        &.is-2-item {
            margin-bottom: 20px;
            height: auto;
        }
    }

    @include media-breakpoint-up(md) {
        &__content {
            padding: 0 40px 40px;
        }

        &__image {
            margin-bottom: 32px;
        }

        &__promotion {
            left: 16px;
            top: 16px;
        }

        &.is-1-item &__link {
            display: flex;
        }

        &.is-1-item &__image {
            flex: 0 0 400px;
            align-self: stretch;
            height: auto;
            margin-bottom: 0;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        &.is-1-item &__content {
            align-self: center;
            padding: 20px 40px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__promotion {
            left: 24px;
            top: 24px;
        }

        &.is-1-item &__image {
            flex: 0 0 640px;
        }

        &__content {
            padding-bottom: 64px;
        }

        &__arrow {
            height: 40px;
            width: 40px;
            bottom: 16px;
            right: 16px;
        }
    }

    @include media-breakpoint-up(xxl) {
        &.is-1-item &__image {
            flex: 0 0 770px;
        }

        &.is-1-item &__content {
            padding: 20px 80px;
        }
    }
}
