// Default button
.button {
    display: inline-flex;
    text-transform: uppercase;
    padding: 8px 24px;
    align-items: center;
    cursor: pointer;
    color: $color-white;
    font-weight: $font-weight-medium;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1;
    text-decoration: none;
    justify-content: center;

    &.disabled,
    &:disabled {
        cursor: not-allowed;
    }

    &:focus-visible {
        box-shadow: 0 0 0 0.2rem rgb(50 50 50 / 25%);
    }

    @include media-breakpoint-up(md) {
        padding: 12px 28px;
        font-size: 14px;
    }

    @include media-breakpoint-up(xl) {
        padding: 20px 36px;
        font-size: 16px;
    }
}

.button--primary {
    border: 2px solid transparent;
    background: $color-mine-shaft-gray;
    line-height: 20px;

    &:hover {
        background: $color-dove-gray;
    }

    &.disabled,
    &:disabled {
        background: $color-alto-gray;
        color: $color-hit-gray;
        cursor: not-allowed;
    }
}

.button--white {
    background: $color-gallery-gray;
    border-color: $color-gallery-gray;
    color: $color-mine-shaft-gray;

    &:hover {
        background: $white;
        border-color: $white;
    }
}

// Secondary
.button--secondary {
    border: 2px solid $color-mine-shaft-gray;
    color: $color-mine-shaft-gray;
    line-height: 20px;
    background: transparent;

    .icon {
        fill: currentcolor;
    }

    &:hover,
    &--active {
        background: transparent;
        color: $color-dove-gray;
        border-color: $color-dove-gray;
    }

    &--active:hover {
        box-shadow: none;
        cursor: default;
    }

    &.disabled,
    &:disabled {
        background: $color-alto-gray;
    }
}

.button--primary > .icon,
.button--secondary > .icon {
    flex: 0 0 auto;
    height: 10px;
    width: auto;
    margin-left: 10px;

    @include media-breakpoint-up(md) {
        height: 12px;
        margin-left: 15px;
    }

    @include media-breakpoint-up(xl) {
        height: 13px;
        margin-left: 15px;
    }
}

// Gray
.button--gray {
    background: $color-porcelain-gray;
    color: $color-mine-shaft-gray;

    &:hover,
    &--active {
        background: $color-alto-gray;
    }

    &--active:hover {
        box-shadow: none;
        cursor: default;
    }

    &.disabled,
    &:disabled {
        background: $color-alto-gray;
    }
}

.button-link {
    background-color: transparent;
    border: none;
    padding: 0;
    color: $color-ribbon-red;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

// Small
.button--small {
    font-size: 10px;
    line-height: 20px;
    padding: 4px 16px;

    @include media-breakpoint-up(md) {
        font-size: 12px;
        padding: 14px 20px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 14px;
        padding: 15px 24px;
    }
}

.button--medium {
    @include media-breakpoint-up(xl) {
        padding: 12px 28px;
        font-size: 14px;
    }
}

.button--full {
    width: 100%;
}

.button--full-small {
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

// Text
.button--text {
    background: none;
    color: $color-mine-shaft-gray;
    padding: 0;

    svg {
        width: 20px;
        margin-right: 6px;
    }

    [class*="g-circle"] {
        fill: $color-mine-shaft-gray;
    }

    &:hover {
        background: none;
        box-shadow: none;
        color: $color-dove-gray;

        [class*="g-circle"] {
            fill: $color-dove-gray;
        }
    }

    &[disabled] {
        background: none;
        color: $color-submarine-gray;

        [class*="g-circle"] {
            fill: $color-alto-gray;
        }

        [class*="g-arrow"] {
            fill: rgba($color-mine-shaft-gray, 0.3);
        }
    }

    &-dark {
        color: $color-mine-shaft-gray;

        [class*="g-circle"] {
            fill: $color-mine-shaft-gray;
        }
    }
}

// Previous / next
.button--prev,
.button--next {
    width: 48px;
    height: 48px;
    background: $color-porcelain-gray;
    border-radius: 50%;
    padding: 0;
    justify-content: center;

    &:hover {
        background: $color-silver-gray;
        box-shadow: none;
    }

    &[disabled] {
        opacity: 0.4;
        background: $color-porcelain-gray;
    }

    svg {
        width: 17px;
    }
}

.button--prev {
    margin-right: 6px;

    svg {
        transform: scaleX(-1);
    }
}

.button--pill {
    border-radius: 50px;
    font-size: 10px;
    font-weight: $font-weight-heavy;
    padding: 10px 16px;

    @include media-breakpoint-up(md) {
        font-size: 12px;
    }

    &.is-gray {
        background-color: $color-gallery-gray;
        color: $font-color-body;
    }

    &.is-checked {
        background-color: $color-mine-shaft-gray;
        color: $color-white;
    }
}

.button--toggle {
    background-color: $color-porcelain-gray;
    color: black;

    &:not([disabled]):hover,
    &--active {
        background: $color-alto-gray;
    }

    &--active:hover {
        box-shadow: none;
        cursor: default;
    }

    &.open::before {
        content: "";
        position: relative;
        height: 16px;
        width: 16px;
        background-image: url("#{$static-path}/svg/plus-blue.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        margin-right: 10px;
    }

    &.close::before {
        background-image: url("#{$static-path}/svg/dash.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
    }
}

.button--card {
    height: 32px;
    width: 32px;

    svg {
        display: block;
        height: 100%;
        width: 100%;

        .g-circle {
            fill: $color-gallery-gray;
        }

        .g-arrow {
            fill: $color-mine-shaft-gray;
        }
    }

    @include media-breakpoint-up(lg) {
        height: 40px;
        width: 40px;
    }
}

.button--back {
    display: inline-flex;
    gap: 0.375rem;
    font-size: 12px;
    line-height: 20px;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    color: $font-color-body;
    padding: 0;
    z-index: 5;

    .icon {
        height: 1.25rem;
        width: 1.25rem;
        transform: rotate(180deg);
    }

    &:hover {
        color: $color-dove-gray;

        .g-circle {
            fill: $color-dove-gray;
        }
    }

    @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 20px;

        .icon {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}

.button--accent {
    &.button--primary {
        background: var(--accent-color);

        &:hover {
            background: var(--accent-color);
            opacity: 0.8;
        }
    }

    &.button--secondary {
        border-color: var(--accent-color);

        &:hover {
            border-color: var(--accent-color);
            opacity: 0.8;
        }
    }
}

.button-hard-shadow {
    inline-size: 100%;
    text-align: left;
    background-color: $color-white;
    border: 1px solid $color-alto-gray;
    font-family: inherit;
    font-size: inherit;
    box-shadow:
        0 8px 0 -5px $color-white,
        0 8px 0 -4px $color-alto-gray,
        0 16px 0 -9px $color-white,
        0 16px 0 -8px $color-alto-gray;
}
