.specifications-block {
    margin: 40px 0;

    &__tabs {
        justify-content: center;
    }

    &__title {
        text-align: center;
        display: inline-block;
    }

    .tooltip-toggler {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 4px;

        svg {
            display: block;
        }
    }

    .accordion__header {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .nav-item {
        flex: 1 0 auto;
        text-align: center;
        margin-right: 8px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    .nav-link {
        @include text-heading;

        font-size: 10px;
        line-height: 1;
        border-color: $color-alto-gray;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        background-color: $color-porcelain-gray;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 0;

        &.active {
            color: $color-mine-shaft-gray;
        }
    }

    .tab-content {
        text-align: center;
        margin-top: 16px;
    }

    .toggle-button {
        color: $color-mine-shaft-gray;

        &::before {
            content: "";
            position: relative;
            height: 16px;
            width: 16px;
            background-image: url("#{$static-path}/svg/plus-blue.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            margin-right: 10px;
        }

        &--faded::before {
            background-image: url("#{$static-path}/svg/dash.svg");
        }
    }

    // Only show the first few items on load
    .tab-pane__content {
        position: relative;
        overflow: hidden;
        transition: height $animation-speed;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 60%;
            background-image: linear-gradient(180deg, rgba($color-white, 0) 0%, $color-white 90%);
            transform: translateY(100%);
            transition: transform $animation-speed;
        }

        &.faded {
            max-height: 620px;

            &::after {
                transform: translateY(0);
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin: 80px 0;

        .nav-item {
            flex: 0 0 240px;
        }

        .nav-link {
            font-size: 14px;
            padding-top: 28px;
            padding-bottom: 28px;
        }

        .toggle-button {
            margin-top: 40px;

            &::before {
                height: 18px;
                width: 18px;
                background-size: 18px;
            }
        }

        .tab-pane__content.faded {
            max-height: 420px;
        }
    }

    @include media-breakpoint-up(xl) {
        .nav-link {
            font-size: 16px;
            padding-top: 32px;
            padding-bottom: 32px;
        }
    }

    @include media-breakpoint-up(xl) {
        margin: 110px 0;
    }
}
