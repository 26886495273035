.benefits-block {
    &__item {
        @include text-intro;

        color: $font-color-body;
        text-transform: none;
        font-weight: $font-weight-book;
        position: relative;
        padding-left: 2rem;

        .icon {
            position: absolute;
            left: 0;
            top: 0;
            width: 1.5rem;
            height: 1.5rem;
        }

        &:not(:last-child) {
            margin-bottom: 0.75rem;
        }

        &--small {
            font-size: 1rem;
            line-height: 1.5rem;
            padding-left: 1.5rem;

            .icon {
                top: 0.25rem;
                width: 1rem;
                height: 1rem;
            }

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__item {
            padding-left: 2.5rem;

            &--small {
                padding-left: 1.5rem;

                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }
            }

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__item {
            padding-left: 2.5rem;

            &--small {
                font-size: 1.125rem;
                line-height: 1.75rem;
                padding-left: 2rem;

                .icon {
                    top: 0.375rem;
                }

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }
        }

        .icon {
            top: 0.3125rem;
        }
    }
}
