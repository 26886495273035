.primary-nav .vehicle-segment-block {
    width: 100%;

    &:not(:first-child) {
        margin-top: 55px;
    }

    &__title {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 5px;
        margin-bottom: 8px;
    }

    &__action {
        display: flex;
        align-items: center;
        width: 100%;

        > hr {
            flex: 1 1 0;
            margin: 4px 0;
        }
    }

    &__title-text {
        font-weight: $font-weight-book;
        margin: 0;
    }

    &__title-link {
        flex: 0 0 auto;
        font-size: 12px;
        line-height: 1;
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        margin-left: auto;
        margin-bottom: 0;

        // Mobile positioning
        position: absolute;
        top: 5px;
        right: 0;

        .icon {
            position: relative;
            width: 12px;
            height: 12px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__action {
            hr {
                margin: 10px 10px 10px 0;
            }
        }

        &__title-link {
            position: relative;
            top: auto;
            right: auto;
        }
    }

    @include media-breakpoint-up(xl) {
        &__title-link {
            font-size: 16px;
        }
    }
}
