.dealer-intro-block {
    margin: 64px auto;

    &__title {
        margin-bottom: 10px;
    }

    &__dealer-wrap {
        display: flex;
        border-bottom: 1px solid $color-alto-gray;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    &__review {
        text-transform: initial;
        margin-top: 5px;
        text-decoration: underline;
    }

    &__image {
        flex: 0 0 160px;
        margin-right: 20px;

        img {
            height: auto;
        }
    }

    &__dealer-info {
        flex: 1 0 0;

        a:hover {
            .dealer-intro-block__review {
                text-decoration: none;
            }
        }
    }

    &__checklist {
        @include text-small;

        margin: 20px 0 0;
        font-weight: $font-weight-book;

        &-item {
            margin-bottom: 8px;
            display: flex;
            align-items: flex-start;
        }

        .icon {
            flex: 0 0 16px;
            height: 16px;
            margin-right: 8px;
            margin-top: 3px;
        }
    }

    @include media-breakpoint-up(md) {
        &__inner {
            display: flex;
        }

        &__dealer-wrap {
            @include text-small;

            display: block;
            flex: 0 0 180px;
            border-bottom: 0;
            border-right: 1px solid $color-alto-gray;
            padding-right: 20px;
            margin-bottom: 0;
            margin-right: 20px;
        }

        &__image {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    @include media-breakpoint-up(lg) {
        margin: 80px auto;
    }

    @include media-breakpoint-up(xl) {
        margin: 120px auto;

        &__title {
            margin-bottom: 16px;
        }

        &__checklist {
            display: flex;
            flex-wrap: wrap;

            &-item {
                flex: 0 0 50%;
            }

            &-item:nth-child(odd) {
                padding-right: 16px;
            }
        }

        &__dealer-wrap {
            flex: 0 0 200px;
        }

        &__review {
            font-size: 16px;
            margin-top: 8px;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__dealer-wrap {
            flex: 0 0 240px;
            margin-right: 40px;
            padding-right: 40px;
        }
    }
}
