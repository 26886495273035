.article-card {
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    word-break: break-word;

    &__link {
        display: block;
        background-color: $color-white;
        border: 1px solid $color-alto-gray;
        border-radius: 2px;
        height: 100%;
        transition:
            box-shadow $animation-speed,
            border-color $animation-speed;

        &:hover {
            border-color: $color-geyser-gray;
            box-shadow: 0 30px 40px 0 rgb(0 0 0 / 5%);

            .article-card__image img {
                transform: scale(1.1);
            }
        }
    }

    &__image {
        clip-path: polygon(90% 0, 100% 100%, 0 100%, 0 0);
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;

        img {
            height: auto;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            transition: transform $animation-speed;
        }
    }

    &__header {
        @include text-small;

        margin-bottom: 10px;

        span {
            display: inline-block;
            vertical-align: middle;
        }

        .tooltip-toggler {
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;

            .icon--tooltip {
                display: block;
                width: inherit;
                height: inherit;
            }
        }
    }

    &__promotion {
        display: inline-block;
        position: absolute;
        top: 16px;
        left: 16px;
        max-width: 75%;
    }

    &__content {
        padding: 26px 26px 48px;
    }

    &__title {
        @include text-heading-4;

        font-weight: $font-weight-medium;
        text-transform: none;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 10px;
    }

    &__text {
        @include text-body;
    }

    &__arrow {
        position: absolute;
        bottom: 8px;
        right: 10px;
        height: 32px;
        width: 32px;

        svg {
            display: block;

            .g-circle {
                fill: $color-gallery-gray;
            }

            .g-arrow {
                fill: $color-mine-shaft-gray;
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: 8px;
        margin-bottom: 8px;

        &__content {
            padding: 24px 24px 48px;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-top: 20px;
        margin-bottom: 20px;

        &__content {
            padding: 30px 30px 72px;
        }

        &__arrow {
            width: 40px;
            height: 40px;
            bottom: 16px;
            right: 16px;
        }
    }

    @include media-breakpoint-up(xl) {
        margin-top: 24px;
        margin-bottom: 24px;

        &__content {
            padding: 32px 32px 72px;
        }
    }
}
