.product-teaser-item {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid $color-alto-gray;
    border-radius: 2px;
    height: 100%;
    transition: all $animation-speed;
    word-break: break-word;

    &:hover {
        border-color: #dce1e4;
        box-shadow: 0 30px 40px 0 rgb(0 0 0 / 5%);

        .related-products-item__image img {
            transform: scale(1.1);
        }
    }

    &__title {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 10px;
    }

    &__link {
        display: block;
    }

    &__image {
        flex: 0 0 auto;
        margin-bottom: 16px;

        &,
        img {
            width: 100%;
            height: auto;
            transition: transform $animation-speed;
        }
    }

    &__label {
        position: absolute;
        top: 12px;
        left: 12px;
    }

    &__stock {
        margin-bottom: 30px;

        span {
            display: inline;
            font-size: 16px;
            line-height: 24px;
        }

        .icon {
            height: 20px;
            width: 20px;
            margin-top: -4px;
            margin-right: 5px;
        }

        &-available {
            color: $color-limeade-green;
        }

        &-unavailable {
            color: $color-ribbon-red;
        }
    }

    &__content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0 20px 20px;
    }

    &__price {
        margin-top: auto;

        h4 {
            font-size: 18px;
            line-height: 21px;
            color: inherit;
            margin-bottom: 0;
        }

        &-normal {
            display: block;
            color: $color-mine-shaft-gray;
        }

        &-discount {
            display: inline-flex;
            align-items: center;
            color: $color-ribbon-red;

            .strikethrough {
                @include text-small;

                display: block;
                font-weight: $font-weight-book;
                color: $color-trout-gray;
                text-decoration: line-through;
                margin-left: 8px;
                margin-right: 8px;
            }

            .tooltip-toggler {
                .icon {
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__title {
            font-size: 22px;
            line-height: 25px;
            margin-bottom: 8px;
        }

        &__content {
            padding: 0 20px 30px;
        }

        &__image {
            margin-bottom: 32px;
        }

        &__label {
            position: absolute;
            top: 18px;
            left: 18px;
        }

        &__stock {
            margin-bottom: 50px;

            span {
                font-size: 18px;
                line-height: 27px;
            }
        }

        &__price {
            h4 {
                font-size: 27px;
                line-height: 31px;
            }

            .strikethrough {
                font-size: 18px;
                line-height: 27px;
            }
        }

        // Add alternate styling for 3 items or more...
        &:not(.is-1-item),
        &:not(.is-2-item) {
            .related-products-item__title {
                font-size: 27px;
                line-height: 31px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__title {
            font-size: 27px;
            line-height: 31px;
            margin-bottom: 15px;
        }

        &__stock {
            margin-bottom: 60px;
        }

        &__content {
            padding: 0 45px 35px;
        }
    }
}
