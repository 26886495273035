.dealer-search {
    padding: 0 1rem 1.5rem;

    &__legend {
        display: flex;
        gap: 1rem;
        margin-top: 1.5rem;
    }

    &__filter {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-bottom: 1rem;

        > span {
            font-size: 16px;
        }

        .form-check-inline {
            margin-right: 0;
        }

        .input-radio__label {
            padding-left: 1.75rem;
        }

        .input-radio__label::before,
        .input-radio__label::after {
            width: 20px;
            height: 20px;
        }

        .input-radio__input:checked + .input-radio__label {
            &::after {
                width: 14px;
                height: 14px;
                left: 3px;
            }
        }
    }

    &__footer {
        margin-top: 1rem;

        &-indicator {
            @include text-small;

            font-weight: $font-weight-medium;
            display: flex;
            align-items: center;
            color: $color-mine-shaft-gray;
            text-transform: uppercase;

            .icon {
                width: 15px;
                height: 15px;
            }

            &.success .icon path {
                fill: green;
            }

            &.fail .icon path {
                stroke: $color-ribbon-red;
            }
        }

        &-indicator-icon {
            margin-right: 10px;
        }
    }

    &__input {
        position: relative;

        .input-text {
            padding-right: 65px;
            text-overflow: ellipsis;
        }
    }

    &__btn-submit,
    &__btn-clear {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &__btn-clear {
        right: 40px;
        transform: translateY(-50%) scale(0);
        transition: transform $animation-speed-lg;

        &.active {
            transform: translateY(-50%) scale(1);
        }

        svg path {
            fill: grey;
        }
    }

    .icon-spinner {
        width: 13px;
        height: 13px;
        margin-right: 11px;
        animation: spin 1s linear 0s infinite;
        margin-left: 5px;
    }

    @include media-breakpoint-up(xl) {
        padding: 0 1.5rem 1.5rem;

        &__btn-submit,
        &__btn-clear {
            svg {
                width: 20px;
                height: 20px;
            }
        }

        &__input {
            .input-text {
                font-size: 16px;
                padding: 12px 24px;
                height: 48px;
            }
        }
    }
}
