@use "sass:color";

.specifications-table {
    .react-tabs {
        &__tab-list {
            border: none;
            margin: -6px;
        }

        &__tab {
            background-color: $color-gallery-gray;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            border: 1px solid $color-alto-gray;
            margin: 6px;
            text-transform: uppercase;
            font-weight: $font-weight-heavy;
            font-size: 10px;
            padding: 24px 12px;
            color: $color-mine-shaft-gray;

            @include media-breakpoint-up(sm) {
                padding: 24px 32px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 16px;
            }

            &:not(.react-tabs__tab--selected):hover {
                background-color: color.scale($color-porcelain-gray, $lightness: 10%);
            }

            &--selected {
                background-color: $white;
                border-bottom-color: $white;
            }

            &--light {
                font-weight: $font-weight-medium;
                text-transform: none;
            }
        }

        &__tab-panel {
            border-top: 1px solid $color-alto-gray;
            padding-top: 50px;
            background-color: $white;
        }
    }
}
