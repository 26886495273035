.adyen-checkout {
    &__payment-methods-list {
        display: grid;
        gap: 1.5rem;
    }

    &__payment-method {
        $self: &;

        --padding: 1rem;

        display: grid;
        gap: 1rem;
        padding: var(--padding);
        border: 1px solid $color-alto-gray;
        max-block-size: fit-content;

        @include media-breakpoint-up(md) {
            --padding: 2rem;
        }

        &,
        &--selected + &,
        &:first-child,
        &:last-child {
            border-radius: 0;
            margin-block: 0;
        }

        &--selected {
            background-color: $color-white;
            outline: 2px solid $color-limeade-green;
            outline-offset: -1px;
        }

        &__header {
            padding: 0;

            &__title {
                display: grid;
                grid-template-columns: max-content 1fr max-content;
                gap: 0.5rem;
                align-items: center;
                inline-size: 100%;
                margin: 0;
                padding: 0;

                #{$self}__image__wrapper {
                    order: 2;

                    &::after {
                        content: normal;
                    }
                }
            }
        }

        &__details__content {
            margin-block-end: 0;
        }

        &__radio {
            all: unset;
            display: grid;
            place-items: center;

            &::before,
            &::after {
                all: unset;
                content: "";
                grid-column: 1;
                grid-row: 1;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $color-gallery-gray;
            }

            &::before {
                border: 1px solid $form-border-color;
            }

            &--selected {
                &::after {
                    background-color: $color-mine-shaft-gray;
                    width: 14px;
                    height: 14px;
                }
            }

            &:is(:hover) {
                box-shadow: none;
            }

            @include media-breakpoint-up(xl) {
                &::before,
                &::after {
                    width: 28px;
                    height: 28px;
                }

                &--selected {
                    &::after {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        &__image {
            &__wrapper {
                margin: 0 !important;

                &,
                img {
                    block-size: 2.125rem;
                    inline-size: 3.125rem;
                    right: -0.625rem;
                }
            }
        }

        &:hover:not(#{$self}--selected) #{$self}__radio {
            box-shadow: none;
        }
    }

    &__payment-method__details {
        padding-inline: 0;
    }

    &__dropdown {
        &__button {
            border-radius: 0;
            block-size: 48px;
            border-color: $color-alto-gray;
        }
    }

    &__input {
        @extend %input-text;

        &:focus,
        &--focus {
            &,
            &:hover,
            &:active {
                border-color: transparent;
                box-shadow: 0 0 0 0.2rem rgba($secondary, 0.25);
            }
        }
    }

    &-input__inline-validation {
        block-size: auto;
    }

    &__label {
        $self: &;

        &__text {
            @extend %input-label;

            padding-block: 0;

            #{$self}--focused & {
                color: $color-mine-shaft-gray;
            }
        }
    }

    &__card__brands {
        &,
        &__brand-wrapper {
            block-size: auto;
        }
    }
}
