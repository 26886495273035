.hero-column-block {
    position: relative;
    overflow: hidden;
    margin-bottom: -1px;

    @include media-breakpoint-up(md) {
        &.has-3-items {
            display: flex;
            flex-wrap: wrap;
        }
    }

    @include media-breakpoint-up(lg) {
        &.has-2-items {
            display: flex;
        }

        &.has-3-items {
            display: grid;
            grid-template-areas:
                "left right-top"
                "left right-bottom";
        }
    }
}
