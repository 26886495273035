.text-block:not(.text-small) .rich-text {
    @include text-intro;

    ul li {
        background-position-y: 10px;
    }

    @include media-breakpoint-up(xl) {
        ul li {
            background-position-y: 12px;
        }
    }
}
