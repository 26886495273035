.article-highlight-block {
    --slide-width: 92%;

    &__slide {
        flex: 0 0 var(--slide-width);
        max-width: var(--slide-width);
    }

    &__more-link {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 30px;
    }

    .embla__container {
        gap: 1.25rem;
    }

    .embla__nav {
        display: none;
        position: relative;
        justify-content: center;
        transform: none;
        margin-top: 1rem;
        column-gap: 1rem;
    }

    .embla__prev,
    .embla__next {
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        --slide-width: 83%;

        .embla__container {
            gap: 1.875rem;
        }

        .embla__nav {
            display: flex;
            margin-top: 2rem;
        }

        &__more-link {
            margin-top: 60px;
        }

        &.has-slider &__more-link {
            position: absolute;
            bottom: 5px;
            right: 20px;
            margin-top: 0;
        }

        &.has-2-items &__row > .col-12 {
            &:first-child {
                padding-right: 10px;
            }

            &:last-child {
                padding-left: 10px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        --slide-width: 40%;

        .embla__container {
            gap: 2.5rem;
        }

        .embla__nav {
            margin-top: 3.75rem;
        }

        &.has-2-items &__row > .col-12 {
            &:first-child {
                padding-right: 20px;
            }

            &:last-child {
                padding-left: 20px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        &__more-link {
            margin-top: 80px;
        }
    }
}
