@import "~range-slider-sass/range";

$thumb-color: $color-mine-shaft-gray;
$thumb-height: 32px;
$thumb-border-width: 2px;
$thumb-border-color: $color-porcelain-gray;
$thumb-width: 32px;
$track-width: 100%;
$track-radius: 10px;
$track-height: 8px;
$track-border-width: 0;
$track-color: transparent;

@mixin thumb-stripes {
    background-image: url("#{$static-path}/svg/thumb-stripes.svg");
    background-repeat: no-repeat;
    background-position: center center;
}

.input-range {
    &-label {
        font-size: 14px;
        line-height: 16px;
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        flex: 0 1 auto;

        &-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;
        }
    }

    &-slider {
        @include input-type-range;

        border-radius: 4px;
        height: 8px;

        // these selectors can not be combined with commas
        &::-moz-range-thumb {
            @include thumb-stripes;
        }

        &::-webkit-slider-thumb {
            @include thumb-stripes;
        }

        @media all and (-ms-high-contrast: none) {
            background: transparent !important;

            &::-ms-thumb {
                @include thumb-stripes;
            }

            &::-ms-track {
                border-width: 12px !important;
            }

            &::-ms-fill-lower {
                background: $color-mine-shaft-gray;
                border-radius: 10px;
            }

            &::-ms-fill-upper {
                background: $color-porcelain-gray;
                border-radius: 10px;
            }

            &:focus::-ms-fill-lower {
                background: $color-mine-shaft-gray;
            }

            &:focus::-ms-fill-upper {
                background: $color-porcelain-gray;
            }
        }
    }

    &-value {
        @include text-small;

        margin-bottom: 0;
    }

    &-pills {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;
        margin-top: 0.5rem;
    }

    &.reversed {
        display: flex;
        flex-direction: column;

        .input-range-label-container {
            order: 1;
        }

        .input-range-slider {
            margin: 10px 0 20px;
        }
    }

    @include media-breakpoint-down(sm) {
        .pill-full-width & .button--pill {
            width: 100%;
            margin-left: 0 !important; // overwrite bootstrap
            margin-right: 0 !important; // overwrite bootstrap
        }
    }

    @include media-breakpoint-up(xl) {
        .input-range {
            &-label {
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
}
