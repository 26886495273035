.page-order-completed {
    .success,
    .failure {
        display: none;
    }

    &[data-payment-status="success"] {
        .success {
            display: block;
        }
    }
}

.order-complete-detail-block {
    margin-top: 48px;

    @include media-breakpoint-up(sm) {
        margin-bottom: 48px;
    }

    @include media-breakpoint-up(md) {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 120px;
        margin-bottom: 120px;
    }

    > .row {
        row-gap: 2rem;
    }

    .button--primary {
        --size: 1rem;

        gap: 1rem;

        &::after {
            content: "";
            width: var(--size);
            height: var(--size);
            mask-image: var(--button-arrow);
            mask-size: var(--size);
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: currentcolor;
        }
    }

    @include media-breakpoint-down(sm) {
        .Checkout-overview {
            margin-inline: 0;
        }
    }
}

.order-confirm-list {
    display: grid;
    gap: 2rem;

    > li {
        --icon-background-size: 3rem;
        --icon-size: 1.5rem;

        display: grid;
        grid-template-columns: var(--icon-background-size) 1fr;
        column-gap: 1rem;
        align-items: flex-start;

        @include media-breakpoint-up(lg) {
            --icon-background-size: 3.5rem;
            --icon-size: 1.75rem;
        }

        @include media-breakpoint-up(xl) {
            --icon-background-size: 4rem;
            --icon-size: 2rem;
        }

        > * {
            grid-column: 2;
            grid-row: 1;
        }

        &[data-type] {
            &::before,
            &::after {
                grid-column: 1;
                grid-row: 1;
                content: "";
            }

            &::before {
                inline-size: var(--icon-background-size);
                block-size: var(--icon-background-size);
                mask-image: var(--icon);
                mask-size: var(--icon-size);
                mask-repeat: no-repeat;
                mask-position: center;
                background-color: $color-white;
            }

            &::after {
                background-color: $color-mine-shaft-gray;
                border-radius: 50%;
                width: 100%;
                aspect-ratio: 1;
            }
        }

        &[data-type="email"],
        &[data-type="delivery-email"] {
            --icon: var(--mail);
            --icon-size: 1.125rem;

            @include media-breakpoint-up(lg) {
                --icon-size: 1.5rem;
            }

            @include media-breakpoint-up(xl) {
                --icon-size: 1.5rem;
            }
        }

        &[data-type="delivery"] {
            --icon: var(--delivery);

            display: none;

            [data-delivery="home"]:not([data-dealer-required="true"]) & {
                display: grid;
            }
        }

        &[data-type="delivery-email"] {
            display: none;

            :is([data-delivery="dealer"], [data-dealer-required="true"]) & {
                display: grid;
            }
        }

        &[data-type="questions"] {
            --icon: var(--questions);
        }

        &[data-type="rhino"] {
            --icon: var(--rhino);

            padding-block: 1.5rem;
            border-block: 1px solid $color-alto-gray;

            &::before {
                background-color: $color-mine-shaft-gray;
            }

            &::after {
                background-color: transparent;
                border: 1px solid $color-alto-gray;
            }
        }
    }
}
