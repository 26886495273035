.text-with-link-block {
    border: 1px solid $color-alto-gray;
    padding: 7px 16px;
    display: flex;
    margin-bottom: 20px;

    &__button {
        margin-left: auto;
    }

    &__image {
        margin-top: 8px;
        margin-bottom: 16px;

        img {
            filter: brightness(90%);
        }
    }

    @include media-breakpoint-up(md) {
        padding: 11px 24px;

        &__image {
            flex: 0 0 150px;
            margin-right: 20px;
            margin-bottom: 8px;
        }
    }

    @include media-breakpoint-up(xl) {
        padding: 18px 24px 17px;
    }
}
