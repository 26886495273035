.Form {
    $self: &;

    display: grid;
    gap: 2rem;

    h3 {
        @include text-heading-5;
        @include text-heading-medium;

        text-transform: none;
        grid-column: 1 / -1;
        margin: 0;
    }

    &-input {
        &[aria-invalid="true"] {
            border-color: $color-ribbon-red;
            background-color: rgba($color-ribbon-red, 0.05);
        }

        &:focus {
            outline: none;
        }

        + label {
            order: -1;
            margin: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        > #{$self}-controls,
        > #{$self}-control {
            max-inline-size: 75%;
        }
    }

    &-controls {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 1.5rem 1rem;

        > * {
            grid-column: 1 / -1;
        }
    }

    &-control {
        &:not([data-name="differentAddress"], [data-name="shippingMethod"]) {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        &:is([data-name="differentAddress"], [data-name="shippingMethod"]) {
            max-inline-size: 100%;
        }
    }

    [data-name="shippingMethod"] {
        legend {
            display: none;
        }
    }

    &-error {
        &[role="alert"] {
            @include text-small;
        }

        &,
        &[role="alert"],
        label {
            color: $color-ribbon-red;
        }
    }

    fieldset {
        padding: 0;
        border: 0;
    }

    &-options {
        display: grid;
        gap: 1.5rem;
    }

    &-option {
        --padding: 1rem;
        --radio-size: 20px;

        position: relative;
        padding: var(--padding);
        border: 1px solid $color-alto-gray;

        @include media-breakpoint-up(md) {
            --padding: 2rem;
        }

        > input {
            position: absolute;
            clip: rect(0 0 0 0);
        }

        > * {
            grid-row: 1;
            grid-column: 1;
        }

        > div > label {
            display: grid;
            grid-template-columns: max-content 1fr;
            column-gap: 0.5rem;
            row-gap: 0.25rem;
            align-items: flex-start;
            color: $color-mine-shaft-gray;
            font-weight: $font-weight-medium;
            cursor: pointer;

            &::before,
            &::after {
                content: "";
                grid-area: 1 / 1;
                width: var(--radio-size);
                height: var(--radio-size);
                border-radius: 50%;
                background-color: $color-gallery-gray;
            }

            &::before {
                border: 1px solid $form-border-color;
            }

            > strong,
            > small {
                grid-column: 2;
            }

            > small {
                @include text-small;
            }

            > .Money {
                grid-column: 2;
                align-self: center;

                @include media-breakpoint-up(md) {
                    grid-column: 3;
                    grid-row: 1 / span 2;
                }
            }

            &.dealer {
                > :last-child {
                    background-color: $color-ribbon-red;
                    mask-image: var(--suzuki-emblem);
                    width: 2rem;
                    aspect-ratio: 1;
                    margin-inline-start: 1.5rem;
                    grid-column: 3;
                    grid-row: 1 / span 3;

                    @include media-breakpoint-up(md) {
                        align-self: center;
                        grid-column: 4;
                        grid-row: 1 / span 2;
                    }
                }
            }
        }

        input:checked + div {
            outline: 2px solid $color-limeade-green;
            outline-offset: calc(var(--padding) - 1px);

            > label {
                &::after {
                    background-color: $color-mine-shaft-gray;
                    width: 14px;
                    height: 14px;
                    margin: 3px;
                }
            }
        }

        input:focus-visible + div > label::before {
            box-shadow: 0 0 0 0.2rem rgb(50 50 50 / 25%);
        }

        @include media-breakpoint-up(xl) {
            --radio-size: 28px;

            div > label {
                font-size: 16px;
            }

            input:checked + div > label {
                &::after {
                    width: 20px;
                    height: 20px;
                    margin: 4px;
                }
            }
        }
    }

    &-subFields {
        padding-block-start: 1.25rem;

        > h3 {
            font-size: 0.75rem;
            text-transform: uppercase;
            margin-block-end: 0.5rem;
        }

        h3,
        #{$self}-switch {
            padding-inline-start: calc(var(--radio-size) + 0.5rem);
        }

        > #shipping-address {
            margin-block-start: 2rem;
            padding-block-start: 1.5rem;
            border-block-start: 1px solid $color-alto-gray;

            @include media-breakpoint-up(lg) {
                padding-inline-end: 20%;
            }
        }
    }

    &-actions {
        text-align: right;
        margin-block-end: 1rem;
    }

    // * {
    //     grid-column: 1 / -1;
    // }

    :where([data-name*="firstName"], [data-name*="lastNamePreposition"]) {
        grid-column: span 3;
    }

    :where([data-name*="houseNumber"], [data-name*="zipCode"], [data-name*="houseNumberAddition"]) {
        grid-column: span 2;
    }

    :where([data-name*="salutation"]) {
        > fieldset {
            display: inherit;
        }

        legend {
            font-size: 12px;
            line-height: 20px;
            text-transform: uppercase;
            font-weight: $font-weight-heavy;
            color: $color-mine-shaft-gray;
            display: block;
            margin-bottom: 6px;
        }

        #{$self}-options {
            display: flex;
            gap: 1rem;
        }

        #{$self}-option {
            padding: 0;
            border: none;

            label {
                align-items: center;
            }

            input:checked + div {
                outline: none;
            }
        }
    }

    .Form-isValidated {
        :where([data-name*="street"], [data-name*="city"]) {
            > input {
                background-image: var(--checkmark-green);
                background-size: 1rem 1rem;
                background-position: calc(100% - 1rem) 50%;
                background-repeat: no-repeat;
            }
        }
    }

    &-switch {
        --thumb-size: 1.25rem;
        --thumb: hsl(0deg 0% 100%);
        --thumb-highlight: hsl(0deg 0% 0% / 25%);
        --track-size: calc(var(--thumb-size) * 2);
        --track-padding: 3px;
        --track-inactive: hsl(80deg 0% 80%);
        --track-active: hsl(93deg 98% 32%);
        --thumb-clr: var(--thumb);
        --thumb-clr-highlight: var(--thumb-highlight);
        --track-clr-inactive: var(--track-inactive);
        --track-clr-active: var(--track-active);

        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        user-select: none;
        -webkit-tap-highlight-color: transparent;

        > input {
            --thumb-position: 0%;
            --thumb-transition-duration: 0.25s;

            box-sizing: content-box;
            display: grid;
            flex-shrink: 0;
            grid: [track] 1fr / [track] 1fr;
            align-items: center;
            padding: var(--track-padding);
            outline-offset: 5px;
            border: 0;
            border-radius: var(--track-size);
            background-color: var(--track-clr-inactive);
            transition: background-color 0.25s ease;
            inline-size: var(--track-size);
            block-size: var(--thumb-size);
            appearance: none;
            pointer-events: none;
            touch-action: pan-y;

            &::before {
                --highlight-size: 0;

                grid-area: track;
                border-radius: 50%;
                box-shadow: 0 0 0 var(--highlight-size) var(--thumb-clr-highlight);
                background-color: var(--thumb-clr);
                cursor: pointer;
                content: "";
                transform: translateX(var(--thumb-position));
                pointer-events: auto;
                inline-size: var(--thumb-size);
                block-size: var(--thumb-size);

                @media (prefers-reduced-motion: no-preference) {
                    transition:
                        transform var(--thumb-transition-duration) ease,
                        box-shadow 0.25s ease;
                }
            }

            &:focus-visible {
                outline: rgb(50 50 50 / 25%) auto 3px;
            }

            // positioned at the end of the track: track length - 100% (thumb width)
            &:checked {
                --thumb-position: calc(var(--track-size) - 100%);

                background-color: var(--track-clr-active);
            }

            &:disabled {
                --thumb-clr: transparent;

                cursor: not-allowed;

                &::before {
                    box-shadow: inset 0 0 0 2px hsl(0deg 0% 100% / 50%);
                    cursor: not-allowed;
                }
            }

            @media (hover: hover) and (pointer: fine) {
                &:not(:disabled):hover::before {
                    --highlight-size: 0.25rem;
                }
            }
        }
    }
}

.DealerChooser {
    display: grid;
    gap: 2rem;

    input[type="search"] {
        width: 100%;
        padding-block: 0.5rem;
        padding-inline: 1rem 3.5rem;
        border: 1px solid $color-silver;
        font-size: 1rem;
        color: $color-mine-shaft-gray;
        font-weight: $font-weight-medium;
        background-image: var(--search);
        background-repeat: no-repeat;
        background-position: center right 1rem;
        background-size: 1.25rem;

        &::placeholder {
            color: $color-mine-shaft-gray;
            opacity: 1;
        }

        &:focus {
            outline: none;
            border-color: transparent;
            box-shadow: 0 0 0 0.2rem rgba(50 50 50 / 25%);
        }

        @include media-breakpoint-up(md) {
            padding-block: 0.875rem;
            padding-inline-start: 1.5rem;
        }
    }

    [role="tablist"] {
        display: flex;
        border-bottom: 1px solid $color-alto-gray;
        gap: 0.5rem;

        button {
            @include text-small;

            padding: 0.5rem 3rem;
            border: 1px solid $color-alto-gray;
            margin-bottom: -1px;
            background-color: $color-gallery-gray;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: $font-weight-medium;
            border-radius: 2px 2px 0 0;

            &[aria-selected="true"] {
                border: 1px solid $color-alto-gray;
                border-bottom-color: $color-white;
                background-color: transparent;
            }

            @include media-breakpoint-down(sm) {
                flex: 1;
            }
        }
    }

    [role="tabpanel"] {
        display: none;
        align-items: center;
        gap: 1.5rem;

        &.selected {
            display: grid;
        }

        > button {
            --size: 1.25rem;

            display: grid;
            align-items: center;
            justify-self: center;
            grid-auto-columns: max-content;
            grid-auto-flow: column;
            gap: 0.5rem;
            font-size: 0.75rem;
            font-weight: 400;
            text-transform: uppercase;

            > * {
                grid-row: 1;
            }

            &::before,
            &::after {
                content: "";
                grid-column: 1;
                grid-row: 1;
                block-size: var(--size);
                inline-size: var(--size);
            }

            &::before {
                mask-image: var(--plus);
                mask-size: calc(var(--size) * 0.75);
                mask-repeat: no-repeat;
                mask-position: center;
                background-color: $color-white;
            }

            &::after {
                border-radius: 50%;
                background-color: $color-mine-shaft-gray;
            }

            @include media-breakpoint-up(md) {
                --size: 1.5rem;

                font-size: 1rem;
            }
        }

        &[aria-labelledby="tab-map"] {
            aspect-ratio: 3 / 4;

            @include media-breakpoint-up(md) {
                aspect-ratio: 2 / 1.5;
            }

            @include media-breakpoint-up(xxl) {
                aspect-ratio: 2 / 1;
            }
        }
    }

    ol {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-block-start: -2rem;
    }

    li {
        position: relative;
        padding-block: 1.5rem;
        border-bottom: 1px solid $color-alto-gray;

        input {
            position: absolute;
            clip: rect(0 0 0 0);
        }

        label {
            display: grid;
            grid-template-columns: max-content 1fr;
            row-gap: 0.875rem;
            column-gap: 1rem;
            padding-inline-end: 2.5rem;

            &::before {
                content: "";
                block-size: 3rem;
                inline-size: 2rem;
                background-image: var(--marker-red);
                background-position: top left;
                background-repeat: no-repeat;
                grid-column: 1;
                grid-row: 1 / span 2;
                align-self: start;
                inset-block-start: -3px;
                position: relative;
            }

            @include media-breakpoint-up(md) {
                grid-template-columns: max-content 1fr max-content;
                align-items: center;
                padding-inline-end: 0;

                > *,
                &::before {
                    grid-row: 1;
                }
            }
        }

        label > span {
            --size: 1rem;
            --icon: var(--button-arrow);

            display: grid;
            align-items: center;
            justify-content: center;
            grid-auto-flow: column;
            gap: 1rem;
            text-transform: uppercase;
            padding-block: 0.75rem;
            padding-inline: 1.5rem;
            cursor: pointer;
            color: $color-white;
            font-weight: $font-weight-medium;
            font-size: 0.875rem;
            letter-spacing: 1px;
            border: 2px solid transparent;
            background: $color-mine-shaft-gray;

            &:hover {
                background: $color-dove-gray;
            }

            &::after {
                content: "";
                width: var(--size);
                height: var(--size);
                mask-image: var(--icon);
                mask-size: var(--size);
                mask-repeat: no-repeat;
                mask-position: center;
                background-color: currentcolor;
            }

            @include media-breakpoint-up(md) {
                min-inline-size: 14rem;
            }

            @include media-breakpoint-up(xl) {
                padding-block: 0.875rem;
            }
        }

        input:checked ~ label > span {
            --icon: var(--checkmark);

            border-color: $color-limeade-green;
            background-color: $color-white;
            color: $color-limeade-green;
        }

        address {
            font-size: 1rem;

            > strong {
                font-weight: $font-weight-medium;
            }
        }
    }

    &-mapInfoWindow {
        border: 1px solid $color-alto-gray;
        background-color: $color-white;
        font-size: 1rem;
        inline-size: 16.5rem;
        padding-block: 1.25rem 1.5rem;
        padding-inline: 1.5rem;
        pointer-events: all !important;

        address {
            display: grid;
            gap: 1rem;
            font-size: 1rem;

            > strong {
                font-weight: $font-weight-medium;
            }
        }

        input {
            position: absolute;
            clip: rect(0 0 0 0);
        }

        label {
            display: grid;
            gap: 1rem;
        }

        label > span {
            --size: 1rem;
            --icon: var(--button-arrow);

            display: grid;
            align-items: center;
            justify-content: center;
            grid-auto-flow: column;
            gap: 1rem;
            text-transform: uppercase;
            padding-block: 0.75rem;
            padding-inline: 1.5rem;
            cursor: pointer;
            color: $color-white;
            font-weight: $font-weight-medium;
            font-size: 0.875rem;
            letter-spacing: 1px;
            border: 2px solid transparent;
            background: $color-mine-shaft-gray;

            &:hover {
                background: $color-dove-gray;
            }

            &::after {
                content: "";
                width: var(--size);
                height: var(--size);
                mask-image: var(--icon);
                mask-size: var(--size);
                mask-repeat: no-repeat;
                mask-position: center;
                background-color: currentcolor;
            }

            @include media-breakpoint-up(xl) {
                padding-block: 0.875rem;
            }
        }

        input:checked ~ label > span {
            --icon: var(--checkmark);

            border-color: $color-limeade-green;
            background-color: $color-white;
            color: $color-limeade-green;
        }
    }

    &-mapMarker {
        --marker-color: #{$color-ribbon-red};
        --marker-dot-color: #{$color-white};

        position: relative;
        top: -0.625em;
        display: grid;
        padding: 0.25em;
        border: 0.5em solid var(--marker-color);
        border-radius: 50%;
        background-color: var(--marker-dot-color);
        block-size: 0.5em;
        font-size: 1rem;
        inline-size: 0.5em;

        &::after {
            position: absolute;
            bottom: -1.875em;
            left: -0.375em;
            width: 0;
            height: 0;
            border: 0.625em solid transparent;
            border-top: 1.0625em solid var(--marker-color);
            content: "";
        }

        &.selected {
            --marker-color: #{$color-limeade-green};
        }
    }

    .Form-error {
        padding: 1rem;
        border: 1px solid hsl(345deg 100% 44%);
        background-color: rgb(222 0 57 / 10%);
        color: hsl(345deg 100% 44%);
        font-size: 0.875rem;
        inline-size: 100%;
        margin-block-start: 2rem;

        > a {
            text-decoration: underline;

            &:is(:hover, :focus-visible) {
                text-decoration: none;
            }
        }
    }
}
