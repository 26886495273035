.dealer-hero {
    position: relative;

    &__image {
        display: block;
        width: 100%;

        img {
            height: auto;
            width: 100%;
        }
    }

    &__card {
        position: relative;
        top: clamp(-200px, -15vw, -50px);
        margin-bottom: clamp(-200px, -15vw, -50px);
        z-index: 1;
    }

    &__card-inner {
        background-color: $color-gallery-gray;
        padding: 1.5rem;

        > .rating-block {
            margin-bottom: 1rem;
        }
    }

    &__contact {
        h1 {
            @include media-breakpoint-between(lg, xl) {
                font-size: 40px;
                line-height: 44px;
            }
        }

        address a {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            text-decoration: underline;

            .icon {
                height: 1.25rem;
                width: 1.25rem;
                fill: currentcolor;
            }

            &:hover {
                color: $color-dove-gray;
            }
        }
    }

    &__text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $white;
        text-align: center;
        padding: 1rem;
        background-color: rgba($black, 0.1);

        h1,
        h4 {
            color: inherit;
        }
    }

    .top-tasks-bar {
        padding: 0;
        background-color: transparent;
        margin-top: 1.5rem;

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
        }

        &__item {
            flex: 0 0 calc(50% - 0.25rem);
            max-width: calc(50% - 0.25rem);
            padding: 0;

            &:nth-child(odd),
            &:nth-child(even) {
                padding: 0;
                margin: 0;
            }
        }

        &__link {
            line-height: 16px;
            border: 1px solid $color-mine-shaft-gray;
            background-color: transparent;
            color: $color-mine-shaft-gray;
            padding: 1rem;
            transition:
                background-color $animation-speed $ease-in-out-cubic,
                color $animation-speed $ease-in-out-cubic;

            &:hover {
                background-color: $color-mine-shaft-gray;
                color: $white;
            }
        }

        &__icon {
            height: 50px;
            width: 50px;
        }
    }

    @include media-breakpoint-up(md) {
        &__card {
            top: clamp(-200px, -10vw, -50px);
            margin-bottom: clamp(-200px, -10vw, -50px);
        }

        &__card-inner {
            padding: 2rem;

            > .rating-block {
                margin-bottom: 1.25rem;
            }
        }

        .top-tasks-bar {
            &__title {
                line-height: 21px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__card {
            position: absolute;
            top: clamp(150px, 20vw, 250px);
            right: 0;
            left: 0;
            margin-bottom: 0;
        }

        &__card-inner {
            padding: 3rem;

            > .rating-block {
                margin-bottom: 1.5rem;
            }
        }

        .top-tasks-bar {
            margin-top: 2.5rem;

            &__list {
                gap: 1rem;
            }

            &__item {
                flex: 0 0 calc(50% - 0.5rem);
                max-width: calc(50% - 0.5rem);
                padding: 1.5rem 1rem 1rem;
            }

            &__link {
                padding: 1.5rem 1rem 1rem;
            }

            &__icon {
                height: 62px;
                width: 62px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &__card-inner {
            padding: 3.5rem;
        }

        .top-tasks-bar {
            &__title {
                font-size: 16px;
            }
        }
    }
}
