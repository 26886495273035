// Heading
@mixin text-heading() {
    font-family: $font-family-headings;
    font-weight: $font-weight-heavy;
    color: $font-color-heading;
    letter-spacing: 0.5px;
    margin-bottom: 1em;
    text-transform: uppercase;
}

@mixin text-heading-light() {
    font-weight: $font-weight-light;
}

@mixin text-heading-medium() {
    font-weight: $font-weight-medium;
    text-transform: none;
}

@mixin text-heading-1() {
    @include text-heading;

    font-size: 28px;
    line-height: 30px;

    @include media-breakpoint-up(md) {
        font-size: 36px;
        line-height: 41px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 40px;
        line-height: 44px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 53px;
        line-height: 58px;
    }
}

@mixin text-heading-2() {
    @include text-heading;

    font-size: 24px;
    line-height: 26px;

    @include media-breakpoint-up(md) {
        font-size: 30px;
        line-height: 34px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 32px;
        line-height: 35px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 43px;
        line-height: 46px;
    }
}

@mixin text-heading-3() {
    @include text-heading;

    font-size: 20px;
    line-height: 23px;

    @include media-breakpoint-up(md) {
        font-size: 22px;
        line-height: 25px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 26px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 32px;
        line-height: 34px;
    }
}

@mixin text-heading-4() {
    @include text-heading;

    font-size: 18px;
    line-height: 21px;

    @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 24px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 24px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 27px;
        line-height: 31px;
    }
}

@mixin text-heading-5() {
    @include text-heading;

    font-size: 16px;
    line-height: 19px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 21px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 21px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 21px;
        line-height: 25px;
    }
}

@mixin text-heading-6() {
    @include text-heading;

    font-size: 14px;
    line-height: 16px;

    @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 19px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 19px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 18px;
        line-height: 21px;
    }
}

// Body text
@mixin text-body() {
    color: $font-color-body;
    font-family: $font-family-body;
    font-weight: $font-weight-book;
    letter-spacing: 0.5px;
    font-size: 16px;
    line-height: 1.5; // 24px

    @include media-breakpoint-up(xl) {
        font-size: 18px;
    }
}

@mixin text-intro() {
    @include text-body;

    font-size: 18px;
    line-height: 28px;

    @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 30px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 30px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 24px;
        line-height: 36px;
    }
}

@mixin text-small() {
    @include text-body;

    font-size: 12px;
    line-height: 19px;

    @include media-breakpoint-up(md) {
        font-size: 14px;
        line-height: 21px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 21px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 14px;
        line-height: 21px;
    }
}

@mixin text-sticker {
    @include text-heading-5;

    background: $color-ribbon-red;
    color: $color-white;
    padding: 1px 5px 0;
}

@mixin text-link {
    color: $color-ribbon-red;

    &:hover {
        text-decoration: underline;
    }
}

@mixin text-medium {
    font-weight: $font-weight-medium;
    font-size: 16px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
    }
}

@mixin screenreader-only {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
    clip: rect(0, 0, 0, 0);
}

// Elements
h1,
.h1 {
    @include text-heading-1;
}

h2,
.h2 {
    @include text-heading-2;
}

h3,
.h3 {
    @include text-heading-3;
}

h4,
.h4 {
    @include text-heading-4;
}

h5,
.h5 {
    @include text-heading-5;
}

h6,
.h6 {
    @include text-heading-6;
}

.text-small {
    @include text-small;

    &-sm {
        @include media-breakpoint-down(sm) {
            @include text-small;
        }
    }
}

.text-strong {
    @include text-heading;

    font-size: 16px;
    margin-bottom: 0;
}

.text-intro {
    @include text-intro;
}

.text-link {
    @include text-link;
}

.text-sticker {
    @include text-sticker;
}

.text-medium {
    @include text-medium;
}

.origin-top-right {
    transform-origin: top right;
}

.text-heading-light {
    @include text-heading-light;
}

.text-heading-medium {
    @include text-heading-medium;
}

.error,
.highlighted {
    color: $color-ribbon-red;
}

hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid $color-alto-gray;

    &.hr--full {
        margin-left: -30px;
        margin-right: -30px;
    }

    &.hr--full-big {
        margin-left: -24px;
        margin-right: -24px;

        @include media-breakpoint-up(md) {
            margin-left: -32px;
            margin-right: -32px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -48px;
            margin-right: -48px;
        }
    }
}

.word-break-normal {
    word-break: normal;
}
