.articles-filter-block {
    margin-top: 32px;

    &__date {
        display: flex;

        .input-date-select:first-of-type {
            margin-right: 6px;
        }
    }

    [class^="col-"]:last-of-type .form-group {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        margin-top: 48px;

        .input-date-select:first-of-type {
            margin-right: 14px;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-top: 80px;

        .input-date-select:first-of-type {
            margin-right: 10px;
        }
    }

    @include media-breakpoint-up(xl) {
        .input-date-select:first-of-type {
            margin-right: 8px;
        }
    }

    @include media-breakpoint-only(md) {
        .row {
            margin-right: -10px;
            margin-left: -10px;
        }

        [class^="col-"] {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}
