.card {
    height: 100%;
    overflow: hidden;
}

.card-img-top {
    @media (hover: hover) {
        .slick-arrow {
            visibility: hidden;
        }

        &:hover {
            .slick-arrow {
                visibility: visible;
            }
        }
    }
}
