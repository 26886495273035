/**
* @description Overlay
*
*/

.overlay {
    &__background {
        pointer-events: none;

        &::before {
            transition: opacity 500ms $ease-in-out-cubic 150ms;
            opacity: 0;
            will-change: opacity;
        }

        &::after {
            transition: transform 500ms $ease-in-out-cubic;
            transform: translate3d(0, 100%, 0);
            will-change: transform;
        }

        &.show-background {
            &::before {
                opacity: 1;
            }

            &::after {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &__body {
        pointer-events: none;
        transition: opacity 200ms $ease-in-out-cubic;
        opacity: 0;

        &.show-body {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__btn-close {
        pointer-events: none;
        transition: transform 250ms $ease-in-out-cubic;
        transform: scale3d(0, 0, 0);

        &.show-button {
            transform: scale3d(1, 1, 1);
            pointer-events: all;
        }
    }
}
