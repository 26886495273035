.faq-block {
    .is-light & {
        .faq-block__item {
            border-color: $color-white;
        }

        .faq-block__question {
            background: $color-white;
        }
    }

    &__category:not(:first-child) {
        margin-top: 48px;
    }

    &__item {
        border: 2px solid $color-gallery-gray;
        border-radius: 2px;
        margin-bottom: 8px;
    }

    &__question {
        @include text-heading-5;

        font-weight: $font-weight-medium;
        text-transform: none;
        display: flex;
        background: $color-gallery-gray;
        padding: 12px 14px;
        margin: 0;
        width: 100%;
        text-align: left;

        &-text {
            padding-right: 16px;
        }

        &[aria-expanded="true"] .icon {
            transform: rotate(-180deg);
        }

        .icon {
            flex: 0 0 13px;
            width: 13px;
            height: 13px;
            align-self: center;
            margin-left: auto;
            transition: transform $animation-speed;
        }
    }

    &__answer-inner {
        padding: 22px;

        > * {
            margin-bottom: 1em;
        }

        .buttons-block.row .col-12 {
            margin-left: 0 !important; // overwrite bootstrap classes
            max-width: none !important; // overwrite bootstrap classes
            flex: 1 !important; // overwrite bootstrap classes
        }
    }

    @include media-breakpoint-up(md) {
        &__item {
            border-width: 4px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__question {
            padding: 16px 24px;

            .icon {
                flex: 0 0 16px;
                width: 16px;
                height: 16px;
            }
        }

        &__answer-inner {
            padding: 40px;
        }

        &__category:not(:first-child) {
            margin-top: 64px;
        }
    }
}
