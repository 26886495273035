.LineItems {
    $self: &;

    padding: 0;
    margin: 0;
    list-style: none;
    border-block: 1px solid $color-alto-gray;

    > li {
        display: grid;
        gap: 1rem;
        grid-template-columns: max-content 1fr;
        padding-block: 1.5rem;

        + li {
            border-block-start: 1px solid $color-alto-gray;
        }
    }

    figure {
        margin: 0;

        > img {
            display: grid;
            background-color: $white;
            inline-size: 5rem;
            aspect-ratio: 3/2;
            object-fit: contain;
            block-size: auto;
            font-size: 0;

            &::before {
                content: "";
                inline-size: 100%;
                block-size: 100%;
                display: grid;
                place-items: center;
                padding: 1rem;
                background-image: url($product-fallback-img);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
            }
        }
    }

    &-header {
        display: flex;
        align-items: flex-start;
        gap: 1rem;

        > :first-child {
            flex-grow: 1;

            @include text-body;

            font-weight: $font-weight-medium;
            line-height: 1.35;
        }
    }

    &-quantity {
        @include text-small;

        text-transform: capitalize;
    }

    &-priceCompare {
        display: grid;
        justify-items: flex-end;
        gap: 0.25rem;

        > #{$self}-priceOld {
            &.Money {
                position: relative;
                font-size: fluid(12px, 14px);
                font-weight: $font-weight-book;
                color: $color-mine-shaft-gray;

                &::after {
                    content: "";
                    position: absolute;
                    inset: 45% 0;
                    block-size: 1px;
                    background-color: currentcolor;
                    rotate: -10deg;
                }
            }
        }

        > #{$self}-price {
            &.Money {
                color: $color-ribbon-red;
            }
        }

        &:not(.CartQuickView &, .Checkout-overview &) {
            > #{$self}-priceOld {
                &.Money {
                    font-size: fluid(12px, 18px);
                }
            }

            @include media-breakpoint-up(sm) {
                display: flex;
                gap: 1rem;
                align-items: center;
            }
        }
    }
}
