.edition-card {
    background-color: $color-athens-gray;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem 0;

    &__title {
        height: 40px;
        margin-bottom: 0;
    }

    &__label {
        @include text-sticker;

        position: absolute;
        font-size: 0.875rem;
        left: 0.5rem;
        top: 0.5625rem;
        border-radius: 0.125rem;
        margin-bottom: 0;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
    }

    @include media-breakpoint-up(md) {
        padding: 2.5rem 2rem 0;

        &__title {
            margin-bottom: 8px;
        }

        &__label {
            font-size: 1rem;
            top: 16px;
            left: 16px;
        }

        &__actions {
            padding: 20px 0;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 48px;
        padding-bottom: 0;
    }
}

.divider {
    border: none;
    border-left: 1px solid $color-alto-gray;
}

.grade-image {
    position: relative;
    width: 100%;

    img {
        display: block;
        margin: 0 auto;
    }

    &__grade {
        @include text-heading-2;

        font-size: 40px;
        color: rgba($font-color-body, 0.2);
        position: absolute;
        inset: 0 0 auto;
        text-align: center;
        line-height: 0.75 !important;
        margin-block-end: 0;

        &--large {
            @include media-breakpoint-up(md) {
                font-size: 60px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 90px;
            }
        }
    }
}

.edition-color-picker,
.edition-summary {
    text-align: center;
}

.edition-summary {
    h5 {
        font-weight: $font-weight-book;
    }
}

.configurator-page {
    .edition-color-picker {
        margin-block-end: 40px;
        padding-block-end: 40px;
        text-align: left;
        border-block-end: 1px solid $color-alto-gray;
    }
}

.edition-carousel {
    display: grid;
    justify-items: center;
    margin-block-end: 40px;
    grid-template-rows: 1fr max-content;

    * {
        grid-column: 1;
    }

    .grade-image {
        margin-block-end: 40px;
        padding-block-start: 16px;

        > img {
            aspect-ratio: 2/1; // set consistent aspect ratio for all images
            object-fit: contain;
        }
    }

    > .input-range {
        display: contents;

        > .input-range-pills {
            grid-row: 2;
        }
    }

    > .embla {
        grid-row: 1 / -1;
        display: grid;
        grid-template-rows: subgrid;
        grid-template-columns: subgrid;
        align-items: center;

        > * {
            grid-column: 1;
        }

        .embla__progress {
            grid-row: 2;
            justify-self: end;
            font-weight: $font-weight-heavy;
        }

        @include media-breakpoint-down(xs) {
            > * {
                grid-row: 1;
            }

            .embla__progress {
                grid-row: 1;
                align-self: self-start;
                padding-block: 0.125rem;
                padding-inline: 0.25rem;
                font-size: 0.75rem;
                background-color: $color-white;
                border-bottom-left-radius: 0.25rem;
                z-index: 1;
            }
        }
    }
}
