.specifications-modal {
    &__title {
        font-weight: $font-weight-book;
    }

    .search-box {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        &__input {
            position: relative;
        }

        &__btn-submit,
        &__btn-clear {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);

            svg {
                display: block;
                height: 1.25rem;
                width: 1.25rem;
            }
        }

        &__btn-clear {
            display: none;
            right: 3rem;

            svg {
                height: 1rem;
                width: 1rem;
            }

            &.active {
                display: block;
            }
        }

        .form-control {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.5rem;

            .form-control {
                max-width: 290px;
                padding-right: 4.25rem;
            }
        }
    }
}
