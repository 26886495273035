// ITC Franklin Gothic Std
@font-face {
    font-family: $font-family-headings;
    font-style: normal;
    font-weight: $font-weight-book;
    font-display: swap;
    src: url("#{$static-path}/fonts/ITCFranklinGothicStd-Book.woff2") format("woff2");
}

@font-face {
    font-family: $font-family-headings;
    font-style: normal;
    font-weight: $font-weight-heavy;
    font-display: swap;
    src: url("#{$static-path}/fonts/ITCFranklinGothicStd-Hvy.woff2") format("woff2");
}

@font-face {
    font-family: $font-family-headings;
    font-style: normal;
    font-weight: $font-weight-medium;
    font-display: swap;
    src: url("#{$static-path}/fonts/ITCFranklinGothicStd-Med.woff2") format("woff2");
}
