.page-splash {
    padding-top: 0;

    .page-splash__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: $header-height-sm;
        width: $header-height-sm;
        padding: 5px;
        z-index: 10;
        background-color: $color-white;

        img {
            display: block;
            height: auto;
            width: auto;
        }
    }

    @include media-breakpoint-up(md) {
        .page-splash__logo {
            height: $header-height-md;
            width: $header-height-md;
            padding: 10px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .page-splash__logo {
            height: $header-height-xxl;
            width: $header-height-xxl;
        }
    }
}
