.page-search-box {
    position: absolute;
    top: $header-height-sm;
    left: 0;
    width: 100%;
    background-color: $color-white;
    z-index: 10;
    transition: height 200ms;
    overflow: hidden;

    &:not(.active) {
        padding: 0 !important;
        height: 0;
    }

    &__form {
        padding: 12px 15px;
    }

    &__row {
        position: relative;
    }

    &__input {
        @include text-intro;

        background-color: $color-gallery-gray;
        padding: 5px 35px 5px 15px;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 40px;

        > .icon {
            display: block;
            height: 20px;
            width: 20px;
        }
    }

    @include media-breakpoint-up(md) {
        top: $header-height-md;

        &__form {
            padding: 15px;
        }

        &__input {
            padding: 9px 40px 9px 17px;
        }

        &__button {
            width: 50px;

            > .icon {
                height: 24px;
                width: 24px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        top: $header-height-md + $pre-header-height;
    }

    @include media-breakpoint-up(xl) {
        &__button {
            width: 64px;

            > .icon {
                height: 30px;
                width: 30px;
            }
        }

        &__input {
            padding: 13px 50px 13px 24px;
        }
    }

    @include media-breakpoint-up(xxl) {
        top: $header-height-xxl + $pre-header-height;
    }
}
