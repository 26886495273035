.option {
    display: flex;
    border: 1px solid $color-alto-gray;
    margin-bottom: 16px;
    align-items: center;
    font-size: 14px;

    &__image {
        width: 140px;
        height: 136px;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
            width: 160px;
            height: 120px;
        }
    }

    &__content {
        flex: 1;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-title {
            margin-bottom: 3px;
        }

        .button-link {
            color: $color-mine-shaft-gray;
            margin-bottom: 3px;
        }

        .line-price {
            margin-bottom: 8px;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            align-items: center;
            margin-left: 30px;
            padding: 0;

            .button-link,
            .line-price {
                margin-bottom: 0;
            }
        }
    }

    &__actions {
        .button {
            font-weight: $font-weight-heavy;

            &.is-active {
                background-color: $color-mine-shaft-gray;
                color: $color-white;
            }
        }

        .button__icon-left {
            fill: currentcolor;
            margin-right: 6px;
        }

        @include media-breakpoint-up(md) {
            .button {
                padding: 12px 20px;
            }
        }

        @include media-breakpoint-up(lg) {
            margin-left: 30px;
            margin-right: 30px;
            min-width: 140px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        @include media-breakpoint-up(xl) {
            .button {
                flex: 0 0 auto;
                font-size: 16px;
            }
        }
    }
}
