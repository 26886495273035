.financial-step-configuration {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__row {
        display: block;
        margin-top: 3rem;
    }

    .input-range-label {
        font-weight: $font-weight-medium;
        text-transform: none;
        font-size: 16px;
        line-height: 25px;
    }

    @include media-breakpoint-up(xl) {
        .input-range-label {
            font-size: 18px;
            line-height: 25px;
        }
    }
}
