.accessory-modal {
    &__title {
        font-weight: $font-weight-book;
    }

    &__description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &.is-clamped {
            -webkit-line-clamp: 5;
        }

        &.is-expanded {
            -webkit-line-clamp: none;
        }
    }

    .button__icon-left {
        fill: currentcolor;
        margin-right: 6px;
    }
}
