.dealer-tabs {
    background-color: $color-gallery-gray;
    padding: 1rem;

    .react-tabs__tab {
        @include text-heading;

        font-size: 0.875rem;
        font-weight: 500;
        background-color: transparent;
        border: none;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        color: rgba($color-mine-shaft-gray, 0.6);
        cursor: default;

        &:focus::after {
            content: none;
        }

        &--selected {
            color: $color-mine-shaft-gray;
        }
    }

    &.has-multiple-items {
        .react-tabs__tab {
            cursor: pointer;
        }

        .react-tabs__tab--selected {
            border-bottom: 1px solid $color-mine-shaft-gray;
        }
    }

    .react-tabs__tab-list {
        display: flex;
        gap: 1.5rem;
        border: none;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        .react-tabs__tab {
            font-size: 1rem;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 1.5rem;
    }
}
