.dealers-map {
    position: relative;

    .side-panel {
        min-height: 320px;
        max-height: 100%;
        overflow: hidden;

        &__container {
            position: relative;
            height: 100%;
            transition: transform $animation-speed-lg;
        }

        &__page {
            width: 100%;
        }
    }

    // Contact-detail doesn't have a scrollcontainer
    &:not(.contact-detail) {
        .side-panel {
            transition: transform $animation-speed-lg;
            transform: translate(-101%);

            &.show-panel {
                transform: translateX(0);
            }
        }

        .side-panel__container.show-page {
            transform: translateX(-100%);
        }

        .dealer-detail-info {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            transform: translateX(100%);
        }
    }

    &.contact-detail {
        margin: 0;
    }

    // Contact detail doesnt display list
    &.contact-detail .dealer-list {
        display: none;
    }

    .google-map {
        display: block;
        height: 100%;
        will-change: height;
        border: 1px solid $white;

        &,
        &__component {
            width: 100%;
            height: 100%;
        }
    }

    &__legend {
        background: $color-alto-gray;
        padding: 15px 20px;
        display: flex;

        &-item {
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 20px;
            }
        }

        svg {
            width: 20px;
            height: 30px;
        }

        &-marker {
            width: 20px;
            height: 20px;
            background: $color-ribbon-red;
            border-radius: 50%;

            &.alt {
                background: $color-mine-shaft-gray;
            }
        }

        svg,
        &-marker {
            margin-right: 5px;
        }
    }

    @include media-breakpoint-down(sm) {
        .side-panel.show-panel {
            overflow-y: scroll;
        }

        .google-map {
            overflow: hidden;

            &,
            &__component {
                height: 140px;
            }

            &.is-minimized {
                height: 0;
            }
        }

        .dealer-info__back {
            position: relative;
        }
    }

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .google-map {
            order: 1;
        }

        .side-panel,
        .google-map {
            height: 800px;
        }

        .dealer-detail-info {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &.contact-detail {
            .side-panel {
                height: auto;
            }

            .google-map {
                height: auto;
                min-height: 480px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 2fr;
    }

    @include media-breakpoint-up(xxl) {
        .side-panel,
        .google-map {
            height: 950px;
        }
    }
}
