@use "sass:map";

.related-products {
    --slide-width: 85%;

    &__slide {
        flex: 0 0 var(--slide-width);
        max-width: var(--slide-width);
    }

    .embla__container {
        gap: 1.25rem;
    }

    .embla__nav {
        display: none;
        position: relative;
        justify-content: center;
        transform: none;
        margin-top: 1rem;
        column-gap: 1rem;
    }

    .embla__prev,
    .embla__next {
        margin: 0;
    }

    @include media-breakpoint-down(xs) {
        &.has-1-items {
            --slide-width: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        --slide-width: 25%;

        .embla__container {
            gap: 1.875rem;
        }

        .embla__nav {
            display: flex;
            margin-top: 2rem;
        }
    }

    @include media-breakpoint-up(lg) {
        --slide-width: 20%;

        .embla__container {
            gap: 2.5rem;
        }

        .embla__nav {
            margin-top: 3.75rem;
        }

        &.has-4-items {
            --slide-width: calc(25% - 2.5rem * 3 / 4);

            .embla__viewport {
                max-width: calc(#{map.get($container-max-widths, "lg")} - #{$grid-gutter-width});
                margin: auto;
                padding: 0;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &.has-4-items {
            .embla__viewport {
                max-width: calc(#{map.get($container-max-widths, "xl")} - #{$grid-gutter-width});
                padding: 0;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        &.has-4-items {
            .embla__viewport {
                max-width: calc(#{map.get($container-max-widths, "xxl")} - #{$grid-gutter-width});
                padding: 0;
            }
        }
    }
}
