.PriceTable-smallItem {
    &,
    .Money {
        font-size: 14px;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }
    }

    > th {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}

.PriceTable-couponRemove {
    --size: 1rem;

    block-size: var(--size);
    aspect-ratio: 1;
    mask-image: var(--remove);
    mask-size: var(--size);
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: currentcolor;

    > span {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }
}
