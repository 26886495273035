/**
* @description UI - Gallery
*
*/

.gallery {
    display: flex;
    flex-wrap: wrap;

    &__link {
        overflow: hidden;
        position: relative;
        width: 100%;

        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform $animation-speed;
        }
    }

    &.has-2-images,
    &.has-3-images,
    &.has-4-images,
    &.has-5-images {
        .gallery__link {
            border: 5px solid $color-white;

            &:last-child {
                display: block;
                position: relative;

                img {
                    position: absolute;
                    z-index: 1;
                }

                @include media-breakpoint-up(sm) {
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
        }
    }

    &.has-morethan5-images .gallery__link:last-child {
        &::before {
            content: "";
            background-color: rgba($color-black, 0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;
            pointer-events: none;
        }

        &:active,
        &:hover {
            &::after {
                opacity: 0.7;
            }
        }

        &::after {
            content: attr(data-label);
            display: flex;
            position: absolute;
            inset: 0;
            align-items: center;
            justify-content: center;
            color: $color-white;
            line-height: 26px;
            z-index: 3;
            text-align: left;
            padding: 15px;
            transition: color 250ms $ease-in-out-cubic;
            font-weight: $font-weight-heavy;
            font-size: 24px;
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                font-size: 32px;
            }

            @include media-breakpoint-up(xxl) {
                font-size: 43px;
            }
        }

        @include media-breakpoint-up(sm) {
            &::before {
                text-align: center;
            }
        }
    }

    &.has-2-images {
        .gallery {
            &__link {
                display: none;

                &:nth-child(1) {
                    display: block;
                    width: 50%;
                }

                &:last-child {
                    display: block;
                    width: 50%;
                }

                @include media-breakpoint-only(xs) {
                    &:nth-child(1),
                    &:last-child {
                        height: 216px;
                    }
                }

                @include media-breakpoint-up(sm) {
                    &:nth-child(1) {
                        width: 66.6666%;
                    }

                    &:last-child {
                        width: 33.3333%;
                    }

                    &:nth-child(1),
                    &:last-child {
                        height: 316px;
                    }
                }

                @include media-breakpoint-up(md) {
                    &:nth-child(1),
                    &:last-child {
                        height: 405px;
                    }
                }
            }
        }
    }

    &.has-3-images,
    &.has-4-images {
        .gallery__link {
            @include media-breakpoint-only(xs) {
                display: none;

                img {
                    position: absolute;
                    inset: 0;
                }

                &:nth-child(1) {
                    border-bottom: 1px solid $color-white;
                    display: block;
                    padding-bottom: 56.2667%;
                    width: 100%;
                }

                &:nth-child(2) {
                    border-right: 1px solid $color-white;
                    display: block;
                    padding-bottom: 28.0748%;
                    width: 50%;
                }

                &:last-child {
                    display: block;
                    padding-bottom: 28.0748%;
                    width: 50%;
                }
            }
        }
    }

    &.has-5-images .gallery__link {
        @include media-breakpoint-only(xs) {
            img {
                position: absolute;
                inset: 0;
            }

            &:nth-child(3) {
                border-bottom: 1px solid $color-white;
                display: block;
                padding-bottom: 56.2667%;
                width: 100%;
                border-right-width: 1px;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
                border-right: 1px solid $color-white;
                display: block;
                padding-bottom: 28.0748%;
                width: 50%;
            }
        }
    }

    &.has-3-images {
        @include media-breakpoint-up(sm) {
            .gallery__link {
                display: none;

                &:nth-child(1) {
                    display: block;
                    height: 316px;
                    width: 100%;
                }

                &:nth-child(2),
                &:last-child {
                    display: block;
                    height: 216px;
                    width: 50%;
                }
            }

            @include media-breakpoint-up(md) {
                .gallery__link {
                    &:nth-child(1) {
                        height: 405px;
                    }

                    &:nth-child(2),
                    &:last-child {
                        height: 316px;
                    }
                }
            }
        }
    }

    &.has-4-images {
        @include media-breakpoint-up(sm) {
            .gallery__link {
                display: none;

                &:nth-child(1) {
                    display: block;
                    height: 316px;
                    width: 100%;
                }

                &:nth-child(2),
                &:nth-child(3),
                &:last-child {
                    display: block;
                    height: 169px;
                    width: 33.333%;
                }
            }

            @include media-breakpoint-up(md) {
                .gallery__link {
                    &:nth-child(1) {
                        height: 405px;
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:last-child {
                        height: 316px;
                    }
                }
            }
        }
    }

    &.has-5-images {
        @include media-breakpoint-up(sm) {
            .gallery__link {
                display: none;

                &:nth-child(1),
                &:nth-child(2) {
                    display: block;
                    width: 50%;
                }

                &:nth-child(3),
                &:nth-child(4) {
                    display: block;
                    width: 40%;
                }

                &:last-child {
                    display: block;
                    width: 20%;
                }
            }
        }
    }
}
