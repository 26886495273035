@import "../../abstracts/fluid-sizes";

.car-trade-in-modal {
    .modal__close:only-child {
        height: 2rem;
        margin-block-end: 0;
        padding: 0.5rem;
        position: absolute;
        width: 2rem;

        @include media-breakpoint-up(lg) {
            height: 3rem;
            margin-block-end: 0;
            padding: 1rem;
            position: absolute;
            width: 3rem;
        }

        > svg {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    &__header {
        padding-block: #{fluid(16px, 36px)};
        border-bottom: 1px solid $color-alto-gray;
    }

    &__body {
        padding-block: #{fluid(32px, 80px)};

        > .row {
            align-items: flex-start;
            row-gap: 2rem;
        }
    }

    &__content {
        display: grid;
        row-gap: #{fluid(24px, 40px)};
        align-content: start;
    }

    &__disclaimer {
        @include text-small;

        display: grid;
        gap: 1rem;

        strong {
            display: block;
            font-weight: $font-weight-heavy;
        }

        em {
            font-style: italic;
        }
    }

    &__aside {
        display: grid;
        gap: #{fluid(32px, 40px)};

        > .receipt {
            margin: 0;
        }
    }

    &__back-button {
        margin-block: 0 !important;
        justify-self: flex-start;
    }

    &__action {
        justify-self: flex-end;
    }
}

// Model grid and card used in the car trade-in modal
.model-grid {
    --min-column-width: 16rem;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--min-column-width), 1fr));

    @media (hover: hover) and (pointer: fine) {
        --min-column-width: 14rem;

        column-gap: #{fluid(24px, 40px)};
    }
}

.model-card {
    $self: &;

    --actions-height: #{fluid(40px, 48px)};

    position: relative;
    padding-block: #{fluid(24px, 32px)};
    padding-inline: #{fluid(16px, 20px)};

    &,
    &__body {
        display: grid;
        gap: #{fluid(16px, 20px)};

        :not(#{$self}__figure, #{$self}__body) {
            justify-self: center;
        }
    }

    &__ribbon {
        position: absolute;
        top: #{fluid(24px, 32px)};
        left: #{fluid(16px, 20px)};
        max-inline-size: calc(100% - (#{fluid(16px, 20px)} * 2));

        > span {
            font-size: #{fluid(12px, 14px)};
        }
    }

    &__figure {
        aspect-ratio: 2 / 1;
        cursor: pointer;

        > img {
            aspect-ratio: 2 / 1;
        }
    }

    &__title {
        margin: 0;
    }

    @media (hover: hover) and (pointer: fine) {
        border-radius: 2px;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: inherit;
            border: 1px solid $color-alto-gray;
            box-shadow: 0 30px 40px 0 rgb(0 0 0 / 5%);
            opacity: 0;
            transition: opacity $animation-speed;
        }

        &__body {
            translate: 0 calc(var(--actions-height) / 2);
            transition: translate $animation-speed;
        }

        &__actions {
            opacity: 0;
            translate: 0 calc(var(--actions-height) / 2);
            transition:
                translate $animation-speed,
                opacity $animation-speed;
        }

        &:is(:hover) {
            &::before,
            #{$self}__body,
            #{$self}__actions {
                translate: 0 0;
                opacity: 1;
            }
        }
    }

    // overrighting the default paddings
    .button {
        padding-block: #{fluid(8px, 12px)};
        padding-inline: #{fluid(20px, 32px)};
    }
}

.skeleton-new-car-model-card {
    display: grid;
    gap: #{fluid(16px, 20px)};
    padding-block: #{fluid(24px, 32px)};
    padding-inline: #{fluid(16px, 20px)};

    &__image {
        aspect-ratio: 2 / 1;
    }

    &__title {
        block-size: 19px;

        @include media-breakpoint-up(md) {
            block-size: 21px;
        }

        @include media-breakpoint-up(xl) {
            block-size: 25px;
        }
    }
}

// Car trade-in overview used in the car trade-in modal
.car-trade-in-overview {
    display: grid;
    gap: #{fluid(24px, 32px)};

    &__table {
        display: grid;
        gap: 1rem;

        > h3 {
            margin-block-end: 0;

            @include media-breakpoint-down(sm) {
                margin-block-end: 0.5rem;
            }
        }

        > em {
            @include text-small;

            font-style: italic;
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        gap: #{fluid(16px, 24px)};

        > * {
            min-inline-size: fit-content;

            @include media-breakpoint-down(sm) {
                flex: 1;
            }
        }
    }

    .line-item {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;

        > :last-child {
            text-align: right;
            flex-shrink: 0;
            font-weight: $font-weight-heavy;
        }

        &__total {
            color: $color-limeade-green;
        }
    }
}

.car-trade-in-contact {
    --flow-spacer: #{fluid(8px, 16px)};

    &,
    &__fields {
        display: grid;
        gap: #{fluid(24px, 32px)};
    }

    &__fields {
        .form-group {
            margin-block-end: 0;
        }

        .form-error:empty {
            display: none;
        }

        button[type="submit"] {
            @include media-breakpoint-up(sm) {
                justify-self: flex-start;
            }
        }
    }
}

.car-trade-in-voucher {
    display: grid;
    border: 1px solid $color-alto-gray;

    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 2fr;
    }

    > figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color-alto-gray;
        padding-block: #{fluid(24px, 40px)};
        padding-inline: #{fluid(16px, 20px)};
    }

    figcaption {
        text-align: center;

        > .label {
            display: grid;
            gap: 0.25rem;
            padding-block-start: 0.25rem;
            padding-block-end: 0.5rem;
            padding-inline: 0.5rem;
            font-size: 1.75rem;

            > span {
                display: block;
                font-size: 0.75rem;
            }
        }
    }

    > div {
        display: grid;
        gap: #{fluid(12px, 16px)};
        padding-block: #{fluid(24px, 40px)};
        padding-inline: #{fluid(16px, 40px)};
    }

    .js-form-fields {
        display: grid;
        gap: #{fluid(12px, 16px)};

        .form-group {
            flex: 1;
            margin-block-end: 0;
        }
    }

    .js-form-fields > div:first-child {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: #{fluid(12px, 16px)};

        .text-small {
            em {
                font-style: italic;
            }
        }

        .form-error {
            inline-size: 100%;
        }

        .button {
            padding: 0;
            inline-size: 2.5rem;
            aspect-ratio: 1;
            flex-shrink: 0;

            > svg {
                margin: 0;
            }

            @include media-breakpoint-up(md) {
                inline-size: 3rem;
            }

            @include media-breakpoint-up(xl) {
                inline-size: 4rem;
            }
        }
    }
}

// Dealer contact info block used in the aside of the car trade-in modal
.dealer-contact {
    @include text-small;

    display: flex;
    align-items: center;
    gap: 0.75rem;

    &::before {
        --size: 1.75rem;

        content: "";
        inline-size: var(--size);
        aspect-ratio: 1;
        flex-shrink: 0;
        background-color: currentcolor;
        mask-image: var(--phone);
        mask-size: var(--size);
        mask-repeat: no-repeat;
        mask-position: center;
    }
}

// USP list used in the aside of the car trade-in modal
.usp-list {
    @include text-small;

    display: grid;
    gap: 0.5rem;

    > li {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;

        &::before {
            --size: 0.75rem;

            content: "";
            inline-size: var(--size);
            aspect-ratio: 1;
            background: currentcolor;
            flex-shrink: 0;
            margin-block-start: 0.25rem;
            mask-image: var(--checkmark);
            mask-size: var(--size);
            mask-repeat: no-repeat;
            mask-position: center;
        }
    }
}

.box {
    padding-block: #{fluid(24px, 40px)};
    padding-inline: #{fluid(16px, 40px)};
    border: 1px solid $color-alto-gray;
}

.success-message {
    padding-block: #{fluid(12px, 24px)};
    padding-inline: 1rem;
    font-weight: $font-weight-medium;
    background-color: hsl(93deg 98% 32% / 10%);
    border: 1px solid $color-limeade-green;
}
