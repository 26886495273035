.list-images-block {
    &:not(:last-child) {
        margin-bottom: 53px;
    }

    &__title {
        @include text-heading-4;

        font-size: 16px;
        margin: 0 0 30px 18px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__info {
        @include text-small;

        a {
            @include text-link;
        }
    }

    @include media-breakpoint-up(lg) {
        &-wrap {
            flex: 1 1 auto;
        }
    }

    @include media-breakpoint-up(lg) {
        &:not(:last-child) {
            margin-bottom: 4%;
        }

        &__title {
            margin-bottom: 40px;
        }
    }
}
