.occasion-overview {
    &__sort {
        flex-wrap: nowrap;
        margin-bottom: 0;

        > div {
            flex: 1 1 0;
        }

        .input-label {
            flex: 0 0 auto;
            margin-bottom: 0;
        }
    }

    &__clear-filters {
        color: $font-color-body;
    }
}

.occasion-card {
    &__model,
    &__price {
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 24px;
    }

    &__model {
        margin-bottom: 6px;
    }

    &__edition {
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 16px;
    }

    &__specs {
        font-size: 14px;
        line-height: 16px;
        color: $font-color-body-light;
    }

    .text-small {
        font-size: 14px;
        line-height: 16px;
    }

    @include media-breakpoint-up(md) {
        &__model,
        &__price {
            font-size: 20px;
            line-height: 24px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__model,
        &__price {
            font-size: 16px;
            line-height: 19px;
        }

        &__model {
            margin-bottom: 4px;
        }

        &__edition,
        &__specs {
            font-size: 12px;
        }

        .text-small {
            font-size: 12px;
            line-height: 21px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__model,
        &__price {
            font-size: 16px;
            line-height: 19px;
        }
    }
}

.occasion-card-img-top {
    height: 192px;
    position: relative;

    img {
        display: block;
        object-fit: cover;
        height: 100%;
        width: auto;
        margin: 0 auto;
    }
}

.occasion-banner {
    display: grid;
    min-block-size: 100%;
    background-color: $color-porcelain-gray;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.025);
    }

    > * {
        grid-area: 1 / 1;
    }

    > img {
        block-size: 100%;
        inline-size: 100%;
        object-fit: cover;
    }

    &__content {
        display: grid;
        gap: 0.5rem;
        padding: 1.25rem;
        align-self: end;
    }

    &__title {
        margin: 0;
    }

    &__title,
    &__text {
        color: $color-white;
    }

    &__label {
        place-self: start;
        font-size: 0.75rem;
        background-color: $color-ribbon-red;
        color: $color-white;
        margin: 0.5rem;
        padding-block-start: 0.125rem;
        padding-inline: 0.25rem;
        font-weight: 400;
        text-transform: uppercase;
    }
}

.occasions-overview-skeleton {
    text-align: center;
    min-height: 5000px;

    @include media-breakpoint-up(md) {
        min-height: 3000px;
    }

    @include media-breakpoint-up(lg) {
        min-height: 2000px;
    }
}
