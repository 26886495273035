.financial-step {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    &__row {
        border-bottom: 1px solid $border-color;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}
